import { useEffect, useState } from 'react';
import {
  RiFileCopy2Fill,
  RiQuestionAnswerFill,
  RiTeamFill,
} from 'react-icons/ri';
import OverviewCard from '../../../components/formus/index/OverviewCard';
import { useAuth } from '../../../contexts/authContext/AuthContext';
import { handleFetchError, showToastError } from '../../../helper';
import { useNavigate } from 'react-router-dom';
import axiosClient from '../../../axios-client';

export default function IndexOverview() {
  const { authDispatch, authState } = useAuth();

  const navigate = useNavigate();

  const [projectsCount, setProjectsCount] = useState(0);
  const [commentsCount, setCommentsCount] = useState(0);
  const [committeesCount, setCommitteesCount] = useState(0);

  useEffect(() => {
    axiosClient
      .get(`/statistics/overview`)
      .then(({ data }) => {
        setProjectsCount(data.projects_count);
        setCommentsCount(data.comments_count);
        setCommitteesCount(data.committees_count);
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {});
    return () => {};
  }, [authState.token]);

  return (
    <>
      <h2 className="mb-[8px] text-[24px] font-bold">نظرة عامة</h2>
      <div className="mb-[16px] grid w-full grid-cols-12 gap-[8px]">
        <OverviewCard
          icons={<RiQuestionAnswerFill size={32} />}
          label={`التعليقات`}
          number={commentsCount}
        />
        <OverviewCard
          icons={<RiFileCopy2Fill size={32} />}
          label={`المشاريع الفنية`}
          number={projectsCount}
        />
        <OverviewCard
          icons={<RiTeamFill size={32} />}
          label={`اللجان الفنية`}
          number={committeesCount}
        />
      </div>
    </>
  );
}
