import moment from 'moment';
import { MdEdit, MdRefresh } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { toDateTime } from '../../../../helper';

export default function UserInformations({ user }) {
  return (
    <div className="space-y-[16px]">
      <div className="space-y-[4px]">
        <div className="font-bold">الاسم الكامل</div>
        <div className="rounded-[6px] border-[2px] border-muted px-[16px] py-[8px]">
          {user.first_name_ar} {user.last_name_ar}
        </div>
      </div>
      <div className="space-y-[4px]">
        <div className="font-bold">دولة</div>
        <div className="flex gap-[4px] rounded-[6px] border-[2px] border-muted px-[16px] py-[8px]">
          <img
            alt={user.country.name_ar}
            title={user.country.name_ar}
            width={32}
            className="rounded-[4px] border-[2px] border-muted"
            src={`${process.env.REACT_APP_BASE_URL}/storage/flags/${user.country.image}`}
          />
          <div>{user.country.name_ar}</div>
        </div>
      </div>

      <div className="space-y-[4px]">
        <div className="font-bold">نوع</div>
        <div className="flex gap-[4px] rounded-[6px] border-[2px] border-muted px-[16px] py-[8px]">
          {user.gender}
        </div>
      </div>
      <div className="space-y-[4px]">
        <div className="font-bold">بريد إلكتروني</div>
        <div className="flex gap-[4px] rounded-[6px] border-[2px] border-muted px-[16px] py-[8px]">
          {user.email}
        </div>
      </div>
      <div className="space-y-[4px]">
        <div className="font-bold">تم إنشاؤه</div>
        <div className="flex gap-[4px] rounded-[6px] border-[2px] border-muted px-[16px] py-[8px]">
          {toDateTime(moment(user.created_at))}
        </div>
      </div>
      <div className="space-y-[4px]">
        <div className="font-bold">تم تحديثه</div>
        <div className="flex gap-[4px] rounded-[6px] border-[2px] border-muted px-[16px] py-[8px]">
          {toDateTime(moment(user.updated_at))}
        </div>
      </div>
      <div className="flex gap-[16px]">
        <Link
          to={`/dashboard/users/${user.id}/edit`}
          className={`flex items-center gap-[8px] rounded-[6px] border-[2px] border-primary px-[16px] py-[8px] text-primary hover:bg-primary hover:text-white`}
        >
          <MdEdit size={20} />
          <span>تعديل</span>
        </Link>
        <Link
          to={`/dashboard/users/${user.id}/change/password`}
          className={`flex items-center gap-[8px] rounded-[6px] border-[2px] border-primary px-[16px] py-[8px] text-primary hover:bg-primary hover:text-white`}
        >
          <MdRefresh size={20} />
          <span>تغيير كلمة مرور</span>
        </Link>
      </div>
    </div>
  );
}
