import React from 'react';
import CommitteeMembersTableHeader from './CommitteeMembersTableHeader';
import CommitteeMembersTableBodyLoading from './CommitteeMembersTableBodyLoading';
import CommitteeMembersTableBody from './CommitteeMembersTableBody';

export default function CommitteeMembersTable({
  members,
  isLoading,
  onRemoveMember,
}) {
  return (
    <table
      className={`w-full border-separate border-spacing-y-[8px] text-right text-[14px]`}
    >
      <CommitteeMembersTableHeader />
      {isLoading && <CommitteeMembersTableBodyLoading />}
      {!isLoading && (
        <CommitteeMembersTableBody
          members={members}
          onRemoveMember={onRemoveMember}
        />
      )}
    </table>
  );
}
