import { createContext, useContext, useEffect, useReducer } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../authContext/AuthContext';
import { projectsInitialState } from './projectsInitialState';
import { projectsReducer } from './projectsReducer';
import { useCommittee } from '../committeeContext/CommitteeContext';
import { PROJECTS_ACTIONS } from './projectsActions';
import { fetchData } from '../../../helper';

const ProjectsContext = createContext({
  projectsState: projectsInitialState,
  projectsDispatch: () => {},
});

export const ProjectsProvider = ({ children }) => {
  const { project_id, committee_id } = useParams();
  const { authDispatch } = useAuth();
  const { committeeState } = useCommittee();
  const navigate = useNavigate();
  const location = useLocation();

  const [projectsState, projectsDispatch] = useReducer(
    projectsReducer,
    projectsInitialState,
  );

  useEffect(() => {
    if (!committeeState.currentCommittee) {
      projectsDispatch({
        type: PROJECTS_ACTIONS.SET_PROJECTS_IS_LOADING,
        payload: {
          isLoading: true,
        },
      });
      projectsDispatch({
        type: PROJECTS_ACTIONS.SET_PROJECTS,
        payload: {
          projects: null,
        },
      });
      return;
    }

    projectsDispatch({
      type: PROJECTS_ACTIONS.SET_PROJECTS_IS_LOADING,
      payload: {
        isLoading: true,
      },
    });
    const cleanup = () => {
      // Dispatch action to reset projects to initial state
      projectsDispatch({
        type: PROJECTS_ACTIONS.SET_PROJECTS,
        payload: {
          projects: null,
        },
      });
    };

    const queryString = location.search;
    fetchData(
      `/committees/${committeeState.currentCommittee.id}/projects${queryString}`,
      {
        IS_LOADING: PROJECTS_ACTIONS.SET_PROJECTS_IS_LOADING,
        SUCCESS: PROJECTS_ACTIONS.SET_PROJECTS,
      },
      projectsDispatch,
      authDispatch,
      navigate,
      (data) => ({
        projects: data.projects,
        pagination: {
          last_page_url: data.last_page_url,
          links: data.links,
          first_page_url: data.first_page_url,
          current_page: data.current_page,
        },
      }),
    );

    return cleanup;
  }, [
    committeeState.currentCommittee,
    location.search,
    authDispatch,
    navigate,
    projectsDispatch,
  ]);

  useEffect(() => {
    if (!project_id) {
      projectsDispatch({
        type: PROJECTS_ACTIONS.SET_CURRENT_PROJECT_IS_LOADING,
        payload: {
          isLoading: true,
        },
      });
      projectsDispatch({
        type: PROJECTS_ACTIONS.SET_CURRENT_PROJECT,
        payload: {
          project: null,
        },
      });
      return;
    }

    if (
      projectsState.currentProject &&
      project_id === projectsState.currentProject.id
    ) {
      return;
    }

    if (committeeState.currentCommittee) {
      fetchData(
        `/committees/${committeeState.currentCommittee.id}/projects/${project_id}`,
        {
          IS_LOADING: PROJECTS_ACTIONS.SET_CURRENT_PROJECT_IS_LOADING,
          SUCCESS: PROJECTS_ACTIONS.SET_CURRENT_PROJECT,
        },
        projectsDispatch,
        authDispatch,
        navigate,
        (data) => ({
          project: data.project,
        }),
      );
    }
  }, [
    committeeState.currentCommittee,
    project_id,
    authDispatch,
    navigate,
    projectsDispatch,
  ]);

  useEffect(() => {
    const { currentCommittee } = committeeState;
    const { currentProject } = projectsState;

    if (!currentCommittee || !currentProject) {
      projectsDispatch({
        type: PROJECTS_ACTIONS.SET_VERSIONS,
        payload: { versions: null },
      });
      return;
    }
    fetchData(
      `/committees/${currentCommittee.id}/projects/${currentProject.id}/history`,
      {
        IS_LOADING: PROJECTS_ACTIONS.SET_VERSIONS_IS_LOADING,
        SUCCESS: PROJECTS_ACTIONS.SET_VERSIONS,
      },
      projectsDispatch,
      authDispatch,
      navigate,
      (data) => ({
        versions: data.versions,
      }),
    );
  }, [
    authDispatch,
    committeeState.currentCommittee,
    projectsState.currentProject,
    navigate,
    projectsDispatch,
  ]);

  return (
    <ProjectsContext.Provider value={{ projectsState, projectsDispatch }}>
      {children}
    </ProjectsContext.Provider>
  );
};

export default ProjectsContext;

export const useProjects = () => useContext(ProjectsContext);
