import React, { useEffect, useRef, useState } from 'react';
import SelectCountries from '../../components/formus/ui/SelectCountries';
import axiosClient from '../../axios-client';

export default function DatabasesSearchForm() {
  const isProposedForApprovalRef = useRef(null);
  const projectStatuslRef = useRef(null);
  const documentTypeRef = useRef(null);
  const departmentRef = useRef(null);

  const cmmitteeRef = useRef(null);

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [committees, setCommittees] = useState(null);

  const handleOnSelectCountry = (option) => {
    setSelectedCountry(option);
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();
  };
  
  useEffect(() => {
    axiosClient
      .get(`/committes`)
      .then(({ data }) => {
        setCommittees(data.committees);
      })
      .catch((error) => {})
      .finally(() => {});
    return () => {};
  }, []);

  return (
    <form className="rounded-[8px] bg-white p-[16px]">
      <h4 className="mb-[4px] text-lg font-bold">البحث</h4>
      <div className="flex flex-col gap-[8px]">
        <div className="flex flex-col">
          <label className="font-bold">معروضة للإعتماد</label>
          <select
            ref={isProposedForApprovalRef}
            className={`rounded-[6px] border-[2px] border-light px-[16px] py-[6px] outline-none`}
            id={`isProposedForApprovalRef`}
          >
            <option> ---- اختر ---- </option>
            <option value="0">لا</option>
            <option value="1">نعم</option>
          </select>
        </div>
        <div className="flex flex-col">
          <label className="font-bold">الجهة المقترحة</label>
          <SelectCountries onSelect={handleOnSelectCountry} />
        </div>
        <div className="flex flex-col">
          <label htmlFor="projectStatuslRef" className={`font-bold`}>
            وضعية المشروع
            <span className={`text-danger`}> *</span>
          </label>
          <select
            ref={projectStatuslRef}
            className={`rounded-[6px] border-[2px] border-light px-[16px] py-[6px] outline-none`}
            id={`projectStatuslRef`}
          >
            <option> ---- اختر ---- </option>
            <option value="مشروع جديد">مشروع جديد</option>
            <option value="مشروع معدل">مشروع معدل</option>
            <option value="تحديث مواصفة معتمدة">تحديث مواصفة معتمدة</option>
            <option value="دليل استرشادي">دليل استرشادي</option>
            <option value="لائحة فنية">لائحة فنية</option>
          </select>
        </div>
        <div className="flex flex-col">
          <label htmlFor={`documentTypeRef`} className={`font-bold`}>
            نوع الوثيقة
            <span className={`text-danger`}> *</span>
          </label>
          <select
            ref={documentTypeRef}
            className={`rounded-[6px] border-[2px] border-light px-[16px] py-[6px] outline-none`}
            id={`documentTypeRef`}
          >
            <option> ---- اختر ---- </option>
            <option value="مواصفة">مواصفة</option>
            <option value="دليل">دليل</option>
            <option value="دراسة">دراسة</option>
            <option value="توصية">توصية</option>
            <option value="لائحة فنية">لائحة فنية</option>
          </select>
        </div>
        <div className="flex flex-col">
          <label htmlFor={`cmmitteeRef`} className={`font-bold`}>
            اللجنة الفنية
            <span className={`text-danger`}> *</span>
          </label>
          <select
            ref={cmmitteeRef}
            className={`rounded-[6px] border-[2px] border-light px-[16px] py-[6px] outline-none`}
            id={`cmmitteeRef`}
          >
            <option> ---- اختر ---- </option>
            {committees?.map((committee) => (
              <option value={committee.id}>
                {committee.name} / {committee.symbol}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col">
          <label htmlFor={`departmentRef`} className={`font-bold`}>
            قسم
            <span className={`text-danger`}> *</span>
          </label>
          <select
            ref={departmentRef}
            className={`rounded-[6px] border-[2px] border-light px-[16px] py-[6px] outline-none`}
            id={`departmentRef`}
          >
            <option> ---- اختر ---- </option>
            <option value="عربية">عربية</option>
            <option value="متبناة">متبناة</option>
          </select>
        </div>
        <div className="mt-[8px] flex gap-[8px]">
          <button
            onClick={handleOnSubmit}
            className="w-full rounded-[4px] bg-primary px-[16px] py-[8px] text-white hover:bg-primary-hover"
          >
            بحث
          </button>
          <button className="w-full rounded-[4px] bg-muted px-[16px] py-[8px] hover:bg-muted-hover">
            مسح المحدد
          </button>
        </div>
      </div>
    </form>
  );
}
