import React from 'react';
import IndexCommitteesCard from './IndexCommitteesCard';
import IndexCommitteesGridCardsLoading from './IndexCommitteesGridCardsLoading';

export default function IndexCommitteesGridCards({ isLoading, committees }) {
  if (isLoading) {
    return <IndexCommitteesGridCardsLoading />;
  }
  if (committees) {
    return (
      <div className="grid w-full grid-cols-12 gap-[8px]">
        {committees?.map((committee) => (
          <IndexCommitteesCard key={committee.id} committee={committee} />
        ))}
      </div>
    );
  }
}
