import React, { useState } from 'react';
import {
  getProjectType,
  handleFetchError,
  toDateTime,
} from '../../../../helper';
import {
  MdKeyboardArrowDown,
  MdOutlineKeyboardDoubleArrowLeft,
} from 'react-icons/md';
import ProjectVersionsHistoryListItemCommentsList from './ProjectVersionsHistoryListItemCommentsList';
import { useProjects } from '../../../../contexts/forums/projectsContext/ProjectsContext';
import axiosClient from '../../../../axios-client';
import { useCommittee } from '../../../../contexts/forums/committeeContext/CommitteeContext';
import ProjectVersionsHistoryFileDiffDialog from './ProjectVersionsHistoryFileDiffDialog';
import { useAuth } from '../../../../contexts/authContext/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';

export default function ProjectVersionsHistoryListItem({ version }) {
  const { project_id, committee_id } = useParams();
  const { authDispatch } = useAuth();
  const { projectsState } = useProjects();
  const { committeeState } = useCommittee();
  const navigate = useNavigate();

  const [file, setFile] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isFileDiffDialogOpen, setIsFileDiffDialogOpen] = useState(false);

  const [isDownloading, setIsDownloading] = useState(false);

  const handleOnClickDownloadProjectVersion = (version_id, fileName) => {
    setIsDownloading(true);
    axiosClient
      .get(`committees/${committee_id}/versions/${version_id}/donwload`, {
        responseType: 'blob',
      })
      .then((response) => {
        const filename = fileName;
        let blob = new Blob([response.data], {
          type: 'application/octet-stream',
        });

        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          // IE workaround for "HTML7007: One or more blob URLs were
          // revoked by closing the blob for which they were created.
          // These URLs will no longer resolve as the data backing
          // the URL has been freed."
          window.navigator.msSaveBlob(blob, filename);
        } else {
          let blobURL = window.URL.createObjectURL(blob);
          let tempLink = document.createElement('a');
          tempLink.style.display = 'none';
          tempLink.href = blobURL;
          tempLink.download = filename;
          tempLink.click();
          window.URL.revokeObjectURL(blobURL);
        }
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  const handleOnClickShowComments = () => {
    setIsOpen(!isOpen);
  };

  function filterDiffs(diffs) {
    const lines = diffs.split('\n');
    const plusLines = lines.filter((line) => line.startsWith('+'))[0];
    const minusLines = lines.filter((line) => line.startsWith('-'))[0];
    return [minusLines, plusLines];
  }
  const renderDiff = (diffs, label) => {
    if (diffs) {
      const filteredLines = filterDiffs(diffs);
      if (label === 'نوع المشروع')
        return (
          <div className="space-y-[4px]">
            <label className="font-bold">تم تعديل {label} :</label>
            <div className="flex items-center gap-[8px] rounded-[8px] bg-white p-[8px]">
              <div className="w-full px-[12px] py-[4px] text-danger-hover">
                {getProjectType(filteredLines[0].substring(1))}
              </div>
              <div>
                <MdOutlineKeyboardDoubleArrowLeft size={20} />
              </div>
              <div className="w-full px-[12px] py-[4px] text-success-hover">
                {getProjectType(filteredLines[1].substring(1))}
              </div>
            </div>
          </div>
        );
      else if (label !== 'المراجع')
        return (
          <div className="space-y-[4px]">
            <label className="font-bold">{label} :</label>
            <div className="flex items-center gap-[8px] rounded-[8px] bg-white p-[8px]">
              <div className="w-full px-[12px] py-[4px] text-danger-hover">
                {filteredLines[0].substring(1)}
              </div>
              <div>
                <MdOutlineKeyboardDoubleArrowLeft size={20} />
              </div>
              <div className="w-full px-[12px] py-[4px] text-success-hover">
                {filteredLines[1].substring(1)}
              </div>
            </div>
          </div>
        );
      else {
        return (
          <div className="flex flex-col space-y-[4px] ">
            <label className="font-bold">{label} :</label>
            <div className="grid grid-cols-11 items-center justify-center gap-[8px] rounded-[8px] bg-white p-[8px] ">
              <div className="col-span-5 flex h-full w-full flex-col gap-[4px] px-[12px] py-[4px] text-danger-hover">
                {JSON.parse(filteredLines[0].substring(1)).map((reference) => (
                  <div>• {reference}</div>
                ))}
              </div>
              <div className="col-span-1 flex items-center justify-center">
                <MdOutlineKeyboardDoubleArrowLeft size={20} />
              </div>
              <div className="col-span-5 flex h-full w-full flex-col gap-[4px] px-[12px] py-[4px] text-success-hover">
                {JSON.parse(filteredLines[1].substring(1)).map((reference) => (
                  <div>• {reference}</div>
                ))}
              </div>
            </div>
          </div>
        );
      }
    }
  };

  if (version) {
    return (
      <div className="rounded-[8px]">
        <button
          onClick={handleOnClickShowComments}
          className="flex w-full items-center justify-between rounded-[8px] bg-muted p-[8px] outline-none"
        >
          <div className="flex items-center gap-[8px]">
            <div className="rounded-[6px] bg-primary px-[8px] py-[4px] font-bold text-white">
              {version.project.stage} : {version.project.version}
            </div>
            <p className="font-semibold">
              {toDateTime(version.project.created_at)}
            </p>
          </div>
          <div>
            <MdKeyboardArrowDown
              size={22}
              className={`transition ease-in-out ${isOpen ? 'rotate-180' : ''}`}
            />
          </div>
        </button>
        {isOpen && (
          <>
            <div className="mt-[8px] flex flex-col gap-[4px]">
              {renderDiff(version.differences.title_ar, 'العنوان بالعربية')}
              {renderDiff(version.differences.title_en, 'العنوان بالإنجليزية')}
              {renderDiff(version.differences.type, 'نوع المشروع')}
              {renderDiff(
                version.differences.ar_number,
                'رقم المواصفة القياسية العربية',
              )}
              {renderDiff(version.differences.references, 'المراجع')}
              {renderDiff(version.differences.stage, 'المرحلة')}
              {renderDiff(version.differences.version, 'الاصدار')}
            </div>
            {version.is_file_changed && (
              <div className="mt-[8px] flex flex-col space-y-[4px] text-[14px]">
                <label className=" font-bold">ملف :</label>
                <div className="flex gap-[16px]">
                  <button
                    onClick={() =>
                      handleOnClickDownloadProjectVersion(
                        version.old_project_id,
                        `before_${version.project.stage}_${version.project.version}_${version.project.title_ar}.${version.project.extension}`,
                      )
                    }
                    disabled={isDownloading}
                    className="w-full rounded-[8px] bg-primary py-[8px] text-white outline-none hover:bg-primary-hover"
                  >
                    قبل تعديل
                  </button>
                  <button
                    disabled={isDownloading}
                    onClick={() =>
                      handleOnClickDownloadProjectVersion(
                        version.new_project_id,
                        `after_${version.project.stage}_${version.project.version}_${version.project.title_ar}.${version.project.extension}`,
                      )
                    }
                    className="w-full rounded-[8px] bg-primary py-[8px] text-white outline-none hover:bg-primary-hover"
                  >
                    بعد تعديل
                  </button>
                </div>
              </div>
            )}
            {/* <ProjectVersionsHistoryFileDiffDialog
              isOpen={isFileDiffDialogOpen}
              file={file}
              isLoading={isLoading}
              onClose={() => setIsFileDiffDialogOpen(false)}
            /> */}
          </>
        )}
      </div>
    );
  }
}
