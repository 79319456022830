import { useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import axiosClient from '../../../../../axios-client';
import { handleFetchError } from '../../../../../helper';
import { useAuth } from '../../../../../contexts/authContext/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { CgAttachment, CgSpinner } from 'react-icons/cg';
import { MdClose } from 'react-icons/md';
export default function ProjectCommentCreate({
  project,
  isLoading,
  onUserComment,
}) {
  const { authDispatch, authState } = useAuth();

  const navigate = useNavigate();

  const [hasComment, setHasComment] = useState(false);
  const [body, setBody] = useState('');
  const [isInProgress, setIsInProgress] = useState(false);
  const [attachment, setAttachment] = useState(null);

  const handleEditorChange = (content, editor) => {
    setBody(content);
  };

  const handleOnClickSendCommnet = () => {
    if (isInProgress) {
      return;
    }
    setIsInProgress(true);

    const payload = {
      has_comment: hasComment ? 1 : 0,
      body: body,
      attachment: attachment,
    };

    axiosClient
      .post(
        `/committees/${project.committee_id}/projects/${project.id}/comments`,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .then(({ data }) => {
        onUserComment();
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        setIsInProgress(false);
      });
  };

  const handleOnClickDownload = (document_name, document_id) => {
    // setIsDownloading(true);
    axiosClient
      .get(`/documents/global/${document_id}`, {
        responseType: 'blob',
      })
      .then((response) => {
        // Create a URL object from the Blob data
        const url = window.URL.createObjectURL(new Blob([response.data]));
        // Create a link element to trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', document_name); // Set the file name for the download
        document.body.appendChild(link); // Append to body
        // Programmatically trigger the download
        link.click();
        // Cleanup: remove the link and release the URL object
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        // setIsDownloading(false);
      });
  };

  const handleOnClickShowCommentArea = () => {
    if (isInProgress) {
      return;
    }
    setIsInProgress(true);
    setHasComment(!hasComment);
    setIsInProgress(false);
  };

  if (isLoading) {
    return;
  }

  if (project.user.id === authState.currentUser.id) {
    return (
      <div className="mt-[8px] rounded-[8px] bg-muted px-[16px] py-[8px]">
        لا يمكنك إنشاء تعليق على مشروعكم.
      </div>
    );
  }

  if (project.user_has_comment) {
    return (
      <div className="mt-[8px] rounded-[8px] bg-muted px-[16px] py-[8px]">
        لقد قمت بالفعل بإنشاء تعليق.
      </div>
    );
  }

  if (!hasComment) {
    return (
      <div className="mt-[8px] flex flex-col gap-[8px]">
        <div className="flex gap-[8px]">
          <button
            className=" flex items-center gap-[12px] rounded-[8px] border-[2px] border-success-hover bg-success px-[16px] py-[8px] text-white transition duration-150 ease-in-out hover:bg-success-hover"
            onClick={handleOnClickSendCommnet}
          >
            <span>لا توجد ملاحظات</span>
          </button>
          <button
            onClick={handleOnClickShowCommentArea}
            className=" flex items-center gap-[12px] rounded-[8px] border-[2px] border-muted-hover bg-muted px-[16px] py-[8px] transition duration-150 ease-in-out hover:bg-muted-hover"
          >
            <span>هناك ملاحظات</span>
          </button>
        </div>
      </div>
    );
  }

  if (hasComment) {
    return (
      <div className="mt-[16px] rounded-[8px] bg-white p-[8px]">
        <button
          className=" flex items-center gap-[12px] rounded-[8px] border-[2px] border-danger-hover bg-muted/50 p-[8px] text-danger-hover transition duration-150 ease-in-out hover:bg-danger-hover hover:text-white"
          onClick={handleOnClickShowCommentArea}
          disabled={isInProgress}
        >
          {isInProgress && <CgSpinner className="animate-spin" size={21} />}
          {!isInProgress && <MdClose size={21} />}
        </button>
        <Editor
          apiKey={'6w0lf9jhoitegtoixng3j9pwefdlcm8j4vqy2zepjo98h624'}
          init={{
            selector: 'textarea', // change this selector as per your needs
            plugins: 'directionality lists link',
            directionality: 'rtl',
            menubar: false,
            height: 200,
            toolbar:
              'undo redo | rtl ltr | formatselect | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist',
            content_style: `
          body {
            border: none !important;
            border-radius: 0 !important;
          }
        `,
            setup: (editor) => {
              editor.on('init', () => {
                editor.getContainer().style.border = 'none';
                editor.getContainer().style.borderRadius = '0';
              });
            },
          }}
          onEditorChange={handleEditorChange}
        />
        <Link
          to={'/forums'}
          className=" flex items-center  px-[16px] py-[8px] underline hover:text-success"
        >
          ◄ تحميل نمودج إبداءالملاحظات
        </Link>
        <div className="mt-[8px] flex justify-between">
          <div className="flex gap-[8px]">
            <button
              onClick={handleOnClickSendCommnet}
              className=" teext-primary flex items-center gap-[12px] rounded-[8px] border-[2px] border-primary-hover bg-muted px-[16px] py-[8px] text-primary transition duration-150 ease-in-out hover:bg-primary-hover hover:text-white"
              disabled={isInProgress}
            >
              {isInProgress && <CgSpinner className="animate-spin" size={21} />}
              {!isInProgress && <>إرسال الملاحظات</>}
            </button>
          </div>
          <label
            htmlFor="ID_PROJECT_FILE_ATTACHMENT"
            className="flex cursor-pointer flex-col space-y-[8px]"
          >
            <div
              className={`flex items-center justify-between rounded-[6px] border-[2px] transition duration-150 ease-in-out  ${attachment ? 'border-success-hover bg-success text-white' : 'border-muted bg-white text-secondary hover:bg-muted'}   px-[16px] py-[8px] `}
            >
              <div>
                {!attachment && 'رفع ملحق'}
                {attachment && 'تم رفع ملحق خاص بك'}
              </div>
            </div>
            <input
              type="file"
              name="attachment_file"
              id="ID_PROJECT_FILE_ATTACHMENT"
              className="hidden"
              accept=".doc,.docx"
              onChange={(event) => setAttachment(event.target.files[0])}
            />
          </label>
        </div>
      </div>
    );
  }
}
