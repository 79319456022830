import { COMMITTEE_ACTIONS } from './committeeActions';

export const committeeReducer = (state, action) => {
  switch (action.type) {
    case COMMITTEE_ACTIONS.SET_COMMITTEES_IS_LOADING:
      return {
        ...state,
        committeesIsLoading: action.payload.isLoading,
      };
    case COMMITTEE_ACTIONS.SET_CURRENT_COMMITTEE_IS_LOADING:
      return {
        ...state,
        currentCommitteeIsLoading: action.payload.isLoading,
      };
    case COMMITTEE_ACTIONS.SET_COMMITTEES:
      return {
        ...state,
        committees: action.payload.committees,
      };
    case COMMITTEE_ACTIONS.SET_CURRENT_COMMITTEE:
      return {
        ...state,
        currentCommittee: action.payload.committee,
      };
    default:
      return state;
  }
};
