import { RiFileCopy2Fill, RiTeamFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { getRoleName } from '../../../../helper';

export default function IndexCommitteesCard({ committee }) {
  return (
    <Link
      to={`/forums/committees/${committee.id}`}
      className="group relative col-span-12 flex h-[200px] items-center justify-between overflow-hidden rounded-[8px] border border-muted bg-white transition-all md:col-span-6 xl:col-span-4"
    >
      <img
        className="h-full w-full object-cover object-bottom"
        src={`${process.env.REACT_APP_BASE_URL}/storage/images/committees/${committee.image}`}
        alt={committee.name}
      />
      <div className="absolute h-full w-full bg-gradient-to-t from-white via-white to-transparent bg-size-200 bg-pos-0 duration-500 group-hover:bg-pos-50" />
      <div className="absolute right-[8px] top-[8px]">
        <div className="rounded-[4px] border-[2px] border-secondary-hover bg-secondary px-[16px] py-[2px] font-semibold text-white">
          {getRoleName(committee.pivot.role)}
        </div>
      </div>
      <div className="absolute bottom-0 left-0 right-0 space-y-[8px] p-[16px]">
        <div>
          <h3 className="line-clamp-2 text-[20px] font-bold group-hover:text-primary">
            {committee.name}
          </h3>
          <span className="text-secondary group-hover:text-primary">
            {committee.symbol}
          </span>
        </div>
        <div className="flex items-center justify-between group-hover:text-primary-hover">
          <div className="flex items-center gap-[8px] text-[20px]">
            <span>{committee.members_count}</span>
            <RiTeamFill size={28} />
          </div>
          <div className="flex items-center gap-[8px] text-[20px]">
            <span>{committee.projects_count}</span>
            <RiFileCopy2Fill size={28} />
          </div>
        </div>
      </div>
    </Link>
  );
}
