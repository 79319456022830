import React from 'react'
import { CgSpinner } from 'react-icons/cg'

export default function CommitteeEditFormLoading() {
    return (
        <div
            className={`grid grid-cols-12 gap-[16px]`}
        >
            <div className={` col-span-12 flex flex-col space-y-[4px]`}>
                <label className={`font-bold`}>
                    الصورة
                    <span className={`text-danger`}>*</span>
                </label>
                <div className={`bg-muted h-[256px] w-full rounded-[8px] animate-pulse`} />
            </div>

            <div className={`col-span-12 flex flex-col space-y-[4px]`}>
                <label className={`font-bold`}>
                    الأسم
                    <span className={`text-danger`}> *</span>
                </label>
                <div className={`rounded-[6px] border-[2px] h-[40px] w-full bg-muted animate-pulse`} />
            </div>
            <div className={`col-span-12 flex flex-col space-y-[4px]`}>
                <label className={`font-bold`}>
                    الرمز
                    <span className={`text-danger`}> *</span>
                </label>
                <div className={`rounded-[6px] border-[2px] h-[40px] w-full bg-muted animate-pulse`} />
            </div>
            <div className={`col-span-2 flex flex-col space-y-[4px]`}>
                <div
                    className={` cursor-wait rounded-[6px] border bg-primary px-[16px] py-[8px] justify-center flex text-white`}
                >
                    <CgSpinner className='animate-spin' size={21} />
                </div>
            </div>
        </div>
    )
}
