import React from 'react';

export default function ProjectCommentsListLaoding() {
  return Array.from({ length: 3 }).map((_, index) => (
    <div
      key={index}
      className="h-24 w-full animate-pulse rounded bg-white text-right"
    />
  ));
}
