import Select, { components } from 'react-select';

const projectTypeOptions = [
  { value: 'NEW_ADOPTION', label: 'جديد / تبني' }, // "New / Adoption"
  { value: 'NEW_PREPARATION', label: 'جديد / اعداد' }, // "New / Preparation"
  { value: 'REVIEW_ADOPTION', label: 'مراجعة / تبني' }, // "Review / Adoption"
  { value: 'REVIEW_PREPARATION', label: 'مراجعة / اعداد' }, // "Review / Preparation"
];

const customComponents = {
  Control: (props) => (
    <components.Control
      {...props}
      className="!rounded-[8px] !border-[2px] !border-muted bg-white px-[8px] py-[2px] !outline-none focus:border-muted"
    />
  ),
  Menu: (props) => (
    <components.Menu
      {...props}
      className="z-10 mt-[8px] !overflow-hidden !rounded-[6px] border border-muted px-[8px] !pt-[6px]"
    />
  ),
  Option: (props) => (
    <components.Option
      {...props}
      className={`mb-[8px] !rounded-[6px] px-[8px] py-[6px] hover:bg-muted ${props.isFocused ? 'bg-gray-200 text-dark' : ''}`}
    />
  ),
};

export default function SelectProjectType({ defaultValue, onChange }) {
  return (
    <Select
      placeholder="نوع المشروع"
      options={projectTypeOptions}
      components={customComponents}
      onChange={onChange}
      value={defaultValue}
    />
  );
}
