import React, { useEffect, useRef, useState } from 'react';
import axiosClient from '../../../axios-client';
import { useNavigate, useParams } from 'react-router-dom';
import UserEditFormLoading from '../../../components/dashboard/users/user-edit-page/UserEditFormLoading';
import { handleFetchError, showToastError, showToastSuccess } from '../../../helper';
import { useAuth } from '../../../contexts/authContext/AuthContext';
import UserEditForm from '../../../components/dashboard/users/user-edit-page/UserEditForm';


export default function UserEdit() {
  const { user_id } = useParams();

  const { authDispatch } = useAuth();
  const navigate = useNavigate();


  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    setIsLoading(true);
    axiosClient
      .get(`/dashboard/users/${user_id}`)
      .then(({ data }) => {
        setUser(data.user);
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        setIsLoading(false);
      });

    return () => { };
  }, [user_id]);


  return (
    <div className={`w-full p-[16px] text-[14px]`}>
      <h1 className='text-[22px] mb-[8px]'>
        تعديل مستخدم
      </h1>
      <div className="rounded-[8px] bg-white p-[16px]">
        {isLoading && <UserEditFormLoading />}
        {!isLoading && (
          <UserEditForm user={user} />
        )}
      </div>
    </div>
  );
}
