import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosClient from '../../../axios-client';
import CommitteeEditFormLoading from '../../../components/dashboard/committees/committees-edit-page/CommitteeEditFormLoading';
import CommitteeEditForm from '../../../components/dashboard/committees/committees-edit-page/CommitteeEditForm';
import { useAuth } from '../../../contexts/authContext/AuthContext';
import { handleFetchError } from '../../../helper';

export default function CommitteesEditPage() {
  const { committee_id } = useParams();
  const navigate = useNavigate();

  const { authDispatch, authState } = useAuth();

  const [committee, setCommittee] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchCommittee = () => {
    setIsLoading(true);
    axiosClient
      .get(`/dashboard/committees/${committee_id}`)
      .then(({ data }) => {
        setCommittee(data.committee);
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchCommittee();
  }, [authState.token]);

  return (
    <div className={`w-full p-[16px] text-[14px]`}>
      <div className="rounded-[8px] bg-white p-[16px]">
        <h1 className="mb-[16px] border-r-[4px] border-dark pr-[8px] text-[16px] font-bold">
          تعديل لجنة
        </h1>
        {isLoading && <CommitteeEditFormLoading />}
        {!isLoading && <CommitteeEditForm committee={committee} />}
      </div>
    </div>
  );
}
