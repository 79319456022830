import React from 'react';
import UsersTableRow from './UsersTableRow';
import UsersTableBodyLoading from './UsersTableBodyLoading';

export default function UsersTableBody({ users, isLoading, onDeleteUser }) {
  if (isLoading) {
    return <UsersTableBodyLoading />;
  }
  if (users) {

    return (
      <tbody>
        {users.map((user, index) => (
          <UsersTableRow
            key={user.id}
            user={user}
            onDeleteUser={onDeleteUser}
            isEven={index % 2 == 0}
          />
        ))}
      </tbody>
    );
  }
}
