import moment from 'moment';
import React, { useState } from 'react';
import { SlOptions } from 'react-icons/sl';
import { Link, useParams } from 'react-router-dom';
import DialogConfirmation from '../../../dialogs/DialogConfirmation';
import { MdEdit } from 'react-icons/md';
import { HiTrash } from 'react-icons/hi2';
import { getRoleName, toDateTime } from '../../../../../helper';

export default function CommitteeMembersTableRow({
  isEven,
  member,
  onRemoveMember,
}) {
  const { committee_id } = useParams();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const handleOnCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleOnOpenDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleOnConfirmDeleteDialog = () => {
    onRemoveMember(committee_id, member.id);
    setIsDeleteDialogOpen(false);
  };

  return (
    <tr
      key={member.id}
      className={`${isEven ? 'bg-white' : 'bg-odd'} my-2 overflow-hidden`}
    >
      <DialogConfirmation
        onClose={handleOnCloseDeleteDialog}
        onConfirm={handleOnConfirmDeleteDialog}
        isOpen={isDeleteDialogOpen}
      >
        <p>
          هل أنت متأكد أنك تريد حذف هذا العضو؟
          <br />
          <br />
          <b>
            {member.first_name_ar} {member.last_name_ar}
          </b>
        </p>
      </DialogConfirmation>
      <td className="rounded-r-[6px] p-[8px]">
        <div className={`flex items-end space-x-[8px] space-x-reverse`}>
          <div className={`broder-light w-[40px] rounded-[4px] border-[2px]`}>
            <img
              className="rounded-[2px]"
              alt={member.country.name_ar}
              title={member.country.name_ar}
              src={`${process.env.REACT_APP_BASE_URL}/storage/flags/${member.country.image}`}
            />
          </div>
          <Link
            to={`/dashboard/users/${member.id}`}
            className={`font-bold text-primary underline hover:text-primary-hover`}
          >
            {member.first_name_ar} {member.last_name_ar}
          </Link>
        </div>
      </td>
      <td className={`p-[16px]`}>{getRoleName(member.committee_user.role)}</td>
      <td className={`p-[16px]`}>
        {toDateTime(moment(member.committee_user.created_at))}
      </td>
      <td className={`w-[64px] rounded-l-[6px] p-[16px]`}>
        <div className={`group relative flex justify-end gap-[4px]`}>
          <button className="rounded-[6px] bg-muted p-[8px]">
            <SlOptions size={16} />
          </button>
          <div className="absolute left-0 top-0 z-50 hidden group-hover:block">
            <div className="z-50 mt-[40px] min-w-[200px] rounded-[8px] border-[2px] border-muted bg-white p-[8px]">
              <ul className="flex flex-col space-y-[4px]">
                <li>
                  <Link
                    to={`/dashboard/committees/${committee_id}/members/${member.id}/edit`}
                    className={`flex gap-[8px] rounded-[6px] px-[16px] py-[8px] text-[13px] hover:bg-muted`}
                  >
                    <MdEdit size={20} />
                    <span>تعديل صلاحيات </span>
                  </Link>
                </li>
                <li>
                  <button
                    onClick={handleOnOpenDeleteDialog}
                    className={`flex w-full gap-[8px] rounded-[6px] bg-danger/10 px-[16px] py-[8px] text-[13px] text-danger hover:bg-muted`}
                  >
                    <HiTrash size={20} />
                    <span>حذف</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
}
