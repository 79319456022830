import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/authContext/AuthContext';
import axiosClient from '../../../axios-client';
import UsersTable from '../../../components/dashboard/users/users-page/UsersTable';
import UsersTablePagination from '../../../components/dashboard/users/users-page/UsersTablePagination';
import { handleFetchError } from '../../../helper';

export default function UsersPage() {
  const { authDispatch, authState } = useAuth();
  const navigate = useNavigate();

  // Get the search params from the current location
  const [searchParams] = useSearchParams();

  const [pagination, setPagination] = useState(null);
  const [users, setUsers] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  const fetchUsers = () => {
    setIsLoading(true);
    axiosClient
      .get(`/dashboard/users?${searchParams.toString()}`)
      .then(({ data }) => {
        setUsers(data.users);
        setPagination({
          last_page_url: data.last_page_url,
          links: data.links,
          first_page_url: data.first_page_url,
          current_page: data.current_page,
        });
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteUser = (userId) => {
    axiosClient
      .delete(`/dashboard/users/${userId}`)
      .then(() => {
        fetchUsers();
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => { });
  };

  useEffect(() => {
    fetchUsers();
  }, [authState.token, searchParams]);

  return (
    <div className={`w-full p-[16px]`}>
      <div className={`mb-[8px] flex items-end justify-between`}>
        <h1 className='text-[22px]'>
          المستخدمين
        </h1>
        <Link
          to={`/dashboard/users/create`}
          className={`rounded-[6px] bg-primary px-[16px] py-[8px] text-white hover:bg-primary-hover`}
        >
          إضافة مستخدم
        </Link>
      </div>
      <UsersTable
        isLoading={isLoading}
        users={users}
        onDeleteUser={deleteUser}
      />
      {!isLoading && <UsersTablePagination pagination={pagination} />}
    </div>
  );
}
