import React from 'react';
import { FaUserAlt } from 'react-icons/fa';
import { RiNotificationFill } from 'react-icons/ri';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/authContext/AuthContext';
import { AUTH_ACTIONS } from '../../../contexts/authContext/authActions';

export default function Header() {
  const { authDispatch, authState } = useAuth();
  const navigate = useNavigate();

  const handleOnClickLogout = () => {
    authDispatch({
      type: AUTH_ACTIONS.LOGOUT,
    });
    navigate('/login', {
      replace: true,
    });
  };
  return (
    <div className={`sticky left-0 top-0 z-50 bg-white p-[16px]`}>
      <div className={`flex w-full items-center justify-between`}>
        <div className="">
          <h1 className={`font-bold`}>مركز المواصفات والمقاييس</h1>
        </div>
        <div className="flex gap-[8px]">
          <div className="group relative">
            <button className="rounded-[8px] bg-muted p-[12px] hover:bg-muted-hover">
              <FaUserAlt size={16} />
            </button>
            <div
              className={`absolute left-0 top-0 z-50 hidden w-[256px] pt-[64px] group-hover:block `}
            >
              <ul
                className={`flex flex-col space-y-[8px] rounded-[6px] border-[2px] border-muted bg-white p-[8px] shadow-md`}
              >
                <li>
                  <Link
                    className={`flex rounded-[4px] bg-muted px-[16px] py-[8px] hover:bg-muted-hover`}
                    to={`/forums/members/${authState.currentUser.id}`}
                  >
                    {authState.currentUser.first_name_ar}{' '}
                    {authState.currentUser.last_name_ar}
                  </Link>
                </li>
                <li>
                  <button
                    className={`flex w-full rounded-[4px] bg-danger px-[16px] py-[8px] text-white hover:bg-danger-hover`}
                    onClick={handleOnClickLogout}
                  >
                    تسجيل الخروج
                  </button>
                </li>
              </ul>
            </div>
          </div>
          {/* <button className="rounded-[8px] bg-muted p-[12px] hover:bg-muted-hover">
            <RiNotificationFill size={16} />
          </button> */}
        </div>
      </div>
    </div>
  );
}
