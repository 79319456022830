import moment from 'moment';
import axiosClient from '../../axios-client';
import { Link, useNavigate } from 'react-router-dom';
import { MdEdit } from 'react-icons/md';
import { useAuth } from '../../contexts/authContext/AuthContext';
import { handleFetchError } from '../../helper';

export default function DatabasesProjectDetails({ project }) {
  const { authState, authDispatch } = useAuth();

  const navigate = useNavigate();

  const handleOnClickDownload = () => {
    axiosClient
      .get(`/databases/projects/${project.id}/download`, {
        responseType: 'blob',
        params: {
          fileType: 'docx', // or 'docx' depending on the desired format
        },
      })
      .then((response) => {
        // Create a URL object from the Blob data
        const url = window.URL.createObjectURL(new Blob([response.data]));

        // Create a link element to trigger the download
        const link = document.createElement('a');
        link.href = url;

        const fileName = `${project.symbol}.docx`;

        link.setAttribute('download', fileName); // Set the file name for the download
        document.body.appendChild(link); // Append to body

        // Programmatically trigger the download
        link.click();

        // Cleanup: remove the link and release the URL object
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {});
  };
  return (
    <div className="relative w-full rounded-[8px] bg-white p-[8px]">
      <div className="absolute left-[8px] top-[8px]">
        {authState.currentUser.country_id == project.country_id && (
          <Link
            to={`/databases/projects/${project.id}/edit`}
            className="flex rounded-[4px] bg-primary p-[6px] text-white"
          >
            <MdEdit size={18} />
          </Link>
        )}
      </div>
      <h3 className="text-[20px] font-bold">{project.symbol}</h3>
      <ul>
        <li>
          <span className="font-bold">الجهة المقترحة : </span>
          <span>{project.country.name_ar}</span>
        </li>
        <li>
          <span className="font-bold">نوع الوثيقة : </span>
          <span>{project.document_type}</span>
        </li>
        <li>
          <span className="font-bold"> اسم المشروع بالعربية : </span>
          <span> {project.project_name_arabic}</span>
        </li>
        <li>
          <span className="font-bold">اسم المشروع بالإنجليزية : </span>
          <span>{project.project_name_english}</span>
        </li>
        <li>
          <span className="font-bold">اللجنة الفنية : </span>
          <span>
            {project.committee.name} - {project.committee.symbol}
          </span>
        </li>
        <li>
          <span className="font-bold">المراجع : </span>
          <span>{project.references}</span>
        </li>
        <li>
          <span className="font-bold">تاريخ الاضافة : </span>
          <span>
            {moment(project.created_at).format('MMMM Do YYYY, h:mm a')}
          </span>
        </li>
      </ul>
      <button
        onClick={handleOnClickDownload}
        className="mt-[8px] w-full rounded-[6px] bg-success py-[8px] text-white hover:bg-success-hover"
      >
        تحميل
      </button>
    </div>
  );
}
