import React from 'react';
import UsersTableBodyLoading from './UsersTableBodyLoading';
import UsersTableBody from './UsersTableBody';
import UsersTableHeader from './UsersTableHeader';

export default function UsersTable({ isLoading, users, onDeleteUser }) {
  return (
    <table
      className={`w-full border-separate border-spacing-y-[8px] text-right text-[14px]`}
    >
      <UsersTableHeader />
      <UsersTableBody
        users={users}
        isLoading={isLoading}
        onDeleteUser={onDeleteUser}
      />

    </table>
  );
}
