export default function OverviewCard({ icons, label, number }) {
  return (
    <div className="col-span-12 flex items-center justify-between rounded-[8px] bg-white p-[16px] lg:col-span-4">
      <div className="flex items-center gap-[16px] text-secondary">
        {icons}
        <span>{label}</span>
      </div>
      <div className="text-[20px] font-bold">{number}</div>
    </div>
  );
}
