import { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import axiosClient from '../../../axios-client';
import { handleFetchError } from '../../../helper';
import { useAuth } from '../../../contexts/authContext/AuthContext';
import { useNavigate } from 'react-router-dom';

// Custom function to format the option label with flag and name
const formatOptionLabel = (option) => (
  <div className="flex justify-start gap-[8px]">
    <img
      className="h-[22px] rounded-[4px] border border-muted"
      src={`${process.env.REACT_APP_BASE_URL}/storage/flags/${option.image}`}
      alt={option.label}
    />
    <span>{option.label}</span>
  </div>
);

const customComponents = {
  Control: (props) => (
    <components.Control
      {...props}
      className="!rounded-[8px] !border-[2px] !border-muted bg-white px-[8px] py-[2px] !outline-none focus:border-muted"
    />
  ),
  Menu: (props) => (
    <components.Menu
      {...props}
      className="z-10 mt-[8px] !overflow-hidden !rounded-[6px] border border-muted px-[8px] !pt-[6px]"
    />
  ),
  Option: (props) => (
    <components.Option
      {...props}
      className={`mb-[8px] !rounded-[6px] !py-[8px] px-[8px] hover:bg-muted ${
        props.isFocused ? 'bg-gray-200 text-indigo-600 hover:text-dark' : ''
      }`}
    />
  ),
};

export default function SelectCountries({ defaultValue, onSelect }) {
  const { authDispatch } = useAuth();
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  useEffect(() => {
    axiosClient
      .get(`/countries`)
      .then(({ data }) => {
        const options = data.countries.map((country) => ({
          value: country.id,
          label: country.name_ar,
          image: country.image,
        }));
        setOptions(options);
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {});

    return () => {};
  }, []);

  return (
    <Select
      placeholder="Select a Country"
      options={options}
      components={customComponents}
      formatOptionLabel={formatOptionLabel}
      onChange={(selectedOption) => onSelect(selectedOption)}
      value={defaultValue}
    />
  );
}
