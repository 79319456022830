import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { handleFetchError, timeAgoInArabic } from '../../../../../helper';
import moment from 'moment';
import ProjectCommentEdit from './ProjectCommentEdit';
import { MdEdit } from 'react-icons/md';
import axiosClient from '../../../../../axios-client';
import { useProjects } from '../../../../../contexts/forums/projectsContext/ProjectsContext';
import { useCommittee } from '../../../../../contexts/forums/committeeContext/CommitteeContext';
import { useAuth } from '../../../../../contexts/authContext/AuthContext';
import { LiaDownloadSolid } from 'react-icons/lia';

export default function ProjectCommentsListItem({ comment, onEditComment }) {
  const { authDispatch, authState } = useAuth();
  const { committee_id, project_id } = useParams();

  const navigate = useNavigate();

  const [isEditable, setIsEditable] = useState(false);
  const [isInProgress, setIsInProgress] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleOnClickShowEditComment = () => {
    setIsEditable(!isEditable);
  };

  const handleOnClickSend = (payload) => {
    if (isInProgress) {
      return;
    }
    setIsInProgress(true);
    axiosClient
      .post(
        `/committees/${committee_id}/projects/${project_id}/comments/${comment.id}`,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .then(({ data }) => {
        onEditComment(data.comment);
        setIsEditable(false);
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        setIsInProgress(false);
      });
  };

  const handleOnClickCancel = () => {
    setIsEditable(false);
  };

  const handleOnClickDownload = (attachment_name, attachment_id) => {
    setIsDownloading(true);
    axiosClient
      .get(
        `/committees/${committee_id}/projects/${project_id}/comments/${comment.id}/attachments/${attachment_id}/download`,
        {
          responseType: 'blob',
        },
      )
      .then((response) => {
        // Create a URL object from the Blob data
        const url = window.URL.createObjectURL(new Blob([response.data]));
        // Create a link element to trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', attachment_name); // Set the file name for the download
        document.body.appendChild(link); // Append to body
        // Programmatically trigger the download
        link.click();
        // Cleanup: remove the link and release the URL object
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  if (!comment.has_comment) {
    return (
      <div
        className={`relative overflow-hidden rounded-[8px] border-[2px] ${authState.currentUser.id === comment.user_id ? 'border-primary' : ' border-muted'} bg-muted`}
      >
        <div className={`flex gap-[4px] p-[8px] text-[14px]`}>
          <img
            className="w-[32px] overflow-hidden rounded-[5px] border-2 border-muted object-contain"
            src={`${process.env.REACT_APP_BASE_URL}/storage/flags/${comment.user.country.image}`}
            alt={comment.user.country.name_ar}
          />

          <Link className="font-bold hover:text-primary">
            {comment.user.first_name_ar} {comment.user.last_name_ar}
          </Link>
          <div
            title={moment(comment.created_at).format('MMMM Do YYYY, h:mm a')}
            className="text-secondary"
          >
            {timeAgoInArabic(comment.created_at)}
          </div>
        </div>
        <div className="bg-white ">
          <article
            className="p-[8px] !text-justify text-[14px]"
            dangerouslySetInnerHTML={{ __html: `لا يوجد تعليق` }}
          />
        </div>
      </div>
    );
  }

  if (comment.has_comment) {
    return (
      <div
        className={`relative overflow-hidden rounded-[8px] border-[2px] ${authState.currentUser.id === comment.user_id ? 'border-primary' : ' border-muted'} bg-muted`}
      >
        <div className="absolute left-[8px] top-[8px]">
          <div className="flex gap-[8px]">
            {authState.currentUser.id === comment.user_id && !isEditable && (
              <button
                onClick={handleOnClickShowEditComment}
                className="rounded-[4px] bg-primary p-[4px] text-white hover:bg-primary-hover"
              >
                <MdEdit size={16} />
              </button>
            )}
          </div>
        </div>

        <div className={`flex gap-[4px] p-[8px] text-[14px]`}>
          <img
            className="w-[32px] overflow-hidden rounded-[5px] border-2 border-muted object-contain"
            src={`${process.env.REACT_APP_BASE_URL}/storage/flags/${comment.user.country.image}`}
            alt={comment.user.country.name_ar}
          />

          <Link className="font-bold hover:text-primary">
            {comment.user.first_name_ar} {comment.user.last_name_ar}
          </Link>
          {comment.created_at !== comment.updated_at ? (
            <div
              title={moment(comment.updated_at).format('MMMM Do YYYY, h:mm a')}
              className="text-secondary"
            >
              تم التعديل {timeAgoInArabic(comment.updated_at)}
            </div>
          ) : (
            <div
              title={moment(comment.created_at).format('MMMM Do YYYY, h:mm a')}
              className="text-secondary"
            >
              {timeAgoInArabic(comment.created_at)}
            </div>
          )}
        </div>
        <div className="bg-white">
          {isEditable && (
            <ProjectCommentEdit
              isInProgress={isInProgress}
              onSend={handleOnClickSend}
              onCancel={handleOnClickCancel}
              comment={comment}
            />
          )}
          {!isEditable && (
            <div>
              <article
                className="p-[8px] !text-justify text-[14px]"
                dangerouslySetInnerHTML={{ __html: comment.body }}
              />
              {comment.attachments && (
                <div className="p-[8px] pt-[0px]">
                  {comment.attachments.map((attachment, index) => (
                    <button
                      onClick={() =>
                        handleOnClickDownload(
                          ` مرفق - ${index + 1}.${attachment.file.split('.')[attachment.file.split('.').length - 1]}`,
                          attachment.id,
                        )
                      }
                      className="flex items-center gap-[2px] rounded-[4px] border border-primary-hover bg-primary/90 px-[4px] py-[2px] text-[12px] text-white hover:bg-primary"
                    >
                      <LiaDownloadSolid size={14} />
                      <div>مرفق - {index + 1}</div>
                    </button>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
