import { useEffect, useLayoutEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Header from '../../components/formus/inc/Header';
import { useAuth } from '../../contexts/authContext/AuthContext';
import { CommitteeProvider } from '../../contexts/forums/committeeContext/CommitteeContext';
import { Toaster } from 'react-hot-toast';
import { ProjectsProvider } from '../../contexts/forums/projectsContext/ProjectsContext';
import NavbarOpen from '../../components/formus/inc/NavbarOpen';

export default function ForumsLayout() {
  const { authState } = useAuth();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    if (isOpen) {
      setIsOpen(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [isOpen]);

  if (authState.currentUser.type === 'ADMIN') {
    return <Navigate to={`/dashboard/users`} replace />;
  }
  if (authState.currentUser.type === 'DATABASES_USER') {
    return <Navigate to={`/databases`} replace />;
  }

  return (
    <CommitteeProvider>
      <Toaster />
      <Header />
      <div className="flex min-h-screen">
        <NavbarOpen />
        <Outlet />
      </div>
    </CommitteeProvider>
  );
}
