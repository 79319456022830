import ProjectCommentsListItem from './ProjectCommentsListItem';

export default function ProjectCommentsList({ comments, onEditComment }) {
  
  if (comments && comments.length === 0) {
    return (
      <div className="rounded-[8px] bg-white p-[16px]">لا توجد تعليقات</div>
    );
  }
  if (comments) {
    return comments.map((comment) => (
      <ProjectCommentsListItem onEditComment={onEditComment} key={comment.id} comment={comment} />
    ));
  }
}
