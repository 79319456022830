import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axiosClient from '../../../../axios-client';
import {
  handleFetchError,
  showToastError,
  showToastSuccess,
} from '../../../../helper';
import { AUTH_ACTIONS } from '../../../../contexts/authContext/authActions';
import { useAuth } from '../../../../contexts/authContext/AuthContext';
import CommitteeMembersTable from '../../../../components/dashboard/committees/committee-members/committee-members-page/CommitteeMembersTable';

export default function CommitteeMembersPage() {
  const { committee_id } = useParams();

  const { authDispatch } = useAuth();
  const navigate = useNavigate();
  const [members, setMembers] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isCommitteeLoading, setIsCommitteeLoading] = useState(true);
  const [committee, setCommittee] = useState(null);

  const fetchCommitteeMembers = () => {
    setIsLoading(true);
    axiosClient
      .get(`/dashboard/committees/${committee_id}/members`)
      .then(({ data }) => {
        setMembers(data.members);
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchCommittee = () => {
    setIsCommitteeLoading(true);
    axiosClient
      .get(`/dashboard/committees/${committee_id}`)
      .then(({ data }) => {
        setCommittee(data.committee);
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        setIsCommitteeLoading(false);
      });
  };

  const handleRemoveMember = (committeeId, memberId) => {
    axiosClient
      .delete(`/dashboard/committees/${committeeId}/members/${memberId}`)
      .then(({ data }) => {
        showToastSuccess(data.message);
        fetchCommitteeMembers();
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchCommitteeMembers();
    fetchCommittee();
  }, [committee_id]);

  return (
    <div className={`w-full p-[16px]`}>
      <div className={`mb-[8px] flex items-end justify-between`}>
        <h1 className="text-[22px]">
          {!isCommitteeLoading && (
            <>
              <span className="text-muted-hover">
                <Link
                  to={`/dashboard/committees`}
                  className="hover:text-secondary"
                >
                  اللجان
                </Link>{' '}
                / {committee?.name}
              </span>{' '}
              / الأعضاء
            </>
          )}
        </h1>
        <Link
          to={`/dashboard/committees/${committee_id}/members/add`}
          className={`rounded-[6px] bg-primary px-[16px] py-[8px] text-white hover:bg-primary-hover`}
        >
          إضافة عضو
        </Link>
      </div>
      <CommitteeMembersTable
        members={members}
        isLoading={isLoading}
        onRemoveMember={handleRemoveMember}
      />
    </div>
  );
}
