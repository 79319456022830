import React, { useState } from 'react'
import { FaUserMinus, FaUserPlus, FaUsers } from 'react-icons/fa6';
import { MdEdit } from 'react-icons/md';
import { SlOptions } from 'react-icons/sl';
import { Link } from 'react-router-dom';
import { HiTrash } from "react-icons/hi2";
import CommitteesTableRow from './CommitteesTableRow';


export default function CommitteesTableBody({ committees, onDeleteCommittee }) {

    return (
        <tbody>
            {
                committees.map(
                    (committee, index) =>
                        <CommitteesTableRow
                            isEven={index % 2 === 0}
                            committee={committee}
                            onDeleteCommittee={onDeleteCommittee}
                        />
                )
            }

        </tbody >
    )
}
