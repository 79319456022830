import React, { useRef } from 'react';
import { GrPowerReset } from 'react-icons/gr';
import axiosClient from '../../../axios-client';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/authContext/AuthContext';
import {
  generateRandomPassword,
  handleFetchError,
  showToastSuccess,
} from '../../../helper';

export default function UserChangePasswordPage() {
  const { user_id } = useParams();
  const { authDispatch } = useAuth();
  const navigate = useNavigate();

  const newPasswordRef = useRef(null);

  const handleOnSubmitChangePasswordForm = (event) => {
    event.preventDefault();
    const payload = {
      new_password: newPasswordRef.current.value,
    };
    axiosClient
      .put(`/dashboard/users/${user_id}/change/password`, payload)
      .then(({ data }) => {
        showToastSuccess(data.message);
        navigate(`/dashboard/users`);
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => { });
  };

  const handleOnClickGenerateNewPasswordButton = (event) => {
    newPasswordRef.current.value = generateRandomPassword();
  };

  return (
    <div className={`w-full p-[16px] text-[14px]`}>
      <h1 className='text-[22px] mb-[8px]'>
      تغيير كلمة مرور
        </h1>
      <div className="rounded-[8px] bg-white p-[16px]">
        <form
          onSubmit={handleOnSubmitChangePasswordForm}
          className={`grid grid-cols-12 gap-[16px]`}
          method="POST"
        >
          <div className={`col-span-12 flex-col space-y-[4px] `}>
            <label htmlFor={`IdLastNameAr`} className={`font-bold`}>
              كلمة مرور الجديدة
              <span className={`text-danger`}>*</span>
            </label>
            <div className="flex w-full gap-[4px]">
              <input
                ref={newPasswordRef}
                className={`w-full rounded-[6px] border-[2px] border-light px-[16px] py-[8px] outline-none`}
                type={`text`}
                name={`new_passwprd`}
                id={`ID_NEW_PASSWORD`}
              />
              <button
                onClick={handleOnClickGenerateNewPasswordButton}
                className="rounded-[6px] bg-success px-[12px] py-[8px] text-white hover:bg-success-hover"
                type="button"
              >
                <GrPowerReset size={20} />
              </button>
            </div>
          </div>
          <div className={`col-span-2 flex flex-col space-y-[4px]`}>
            <button
              type={`submit`}
              className={` rounded-[6px] border bg-primary px-[16px] py-[8px] text-white hover:bg-primary-hover`}
            >
              تغيير
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
