import { AUTH_ACTIONS } from './authActions';

export const authReducer = (state, action) => {
  switch (action.type) {
    case AUTH_ACTIONS.LOGIN:
      localStorage.setItem('ACCESS_TOKEN', action.payload.token);
      return {
        ...state,
        currentUser: action.payload.user,
        token: action.payload.token,
      };

    case AUTH_ACTIONS.SET_USER:
      return {
        ...state,
        currentUser: action.payload.user,
      };
    case AUTH_ACTIONS.LOGOUT:
      localStorage.removeItem('ACCESS_TOKEN');
      return {
        ...state,
        currentUser: null,
        token: null,
      };
    default:
      return state;
  }
};
