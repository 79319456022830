import { useCommittee } from '../../../contexts/forums/committeeContext/CommitteeContext';

export default function CommitteeTitle() {
  const { committeeState } = useCommittee();

  if (committeeState.currentCommitteeIsLoading) {
    return (
      <div className="relative pr-[16px]">
        <div className="absolute right-0 top-0 h-full w-[8px] animate-pulse rounded-[3px] bg-secondary"></div>
        <div className="mb-[8px] h-[36px] w-[300px] animate-pulse rounded-[4px] bg-white" />
        <div className="h-[30px] w-[98px] animate-pulse rounded-[4px] bg-white" />
      </div>
    );
  }
  if (committeeState.currentCommittee) {
    return (
      <div className="relative pr-[16px]">
        <div className="absolute right-0 top-0 h-full w-[8px] rounded-[3px] bg-secondary"></div>
        <h1 className="mb-[4px] text-[24px] font-bold">
          {committeeState.currentCommittee.name}
        </h1>
        <p className="text-[20px] text-secondary">
          {committeeState.currentCommittee.symbol}
        </p>
      </div>
    );
  }
}
