import React, { useEffect, useRef, useState } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';
import SelectProjectType from '../../ui/SelectProjectType';
import SelectCountries from '../../ui/SelectCountries';
import { TbFileSearch, TbFilter, TbFilterX } from 'react-icons/tb';
import { MdClose, MdEdit } from 'react-icons/md';
import { HiDownload, HiOutlineDocumentAdd } from 'react-icons/hi';
import { useCommittee } from '../../../../contexts/forums/committeeContext/CommitteeContext';
import axiosClient from '../../../../axios-client';
import { handleFetchError } from '../../../../helper';
import { useAuth } from '../../../../contexts/authContext/AuthContext';

export default function ProjectsListFilter() {
  const { committee_id } = useParams();
  const { authDispatch } = useAuth();

  const [isDownloading, setIsDownloading] = useState(false);

  const { committeeState } = useCommittee();
  const navigate = useNavigate();

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [documents, setDocuments] = useState(null);

  const filterRef = useRef(null);
  const projectTitleRef = useRef(null);
  const memberNameRef = useRef(null);
  const projectReferenceRef = useRef(null);

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedType, setSelectedType] = useState(null);

  const handleOnOpenFilter = () => {
    setIsFilterOpen(true);
    filterRef.current.classList.remove(`-translate-x-full`);
    filterRef.current.classList.add(`translate-x-0`);
  };
  const handleOnCloseFilter = () => {
    setIsFilterOpen(false);
    filterRef.current.classList.remove(`translate-x-0`);
    filterRef.current.classList.add(`-translate-x-full`);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const queryStringParams = {};

    if (
      projectTitleRef.current &&
      projectTitleRef.current.value.trim() !== ''
    ) {
      queryStringParams['project_title'] = projectTitleRef.current.value;
    }
    if (selectedCountry) {
      queryStringParams['country_id'] = selectedCountry.value;
    }
    if (selectedType) {
      queryStringParams['type'] = selectedType.value;
    }

    if (memberNameRef.current && memberNameRef.current.value.trim() !== '') {
      queryStringParams['member_name'] = memberNameRef.current.value;
    }

    if (
      projectReferenceRef.current &&
      projectReferenceRef.current.value.trim() !== ''
    ) {
      queryStringParams['project_reference'] =
        projectReferenceRef.current.value;
    }

    const queryString = new URLSearchParams(queryStringParams).toString();
    handleOnCloseFilter();
    navigate(`?${queryString}`);
  };

  const handleOnReset = (e) => {
    e.preventDefault();
    setSelectedCountry(null);
    setSelectedType(null);
    handleOnCloseFilter();
    navigate(`?`);
  };

  const getDocuments = () => {
    axiosClient
      .get(`/committees/${committee_id}/documents`)
      .then(({ data }) => {
        setDocuments(data.documents);
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      });
  };

  useEffect(() => {
    getDocuments();
  }, [committee_id]);

  const handleOnClickDownload = (document_name, document_id) => {
    setIsDownloading(true);
    axiosClient
      .get(`/committees/${committee_id}/documents/${document_id}/download`, {
        responseType: 'blob',
      })
      .then((response) => {
        const filename = document_name;
        let blob = new Blob([response.data], {
          type: 'application/octet-stream',
        });

        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          // IE workaround for "HTML7007: One or more blob URLs were
          // revoked by closing the blob for which they were created.
          // These URLs will no longer resolve as the data backing
          // the URL has been freed."
          window.navigator.msSaveBlob(blob, filename);
        } else {
          let blobURL = window.URL.createObjectURL(blob);
          let tempLink = document.createElement('a');
          tempLink.style.display = 'none';
          tempLink.href = blobURL;
          tempLink.download = filename;
          tempLink.click();
          window.URL.revokeObjectURL(blobURL);
        }
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  return (
    <div>
      {isFilterOpen && (
        <div
          onClick={handleOnCloseFilter}
          className="fixed bottom-0 left-0 right-0 top-0 z-10 h-full w-full bg-dark/20 backdrop-blur"
        ></div>
      )}
      <div
        ref={filterRef}
        className="fixed bottom-0 left-0 top-[70px] z-10 w-full -translate-x-full bg-light p-[16px] transition md:w-[500px]"
      >
        <div className={`flex flex-col gap-[16px]`}>
          <div className="flex  items-center justify-between">
            <h3 className="text-[20px] font-semibold">فلتر</h3>
            <button
              onClick={handleOnCloseFilter}
              className={`rounded-[6px] border-[2px] border-muted-hover bg-white p-[8px] transition duration-150 ease-in-out hover:bg-muted-hover`}
            >
              <MdClose size={22} />
            </button>
          </div>
          {/* <div className="h-px w-full bg-muted" /> */}
          <form
            className="flex h-full flex-col gap-[8px]"
            onSubmit={handleOnSubmit}
            onReset={handleOnReset}
          >
            <SelectProjectType
              onChange={(type) => {
                setSelectedType(type);
              }}
              defaultValue={selectedType}
            />
            <SelectCountries
              onSelect={(country) => setSelectedCountry(country)}
              defaultValue={selectedCountry}
            />
            <input
              placeholder="عنوان المشروع"
              type="text"
              name="project_title"
              ref={projectTitleRef}
              className="rounded-[8px] border-[2px] border-muted bg-white px-[16px] py-[8px] outline-none"
            />
            <input
              placeholder="اسم عضو"
              type="text"
              name="member_name"
              ref={memberNameRef}
              className="rounded-[8px] border-[2px] border-muted bg-white px-[16px] py-[8px] outline-none"
            />
            <input
              placeholder="مرجع"
              type="text"
              name="project_reference"
              ref={projectReferenceRef}
              className="rounded-[8px] border-[2px] border-muted bg-white px-[16px] py-[8px] outline-none"
            />
            <div className="mt-[8px] flex gap-[8px]">
              <button
                type="reset"
                className="flex w-full items-center gap-[8px] rounded-[8px] border-[2px] border-muted-hover bg-muted px-[10px] py-[8px] pl-[16px] transition duration-150 ease-in-out hover:bg-muted-hover"
              >
                <TbFilterX size={20} />
                <div>مسح</div>
              </button>
              <button className="flex w-full items-center gap-[8px] rounded-[8px] border-[2px] border-primary-hover bg-primary px-[10px] py-[8px] pl-[16px] text-white transition duration-150 ease-in-out hover:bg-primary-hover">
                <TbFileSearch size={22} />
                <div>بحث</div>
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="pb-[16px]">
        <div
          className={`flex w-full flex-col items-center justify-between gap-[8px] md:w-auto md:flex-row`}
        >
          <div
            className={`flex w-full flex-col items-center gap-[8px] md:w-auto md:flex-row`}
          >
            <Link
              to={`/forums/committees/${committee_id}/projects/create`}
              title="إضافة مشروع مواصفة جديد"
              className={`flex w-full items-center gap-[8px] rounded-[8px] border-[2px] border-primary-hover bg-primary p-[8px] pl-[16px] text-white transition duration-150 ease-in-out hover:bg-primary-hover md:w-auto`}
            >
              <HiOutlineDocumentAdd size={20} />
              <div className="line-clamp-1">إضافة مشروع مواصفة جديد</div>
            </Link>
            {committeeState.currentCommittee &&
              committeeState.currentCommittee.pivot.role !== 'MEMBER' && (
                <>
                  <Link
                    to={`/forums/committees/${committee_id}/documents/create`}
                    title="إضافة وثيقة اللجنة"
                    className={`flex w-full items-center gap-[8px] rounded-[8px] border-[2px] border-muted-hover bg-muted p-[8px] pl-[16px] text-secondary transition duration-150 ease-in-out hover:bg-muted-hover md:w-auto`}
                  >
                    <HiOutlineDocumentAdd size={20} />
                    <div className="line-clamp-1">إضافة وثيقة اللجنة</div>
                  </Link>
                  {/* <Link
                    to={`/forums/committees/${committee_id}/plans`}
                    title="إضافة وثيقة اللجنة"
                    className={`flex w-full items-center gap-[8px] rounded-[8px] border-[2px] border-muted-hover bg-muted p-[8px] pl-[16px] text-secondary transition duration-150 ease-in-out hover:bg-muted-hover md:w-auto`}
                  >
                    <HiOutlineDocumentAdd size={20} />
                    <div className="line-clamp-1">الخطة السنوية</div>
                  </Link> */}
                </>
              )}
          </div>
          <div
            className={`flex w-full flex-col items-center gap-[8px] md:w-auto md:flex-row`}
          >
            {documents && documents.length > 0 && (
              <div
                className={`group relative flex w-full items-center gap-[8px] rounded-[8px] border-[2px] border-muted-hover bg-white px-[16px] py-[8px] transition duration-150 ease-in-out hover:bg-muted-hover md:w-auto`}
              >
                <div>وثائق اللجنة</div>
                <div className="absolute left-0 top-[0] z-10 hidden w-full pt-[48px] group-hover:block md:w-[500px]">
                  <div className="flex w-full flex-col gap-[4px] rounded-[8px] border-[2px] border-muted bg-white p-[4px]">
                    {documents.map((document) => (
                      <div className="flex w-full cursor-default gap-[8px] border-b-[2px]  px-[8px] py-[8px] last:border-0">
                        <div className="line-clamp-1 w-full cursor-default text-right">
                          {document.title}
                        </div>
                        <div className="flex gap-[4px]">
                          <button
                            className="rounded-[4px] bg-primary p-[4px] text-white hover:bg-primary-hover"
                            onClick={() =>
                              handleOnClickDownload(
                                `${document.title}.${document.file.split('.')[document.file.split('.').length - 1]}`,
                                document.id,
                              )
                            }
                          >
                            <HiDownload size={20} />
                          </button>
                          {committeeState.currentCommittee &&
                            committeeState.currentCommittee.pivot.role !==
                              'MEMBER' && (
                              <>
                                <Link
                                  to={`/forums/committees/${committee_id}/documents/${document.id}/edit`}
                                  className="rounded-[4px] bg-muted p-[4px]  hover:bg-muted-hover"
                                >
                                  <MdEdit size={20} />
                                </Link>
                                {/* <button className='rounded-[4px] text-white hover:bg-danger-hover bg-danger p-[4px]' onClick={() => handleOnClickDownload(`${document.title}.${document.file.split('.')[1]}`, document.id)}>
                                <FaTrashAlt size={20} />
                              </button> */}
                              </>
                            )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            <button
              onClick={handleOnOpenFilter}
              className={`flex w-full items-center gap-[8px] rounded-[8px] border-[2px] border-muted-hover bg-white px-[16px] py-[8px] transition duration-150 ease-in-out hover:bg-muted-hover md:w-auto`}
            >
              <TbFilter size={20} />
              <div className="font-semibold">فلتر</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
