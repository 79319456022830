import React from 'react';
import ProjectsListItem from './ProjectsListItem';
import { VscFiles } from 'react-icons/vsc';

export default function ProjectsList({ projects, isLoading }) {
  if (isLoading) {
    return (
      <div className="mt-[16px] grid grid-cols-1 gap-[8px]">
        {[...Array(8)].map((_, index) => (
          <div
            key={index}
            className="col-span-1 h-[149px] w-full animate-pulse rounded-[8px] bg-white"
          />
        ))}
      </div>
    );
  }

  if (projects && projects.length === 0) {
    return (
      <div className="mt-[16px] flex h-[256px] flex-col items-center justify-center rounded-[8px] border-[2px] border-muted bg-muted text-[20px] font-bold text-secondary">
        <div className="mb-[16px]">
          <VscFiles size={32} />
        </div>
        <p>لا يوجد اي مشروع حاليا</p>
      </div>
    );
  }

  return (
    <div className="mt-[16px] grid grid-cols-2 gap-[8px]">
      {projects?.map((project, i) => (
        <ProjectsListItem
          key={project.id}
          project={project}
          isEven={i % 2 === 0}
        />
      ))}
    </div>
  );
}
