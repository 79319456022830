import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/authContext/AuthContext';
import { AUTH_ACTIONS } from '../../../contexts/authContext/authActions';

export default function TopNavbar() {
  const { authDispatch, authState } = useAuth();
  const navigate = useNavigate();

  const handleOnClickLogout = () => {
    authDispatch({
      type: AUTH_ACTIONS.LOGOUT,
    });
    navigate('/login', {
      replace: true,
    });
  };

  return (
    <div className={`bg-white px-[20px] py-[9px]`}>
      <div className={`flex items-center justify-between`}>
        <div className={`flex items-center space-x-[8px] space-x-reverse`}>
          <img src={`/assets/images/aidsmo_logo.svg`} alt={`logo`} width={56} />
          <div>
            <h1 className={`font-bold`}>مركز المواصفات والمقاييس</h1>
            <p className={`font-light`}>
              المنظمة العربية للتنمية الصناعية والتقييس والتعدين
            </p>
          </div>
        </div>
        <div className="flex space-x-2 space-x-reverse">
          <div
            className={`group relative rounded-[4px] bg-muted px-[16px] py-[8px]`}
          >
            <span>
              {authState.currentUser?.first_name_ar +
                ' ' +
                authState.currentUser?.last_name_ar}
            </span>
            <div
              className={`absolute left-0 top-0 z-50 hidden w-[256px] pt-[64px] group-hover:block `}
            >
              <ul
                className={`flex flex-col space-y-[8px] rounded-[6px] border-[2px] border-muted bg-white p-[8px] shadow-md`}
              >
                {/* <li>
                  <Link
                    className={`flex rounded-[4px] bg-muted px-[16px] py-[8px] hover:bg-muted-hover`}
                    to={`/logout`}
                  >
                    Profile
                  </Link>
                </li> */}
                <li>
                  <button
                    className={`flex w-full rounded-[4px] bg-danger px-[16px] py-[8px] text-white hover:bg-danger-hover`}
                    onClick={handleOnClickLogout}
                  >
                    تسجيل الخروج
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
