import React from 'react';

export default function CommitteeMembersTableIsEmpty() {
  return (
    <tbody>
      <tr>
        <td colSpan={4}>
          <h2 className="flex h-[256px] items-center justify-center rounded-[6px] border-[2px] border-muted bg-white text-[22px] font-bold">
            لا يوجد أعضاء في هذه اللجنة
          </h2>
        </td>
      </tr>
    </tbody>
  );
}
