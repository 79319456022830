import React from 'react';

export default function CommitteeMembersTableBodyLoading() {
  return (
    <tbody>
      {[...Array(8)].map(() => (
        <tr className={`overflow-hidden`}>
          <td
            className={`my-2 h-[56px] animate-pulse overflow-hidden rounded-[4px] bg-white`}
            colSpan={4}
          />
        </tr>
      ))}
    </tbody>
  );
}
