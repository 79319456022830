import React, { useEffect, useRef, useState } from 'react';
import { FiRefreshCw } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import axiosClient from '../../../../axios-client';
import { CgSpinner } from 'react-icons/cg';
import { AUTH_ACTIONS } from '../../../../contexts/authContext/authActions';
import {
  handleFetchError,
  showToastError,
  showToastSuccess,
} from '../../../../helper';
import { useAuth } from '../../../../contexts/authContext/AuthContext';

export default function CommitteeEditForm({ committee }) {
  const { authDispatch } = useAuth();
  const navigate = useNavigate();

  const imageRef = useRef(null);
  const nameRef = useRef(null);
  const symbolRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isInProgress, setIsInProgress] = useState(false);

  const handleOnChangeImageInput = () => {
    setSelectedImage(URL.createObjectURL(imageRef.current.files[0]));
  };

  const handleOnSubmitEditCommittee = (event) => {
    event.preventDefault();
    if (isInProgress) {
      return;
    }
    setIsInProgress(true);

    const payload = {
      name: nameRef.current?.value,
      symbol: symbolRef.current?.value,
      image: imageRef.current?.files[0],
    };


    axiosClient
      .post(`/dashboard/committees/${committee.id}`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({ data }) => {
        showToastSuccess(data.message);
        navigate('/dashboard/committees?page=1');
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        setIsInProgress(false);
      });
  };

  useEffect(() => {
    setSelectedImage(
      `${process.env.REACT_APP_BASE_URL}/storage/images/committees/${committee.image}`,
    );
  }, [committee]);

  return (
    <form
      onSubmit={handleOnSubmitEditCommittee}
      className={`grid grid-cols-12 gap-[16px]`}
    >
      <div className={` col-span-12 flex flex-col space-y-[4px]`}>
        <label htmlFor={`ID_COMMITTEE_IMAGE`} className={`font-bold`}>
          الصورة
          <span className={`text-danger`}>*</span>
        </label>
        <div className={`relative col-span-12`}>
          <label
            htmlFor={`ID_COMMITTEE_IMAGE`}
            className="absolute m-[8px] cursor-pointer rounded-[8px] bg-primary text-white hover:bg-primary-hover"
          >
            <div className="flex gap-[8px] px-[16px] py-[8px]">
              <FiRefreshCw size={20} />
              <span>تغيير</span>
            </div>
          </label>
          <img
            className="h-[256px] w-full rounded-[8px] border-[2px] border-muted object-cover"
            src={selectedImage}
            alt="committee_image"
          />
        </div>
        <input
          ref={imageRef}
          className={`hidden rounded-[6px] border-[2px] border-light px-[16px] py-[8px] outline-none`}
          type={`file`}
          name={`committee_image`}
          id={`ID_COMMITTEE_IMAGE`}
          accept="image/png, image/webp, image/jpeg, image/jpg"
          onChange={handleOnChangeImageInput}
        />
      </div>

      <div className={`col-span-12 flex flex-col space-y-[4px]`}>
        <label htmlFor={`ID_NAME`} className={`font-bold`}>
          الأسم
          <span className={`text-danger`}> *</span>
        </label>
        <input
          ref={nameRef}
          className={`rounded-[6px] border-[2px] border-light px-[16px] py-[8px] outline-none`}
          type={`text`}
          name={`name`}
          id={`ID_NAME`}
          defaultValue={committee.name}
        />
      </div>
      <div className={`col-span-12 flex flex-col space-y-[4px]`}>
        <label htmlFor={`ID_SYMBOL`} className={`font-bold`}>
          الرمز
          <span className={`text-danger`}> *</span>
        </label>
        <input
          ref={symbolRef}
          className={`rounded-[6px] border-[2px] border-light px-[16px] py-[8px] outline-none`}
          type={`text`}
          name={`symbol`}
          id={`ID_SYMBOL`}
          defaultValue={committee.symbol}
        />
      </div>

      <div className={`col-span-2 flex flex-col space-y-[4px]`}>
        <button
          type={`submit`}
          disabled={isInProgress}
          className={`${isInProgress ? 'cursor-wait' : 'hover:bg-primary-hover'} flex justify-center rounded-[6px] border bg-primary px-[16px] py-[8px] text-white`}
        >
          {isInProgress && <CgSpinner className="animate-spin" size={21} />}
          {!isInProgress && <>تعديل</>}
        </button>
      </div>
    </form>
  );
}
