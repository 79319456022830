import Select, { components } from 'react-select';

const userRolesOptions = [
  { value: 'MEMBER', label: 'عضو' },
  { value: 'COMMITTEE_SECRETARY', label: 'أمين اللجنة' },
  { value: 'COMMITTEE_HEAD', label: 'رئيس اللجنة' },
];

const formatOptionLabel = (option) => (
  <div className="flex items-center justify-start gap-[8px]">
    <span>{option.label}</span>
  </div>
);

const customComponents = {
  Control: (props) => (
    <components.Control
      {...props}
      className="!rounded-[6px] border border-muted bg-white px-[8px] py-[2px] !outline-none focus:border-muted"
    />
  ),
  Menu: (props) => (
    <components.Menu
      {...props}
      className="z-10 mt-[8px] !overflow-hidden !rounded-[6px] border border-muted px-[8px] !pt-[6px]"
    />
  ),
  Option: (props) => (
    <components.Option
      {...props}
      className={`mb-[8px] !rounded-[6px] !py-[8px] px-[8px] hover:bg-primary hover:text-white ${
        props.isFocused ? 'bg-gray-200 text-primary hover:text-dark' : ''
      }`}
    />
  ),
};

export default function SelectUserRoles({
  option,
  onUserRoleChange,
  defaultValue,
}) {
  const handleOnChangeUserRolesSelect = (option) => {
    onUserRoleChange(option);
  };
  return (
    <Select
      options={userRolesOptions}
      isSearchable={false}
      value={defaultValue}
      components={customComponents}
      formatOptionLabel={formatOptionLabel}
      onChange={handleOnChangeUserRolesSelect}
    />
  );
}
