import ProjectVersionsHistoryListItem from './ProjectVersionsHistoryListItem';

export default function ProjectVersionsHistoryList({ isLoading, versions }) {
  if (!versions || versions.length === 0) {
    return (
      <div className="rounded-[8px] bg-white p-[16px]">
        لا توجد الإصدارات السابقة للمشروع.
      </div>
    );
  }

  return versions.map((version) => (
    <ProjectVersionsHistoryListItem key={version.id} version={version} />
  ));
}
