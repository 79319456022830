import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../contexts/authContext/AuthContext';
import { Toaster } from 'react-hot-toast';
import TopNavbar from '../../components/dashboard/inc/TopNavbar';
import SideMenu from '../../components/dashboard/inc/SideMenu';

export default function AdminLayout() {
  const { authState } = useAuth();
  if (authState.currentUser.type !== 'ADMIN') {
    return <Navigate to={`/forums`} replace />;
  } else {
    return (
      <>
        <Toaster />
        <TopNavbar />
        <div className={`grid min-h-screen grid-cols-12`}>
          <SideMenu />
          <div className={`col-span-10`}>
            <Outlet />
          </div>
          <div className="h-[128px]"></div>
        </div>
      </>
    );
  }
}
