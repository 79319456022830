import React from 'react';
import CommitteeMembersTableIsEmpty from './CommitteeMembersTableIsEmpty';
import CommitteeMembersTableRow from './CommitteeMembersTableRow';

export default function CommitteeMembersTableBody({ members, onRemoveMember }) {
 
  if (!members) {
    return;
  }
  if (members.length === 0) {
    return <CommitteeMembersTableIsEmpty />;
  }
  return (
    <tbody>
      {members.map((member, index) => (
        <CommitteeMembersTableRow
          key={member.id}
          member={member}
          onRemoveMember={onRemoveMember}
          isEven={index % 2 === 0}
        />
      ))}
    </tbody>
  );
}
