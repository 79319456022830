import React, { useState } from 'react';
import { useCommittee } from '../../../contexts/forums/committeeContext/CommitteeContext';
import { useAuth } from '../../../contexts/authContext/AuthContext';
import {
  handleFetchError,
  showToastError,
  showToastSuccess,
} from '../../../helper';
import { AUTH_ACTIONS } from '../../../contexts/authContext/authActions';
import { useNavigate } from 'react-router-dom';
import axiosClient from '../../../axios-client';
import CommitteeTitle from '../../../components/formus/ui/CommitteeTitle';
import { CgSpinner } from 'react-icons/cg';
import { BiUpload } from 'react-icons/bi';

export default function DocumentAdd() {
  const { committeeState } = useCommittee();
  const { authDispatch } = useAuth();
  const [isInProgress, setIsInProgress] = useState(false);
  const [title, setTitle] = useState(false);
  const [file, setFile] = useState(false);

  const navigate = useNavigate();

  const handleOnSubmitAddDocumentForm = (event) => {
    event.preventDefault();
    if (isInProgress) {
      return;
    }
    setIsInProgress(true);
    const payload = {
      title,
      file,
    };
    axiosClient
      .post(
        `/committees/${committeeState.currentCommittee.id}/documents`,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .then(({ data }) => {
        showToastSuccess(data.message);

        navigate(`/forums/committees/${committeeState.currentCommittee.id}`);
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        setIsInProgress(false);
      });
  };

  return (
    <div className="w-full border-r border-muted p-[16px]">
      <CommitteeTitle />
      <form
        onSubmit={handleOnSubmitAddDocumentForm}
        className="mt-[16px] flex flex-col gap-[16px] rounded-[8px] bg-white p-[16px]"
      >
        <h1 className="text-[24px] font-bold">إضافة وثيقة اللجنة</h1>

        <div className="flex w-full flex-col space-y-[8px]">
          <label className="font-semibold">
            عنوان <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            placeholder="عنوانبالعربية"
            name="document_title"
            className="rounded-[6px] border-[2px] border-muted bg-white px-[16px] py-[8px] placeholder-muted outline-none"
            onChange={(event) => setTitle(event.target.value)}
          />
        </div>

        <label
          htmlFor="ID_DOCUMENT_FILE"
          className="flex w-full cursor-pointer flex-col space-y-[8px]"
        >
          <span className="font-semibold">
            ملف <span className="text-danger">*</span>
          </span>
          <div className="flex items-center justify-between rounded-[6px] border-[2px] border-muted bg-white px-[16px] py-[8px] text-secondary">
            <div>
              {file && file.name}
              {!file && `اختر ملف`}
            </div>
            <BiUpload size={22} />
          </div>
          <input
            type="file"
            name="document_file"
            id="ID_DOCUMENT_FILE"
            className="hidden"
            onChange={(event) => setFile(event.target.files[0])}
          />
        </label>

        <div className="flex justify-start">
          <button
            type="submit"
            disabled={isInProgress}
            className={`${isInProgress ? 'cursor-wait' : 'hover:bg-primary-hover'} flex justify-center rounded-[6px] border bg-primary px-[16px] py-[8px] text-white`}
          >
            {isInProgress && <CgSpinner className="animate-spin" size={21} />}
            {!isInProgress && <>اضاف</>}
          </button>
        </div>
      </form>
    </div>
  );
}
