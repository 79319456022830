import { Link, useLocation } from 'react-router-dom';

export default function SideMenu() {
  const location = useLocation();

  const menuItems = [
    { label: 'المستخدمين', basePath: '/dashboard/users' },
    { label: 'اللجان', basePath: '/dashboard/committees' },
    { label: 'وثائق اللجان العامة', basePath: '/dashboard/documents/global' },
  ];

  const isActive = (basePath) => {
    // Check if the current path starts with the basePath
    return location.pathname.startsWith(basePath);
  };

  return (
    <div className={`col-span-2 border-l border-muted`}>
      <nav className={`p-[16px] font-semibold`}>
        <ul className={`flex flex-col space-y-[8px]`}>
          {menuItems.map((item) => (
            <li key={item.basePath}>
              <Link
                to={`${item.basePath}?page=1`}
                className={`flex w-full rounded-[4px] px-[16px] py-[8px] ${
                  isActive(item.basePath)
                    ? 'bg-white text-primary'
                    : 'hover:bg-white hover:text-primary'
                }`}
              >
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
}
