import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/authContext/AuthContext';
import { useCommittee } from '../../../contexts/forums/committeeContext/CommitteeContext';
import { handleFetchError, showToastSuccess } from '../../../helper';

import CommitteeTitle from '../../../components/formus/ui/CommitteeTitle';
import axiosClient from '../../../axios-client';

import ProjectEditForm from '../../../components/formus/projects/project-edit-page/ProjectEditForm';

export default function ProjectEditPage() {
  const { project_id, committee_id } = useParams();
  const navigate = useNavigate();

  const { authDispatch } = useAuth();

  const [project, setProject] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isInProgress, setIsInProgress] = useState(false);

  const handleOnSubmitEditProjectForm = (payload) => {
    if (isInProgress) {
      return;
    }
    setIsInProgress(true);
    axiosClient
      .post(
        `/committees/${committee_id}/projects/${project_id}`,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .then(({ data }) => {
        showToastSuccess(data.message);
        navigate(`/forums/committees/${committee_id}/projects/${project_id}`);
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        setIsInProgress(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    axiosClient
      .get(`/committees/${committee_id}/projects/${project_id}`)
      .then(({ data }) => {
        setProject(data.project);
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        setIsLoading(false);
      });

    return () => {};
  }, [committee_id, project_id]);

  return (
    <div className="w-full border-r border-muted p-[16px]">
      <CommitteeTitle />
      <ProjectEditForm
        project={project}
        isLoading={isLoading}
        onSubmit={handleOnSubmitEditProjectForm}
      />
    </div>
  );
}
