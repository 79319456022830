import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import axiosClient from '../../../axios-client';
import { useNavigate } from 'react-router-dom';
import { AUTH_ACTIONS } from '../../../contexts/authContext/authActions';
import {
  generateRandomPassword,
  handleFetchError,
  showToastError,
  showToastSuccess,
} from '../../../helper';
import { useAuth } from '../../../contexts/authContext/AuthContext';
import { CgSpinner } from 'react-icons/cg';
import { genderOptions, typeOptions } from '../../../config/const';
import { GrPowerReset } from 'react-icons/gr';

export default function UserCreate() {
  const { authDispatch } = useAuth();

  const navigate = useNavigate();

  const [countriesOptions, setCountriesOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedGender, setSelectedGender] = useState('ذكر');
  const [isInProgress, setIsInProgress] = useState(false);

  const firstNameAr = useRef(null);
  const firstNameEn = useRef(null);
  const lastNameAr = useRef(null);
  const lastNameEn = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  async function fetchCountries() {
    axiosClient
      .get(`/countries`)
      .then(({ data }) => {
        const option = data.countries.map((country) => ({
          label: country.name_ar,
          value: country.id,
        }));
        setCountriesOptions(option);
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {});
  }

  useEffect(() => {
    fetchCountries();
    return () => {};
  }, []);

  function handleOnSubmit(event) {
    event.preventDefault();
    if (isInProgress) {
      return;
    }
    setIsInProgress(true);
    const payload = {
      country_id: selectedCountry,
      type: selectedType,
      gender: selectedGender,
      first_name_ar: firstNameAr.current.value,
      first_name_en: firstNameEn.current.value,
      last_name_ar: lastNameAr.current.value,
      last_name_en: lastNameEn.current.value,
      email: emailRef.current.value,
      password: passwordRef.current.value,
    };

    axiosClient
      .post(`/dashboard/users/create`, payload)
      .then(({ data }) => {
        showToastSuccess(data.message);
        navigate('/dashboard/users');
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        setIsInProgress(false);
      });
  }

  const handleOnClickGenerateNewPasswordButton = (event) => {
    passwordRef.current.value = generateRandomPassword();
  };

  return (
    <div className={`w-full p-[16px] text-[14px]`}>
      <h1 className="mb-[8px] text-[22px]">إنشاء مستخدم جديد</h1>
      <div className="rounded-[8px] bg-white p-[16px]">
        <form
          onSubmit={handleOnSubmit}
          className={`grid grid-cols-12 gap-[16px]`}
          method="POST"
        >
          <div className={`col-span-12 flex flex-col space-y-[4px]`}>
            <label htmlFor={`IdPassword`} className={`font-bold`}>
              دولة
              <span className={`text-danger`}>*</span>
            </label>
            <Select
              isSearchable={true}
              options={countriesOptions}
              onChange={(selectedCountry) =>
                setSelectedCountry(selectedCountry.value)
              }
            />
          </div>
          <div className={`col-span-6 flex flex-col space-y-[4px]`}>
            <label htmlFor={`IdFirstNameAr`} className={`font-bold`}>
              الاسم الشخصي بالعربية
              <span className={`text-danger`}>*</span>
            </label>
            <input
              ref={firstNameAr}
              className={`rounded-[6px] border-[2px] border-light px-[16px] py-[8px] outline-none`}
              type={`text`}
              name={`first_name_ar`}
              id={`IdFirstNameAr`}
            />
          </div>
          <div className={`col-span-6 flex flex-col space-y-[4px]`}>
            <label htmlFor={`IdLastNameAr`} className={`font-bold`}>
              الاسم العائلة بالعربية
              <span className={`text-danger`}>*</span>
            </label>
            <input
              ref={lastNameAr}
              className={`rounded-[6px] border-[2px] border-light px-[16px] py-[8px] outline-none`}
              type={`text`}
              name={`last_name_ar`}
              id={`IdLastNameAr`}
            />
          </div>
          <div className={`col-span-6 flex flex-col space-y-[4px]`}>
            <label htmlFor={`IdFirstNameEn`} className={`font-bold`}>
              الاسم الشخصي بالانجليزية
              <span className={`text-danger`}>*</span>
            </label>
            <input
              ref={firstNameEn}
              dir={`ltr`}
              className={`rounded-[6px] border-[2px] border-light px-[16px] py-[8px] outline-none`}
              type={`text`}
              name={`first_name_en`}
              id={`IdFirstNameEn`}
            />
          </div>
          <div className={`col-span-6 flex flex-col space-y-[4px]`}>
            <label htmlFor={`IdLastNameEn`} className={`font-bold`}>
              الاسم العائلة بالانجليزية
              <span className={`text-danger`}>*</span>
            </label>
            <input
              ref={lastNameEn}
              dir={`ltr`}
              className={`rounded-[6px] border-[2px] border-light px-[16px] py-[8px] outline-none`}
              type={`text`}
              name={`last_name_en`}
              id={`IdLastNameEn`}
            />
          </div>

          <div className={`col-span-12 flex flex-col space-y-[4px]`}>
            <label htmlFor={`IdPassword`} className={`font-bold`}>
              البريد الإلكتروني
              <span className={`text-danger`}>*</span>
            </label>
            <input
              ref={emailRef}
              dir={`ltr`}
              className={`rounded-[6px] border-[2px] border-light px-[16px] py-[8px] outline-none`}
              type={`email`}
              name={`email`}
              id={`IdEmail`}
            />
          </div>
          <div className={`col-span-12 flex flex-col space-y-[4px]`}>
            <label htmlFor={`IdPassword`} className={`font-bold`}>
              كلمة المرور
              <span className={`text-danger`}>*</span>
            </label>
            <div className="flex w-full gap-[4px]">
              <input
                ref={passwordRef}
                dir={`ltr`}
                className={`w-full rounded-[6px] border-[2px] border-light px-[16px] py-[8px] outline-none`}
                type={`text`}
                name={`password`}
                id={`IdPassword`}
              />
              <button
                onClick={handleOnClickGenerateNewPasswordButton}
                className="rounded-[6px] bg-success px-[12px] py-[8px] text-white hover:bg-success-hover"
                type="button"
              >
                <GrPowerReset size={20} />
              </button>
            </div>
          </div>

          <div className={`col-span-12 flex flex-col space-y-[4px]`}>
            <label htmlFor={`IdPassword`} className={`font-bold`}>
              الفئة
              <span className={`text-danger`}>*</span>
            </label>
            <Select
              options={typeOptions}
              isSearchable={false}
              onChange={(selectedType) => setSelectedType(selectedType.value)}
            />
          </div>
          <div className={`col-span-12 flex flex-col space-y-[4px]`}>
            <label htmlFor={`ID_GENDER`} className={`font-bold`}>
              نوع
              <span className={`text-danger`}>*</span>
            </label>
            <Select
              options={genderOptions}
              isSearchable={false}
              defaultValue={genderOptions[0]}
              onChange={(selectedGender) =>
                setSelectedGender(selectedGender.value)
              }
            />
          </div>
          <div className={`col-span-2 flex flex-col space-y-[4px]`}>
            <button
              className={`${isInProgress ? 'cursor-wait' : 'hover:bg-primary-hover'} flex justify-center rounded-[6px] border bg-primary px-[16px] py-[8px] text-white`}
              type={`submit`}
              disabled={isInProgress}
            >
              {isInProgress && <CgSpinner className="animate-spin" size={21} />}
              {!isInProgress && <>اضاف</>}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
