import toast from 'react-hot-toast';
import 'moment/locale/ar';
import moment from 'moment-timezone';
import { AUTH_ACTIONS } from './contexts/authContext/authActions';
import axiosClient from './axios-client';

const copyToClipboard = (text) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      showToastSuccess('تم نسخ الرابط!');
    })
    .catch((err) => {
      showToastError('فشل في نسخ الرابط.');
    });
};

const handleFetchError = (error, authDispatch, navigate) => {
  const { response } = error;
  if (
    response.status === 401 ||
    (response.status === 500 && response.data.error === 'Unauthenticated.')
  ) {
    showToastError('انتهت صلاحية الجلسة الخاصة بك');
    authDispatch({ type: AUTH_ACTIONS.LOGOUT });
    navigate('/login', { replace: true });
  } else {
    showToastError(response.data.error);
  }
};

const fetchData = async (
  url,
  successAction,
  dispatch,
  authDispatch,
  navigate,
  transformData = (data) => data,
) => {
  dispatch({ type: successAction.IS_LOADING, payload: { isLoading: true } });
  try {
    const { data } = await axiosClient.get(url);
    dispatch({
      type: successAction.SUCCESS,
      payload: transformData(data),
    });
  } catch (error) {
    handleFetchError(error, authDispatch, navigate);
  } finally {
    dispatch({ type: successAction.IS_LOADING, payload: { isLoading: false } });
  }
};

const showToastError = (message) => {
  toast.error(message, {
    position: 'top-left',
    duration: 5000, // Toast will stay for 5 seconds
    style: {
      borderRadius: '5px', // Slightly larger border radius for a smoother look
      backgroundColor: '#ff6b6b', // Light red background color for errors
      color: '#fff', // White text color for better contrast
      padding: '10px', // Additional padding for better readability
      fontSize: '14px', // Slightly larger font for better readability
    },
    iconTheme: {
      primary: '#ff6b6b', // Matching icon color with background
      secondary: '#fff', // White secondary color for the icon
    },
  });
};

const showToastSuccess = (message) => {
  toast.success(message, {
    position: 'top-left', // Positioning on the top-right, commonly used for success messages
    duration: 5000, // Toast will stay for 4 seconds
    style: {
      borderRadius: '5px', // Consistent border radius
      backgroundColor: '#4CAF50', // Green background color to represent success
      color: '#fff', // White text color for contrast
      padding: '10px', // Consistent padding
      fontSize: '14px', // Consistent font size
    },
    iconTheme: {
      primary: '#4CAF50', // Matching the success green
      secondary: '#fff', // White secondary color for the icon
    },
  });
};

const generateRandomPassword = () => {
  const length = 16; // Length of the password
  const charset =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+[]{}|;:,.<>?';
  let password = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    password += charset[randomIndex];
  }
  return password;
};

const getRoleName = (KEY) => {
  const roles = {
    MEMBER: 'عضو',
    COMMITTEE_SECRETARY: 'أمين اللجنة',
    COMMITTEE_HEAD: 'رئيس اللجنة',
  };

  return roles[KEY];
};
const getProjectType = (KEY) => {
  const types = {
    NEW_ADOPTION: 'جديد / تبني',
    NEW_PREPARATION: 'جديد / اعداد',
    REVIEW_ADOPTION: 'مراجعة / تبني',
    REVIEW_PREPARATION: 'مراجعة / اعداد',
  };

  return types[KEY];
};

// Function to calculate the time ago in Arabic with multi-unit output
const timeAgoInArabic = (dateString) => {
  const now = moment(); // Current time
  const date = moment(dateString); // Given date as a moment object
  const duration = moment.duration(now.diff(date)); // Calculate the duration

  if (duration.years() > 0) {
    const years = duration.years();
    return `قبل ${years}y`;
  } else if (duration.months() > 0) {
    const months = duration.months();
    return `قبل ${months}m`;
  } else if (duration.days() > 0) {
    const days = duration.days();
    return `قبل ${days}d`;
  } else if (duration.hours() > 0) {
    const hours = duration.hours();
    return `قبل ${hours}h `;
  } else if (duration.minutes() > 0) {
    const minutes = duration.minutes();
    return `قبل ${minutes}min`;
  } else {
    return 'قبل لحظات'; // Less than a minute
  }
};

const toDateTime = (utcDate, format = null) => {
  // Parse the UTC timestamp with the specified format, if provided
  const utcMoment = format ? moment.utc(utcDate, format) : moment.utc(utcDate);
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Convert UTC to user's local time
  const localMoment = utcMoment.clone().tz(userTimeZone);

  // Format the local date and time
  const localDate = localMoment.format('MMMM Do YYYY, h:mm a');

  // Replace Arabic numerals with Western numerals if present
  const arabicToWesternNumberMap = {
    '٠': '0',
    '١': '1',
    '٢': '2',
    '٣': '3',
    '٤': '4',
    '٥': '5',
    '٦': '6',
    '٧': '7',
    '٨': '8',
    '٩': '9',
  };

  return localDate.replace(
    /[٠-٩]/g,
    (match) => arabicToWesternNumberMap[match],
  );
};

export {
  showToastError,
  showToastSuccess,
  generateRandomPassword,
  getRoleName,
  getProjectType,
  timeAgoInArabic,
  toDateTime,
  handleFetchError,
  fetchData,
  copyToClipboard,
};
