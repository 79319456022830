import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axiosClient from '../../../../axios-client';
import SelectUsers from '../../../../components/dashboard/selects/SelectUsers';
import SelectUserRoles from '../../../../components/dashboard/selects/SelectUserRoles';
import { CgSpinner } from 'react-icons/cg';
import { handleFetchError, showToastSuccess } from '../../../../helper';
import { useAuth } from '../../../../contexts/authContext/AuthContext';

export default function CommitteeMemberAddPage() {
  const { committee_id } = useParams();

  const { authDispatch } = useAuth();
  const navigate = useNavigate();

  const [usersOptions, setUsersOptions] = useState([]);
  const [isUsersOptionsLoading, setIsUsersOptionsLoading] = useState(true);
  const [isInProgress, setIsInProgress] = useState(false);
  const [isCommitteeLoading, setIsCommitteeLoading] = useState(true);
  const [committee, setCommittee] = useState(null);

  const [selectedUserRoleOption, setSelectedUserRoleOption] = useState(null);
  const [selectedUserOption, setSelectedUserOption] = useState(null);

  const handleOnSubmitAddMemberForm = (event) => {
    event.preventDefault();

    if (isInProgress) {
      return;
    }

    const payload = {
      user_id: selectedUserOption?.value,
      role: selectedUserRoleOption?.value,
    };

    axiosClient
      .post(`/dashboard/committees/${committee_id}/members`, payload)
      .then(({ data }) => {
        showToastSuccess(data.message);
        navigate(`/dashboard/committees/${committee_id}/members`);
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        setIsInProgress(false);
      });
  };
  const fetchCommittee = () => {
    setIsCommitteeLoading(true);
    axiosClient
      .get(`/dashboard/committees/${committee_id}`)
      .then(({ data }) => {
        setCommittee(data.committee);
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        setIsCommitteeLoading(false);
      });
  };
  useEffect(() => {
    setIsUsersOptionsLoading(true);
    axiosClient
      .get(`/dashboard/committees/${committee_id}/members/available`)
      .then(({ data }) => {
        const options = data.members.map((member) => ({
          label: `${member.first_name_ar} ${member.last_name_ar}`,
          value: member.id,
          country: member.country,
        }));
        setUsersOptions(options);
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        setIsUsersOptionsLoading(false);
      });

    fetchCommittee();
  }, [committee_id]);

  const handleOnChangeUserRole = (selectedUserRoleOption) => {
    setSelectedUserRoleOption(selectedUserRoleOption);
  };

  const handleOnChangeUser = (selectedUserOption) => {
    setSelectedUserOption(selectedUserOption);
  };

  return (
    <div className={`w-full p-[16px] text-[14px]`}>
      <h1 className="mb-[8px] text-[22px] ">
        {!isCommitteeLoading && (
          <>
            <span className="text-muted-hover">
              <Link
                className="hover:text-secondary"
                to={`/dashboard/committees`}
              >
                اللجان
              </Link>{' '}
              /{' '}
              <Link
                className="hover:text-secondary"
                to={`/dashboard/committees/${committee?.id}/members`}
              >
                {committee?.name}
              </Link>{' '}
            </span>{' '}
            / إضافة عضو جديد
          </>
        )}
      </h1>
      <div className="rounded-[8px] bg-white p-[16px]">
        <form
          onSubmit={handleOnSubmitAddMemberForm}
          className={`grid grid-cols-12 gap-[16px]`}
        >
          <div className={`col-span-12 flex flex-col space-y-[4px]`}>
            <label className={`font-bold`}>
              المستخدم
              <span className={`text-danger`}>*</span>
            </label>
            <SelectUsers
              isLoading={isUsersOptionsLoading}
              option={usersOptions}
              onUserChange={handleOnChangeUser}
            />
          </div>
          <div className={`col-span-12 flex flex-col space-y-[4px]`}>
            <label className={`font-bold`}>
              الرتبة
              <span className={`text-danger`}>*</span>
            </label>
            <SelectUserRoles onUserRoleChange={handleOnChangeUserRole} />
          </div>
          <div className={`col-span-2 flex flex-col space-y-[4px]`}>
            <button
              type={`submit`}
              disabled={isInProgress}
              className={`${isInProgress ? 'cursor-wait' : 'hover:bg-primary-hover'} flex justify-center rounded-[6px] border bg-primary px-[16px] py-[8px] text-white`}
            >
              {isInProgress && <CgSpinner className="animate-spin" size={21} />}
              {!isInProgress && <>اضاف</>}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
