import { createContext, useContext, useEffect, useReducer } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../authContext/AuthContext';
import { committeeInitialState } from './committeeInitialState';
import { committeeReducer } from './committeeReducer';
import { COMMITTEE_ACTIONS } from './committeeActions';
import { fetchData, handleFetchError } from '../../../helper';
import axiosClient from '../../../axios-client';

const CommitteeContext = createContext({
  committeeState: committeeInitialState,
  committeeDispatch: () => {},
});

export const CommitteeProvider = ({ children }) => {
  const { committee_id } = useParams();
  const { authDispatch } = useAuth();

  const navigate = useNavigate();

  const [committeeState, committeeDispatch] = useReducer(
    committeeReducer,
    committeeInitialState,
  );

  useEffect(() => {
    fetchData(
      '/committees/mine',
      {
        IS_LOADING: COMMITTEE_ACTIONS.SET_COMMITTEES_IS_LOADING,
        SUCCESS: COMMITTEE_ACTIONS.SET_COMMITTEES,
      },
      committeeDispatch,
      authDispatch,
      navigate,
      (data) => ({ committees: data.committees }),
    );
  }, [authDispatch, navigate]);

  useEffect(() => {
    if (!committee_id) {
      committeeDispatch({
        type: COMMITTEE_ACTIONS.SET_CURRENT_COMMITTEE,
        payload: { committee: null },
      });

      return;
    }
    committeeDispatch({
      type: COMMITTEE_ACTIONS.SET_CURRENT_COMMITTEE_IS_LOADING,
      payload: {
        isLoading: true,
      },
    });
    axiosClient
      .get(`/committees/${committee_id}`)
      .then(({ data }) => {
        committeeDispatch({
          type: COMMITTEE_ACTIONS.SET_CURRENT_COMMITTEE,
          payload: { committee: data.committee },
        });
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        committeeDispatch({
          type: COMMITTEE_ACTIONS.SET_CURRENT_COMMITTEE_IS_LOADING,
          payload: { isLoading: false },
        });
      });
  }, [committee_id, authDispatch, navigate]);

  return (
    <CommitteeContext.Provider value={{ committeeState, committeeDispatch }}>
      {children}
    </CommitteeContext.Provider>
  );
};

export default CommitteeContext;

export const useCommittee = () => useContext(CommitteeContext);
