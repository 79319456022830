export const PROJECTS_ACTIONS = {
  SET_PROJECTS: 'SET_PROJECTS',
  SET_PROJECTS_IS_LOADING: 'SET_PROJECTS_IS_LOADING',
  SET_CURRENT_PROJECT: 'SET_CURRENT_PROJECT',
  SET_CURRENT_PROJECT_IS_LOADING: 'SET_CURRENT_PROJECT_IS_LOADING',
  SET_USER_HAS_COMMENT: 'SET_USER_HAS_COMMENT',
  PUSH_NEW_PROJECT: 'PUSH_NEW_PROJECT',
  UPDATE_PROJECT: 'UPDATE_PROJECT',
  SET_VERSIONS: 'SET_VERSIONS',
  SET_VERSIONS_IS_LOADING: 'SET_VERSIONS_IS_LOADING',
};
