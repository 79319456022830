import React, { useEffect, useState } from 'react';
import ProjectEditFormLoading from './ProjectEditFormLoading';
import { v4 as uuidv4 } from 'uuid';
import { BiUpload } from 'react-icons/bi';
import RadioButton from '../../ui/RadioButton';
import ReferenceInput from '../../ui/ReferenceInput';

export default function ProjectEditForm({ isLoading, project, onSubmit }) {
  const [formType, setFormType] = useState('NEW');
  const [projectType, setProjectType] = useState('PREPARATION');
  const [projectFile, setProjectFile] = useState(null);
  const [projectTitleAr, setProjectTitleAr] = useState('');
  const [projectTitleEn, setProjectTitleEn] = useState('');
  const [projectArabNumber, setProjectArabNumber] = useState('');
  const [references, setReferences] = useState([{ id: uuidv4(), value: '' }]);

  // Function to add a new reference
  const addReference = () => {
    setReferences([...references, { id: uuidv4(), value: '' }]);
  };

  // Function to handle changes in reference inputs
  const handleReferenceChange = (id, event) => {
    const updatedReferences = references.map((ref) =>
      ref.id === id ? { ...ref, value: event.target.value } : ref,
    );
    setReferences(updatedReferences);
  };

  // Function to remove a reference by its ID
  const removeReference = (id) => {
    setReferences(references.filter((ref) => ref.id !== id));
  };

  // Handle project type change
  const handleProjectTypeChange = (type) => {
    setProjectType(type);
  };

  // Handle form type change
  const handleFormTypeChange = (type) => {
    setFormType(type);
  };

  useEffect(() => {
    if (!project) return;
    const projectType = project.type.split('_');
    setFormType(projectType[0]);
    setProjectType(projectType[1]);

    setProjectTitleAr(project.title_ar);
    setProjectTitleEn(project.title_en);
    setProjectArabNumber(project.ar_number);

    const references = JSON.parse(project.references).map((reference) => ({
      id: uuidv4(),
      value: reference,
    }));

    setReferences(references);

    return () => {};
  }, [project]);

  const handleOnSubmitEditProjectForm = (event) => {
    event.preventDefault();

    const payload = {
      title_ar: projectTitleAr,
      title_en: projectTitleEn,
      file: projectFile,
      type: `${formType}_${projectType}`,
      ar_number: projectArabNumber,
      references: references.map((reference) => reference.value),
    };

    onSubmit(payload);
  };

  if (isLoading) {
    return <ProjectEditFormLoading />;
  }
  return (
    <form
      onSubmit={handleOnSubmitEditProjectForm}
      className="mt-[16px] flex flex-col gap-[16px] rounded-[8px] bg-white p-[16px]"
    >
      <h1 className="text-[24px] font-bold">تعديل مشروع مواصفة</h1>
      <div className="space-y-[8px]">
        <label className="font-semibold">
          نوع إضافة المشروع <span className="text-danger">*</span>
        </label>
        <div className="flex gap-[16px]">
          <RadioButton
            onClick={() => handleFormTypeChange('NEW')}
            label="جديد"
            isActive={formType === 'NEW'}
          />
          <RadioButton
            onClick={() => handleFormTypeChange('REVIEW')}
            label="مراجعة"
            isActive={formType === 'REVIEW'}
          />
        </div>
      </div>

      <div className="space-y-[8px]">
        <label className="font-semibold">
          نوع المشروع <span className="text-danger">*</span>
        </label>
        <div className="flex gap-[16px]">
          <RadioButton
            onClick={() => handleProjectTypeChange('PREPARATION')}
            label="اعداد"
            isActive={projectType === 'PREPARATION'}
          />
          <RadioButton
            onClick={() => handleProjectTypeChange('ADOPTION')}
            label="تبني"
            isActive={projectType === 'ADOPTION'}
          />
        </div>
      </div>

      <div className="flex gap-[16px]">
        <div className="flex w-full flex-col space-y-[8px]">
          <label className="font-semibold">
            عنوان بالعربية <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            placeholder="عنوان بالعربية"
            name="project_title_ar"
            className="rounded-[6px] border-[2px] border-muted bg-white px-[16px] py-[8px] placeholder-muted outline-none"
            defaultValue={projectTitleAr}
            onChange={(event) => setProjectTitleAr(event.target.value)}
          />
        </div>
        <div className="flex w-full flex-col space-y-[8px]">
          <label className="font-semibold">عنوان بالإنجليزية</label>
          <input
            type="text"
            placeholder="عنوان بالإنجليزية"
            name="project_title_en"
            className="rounded-[6px] border-[2px] border-muted bg-white px-[16px] py-[8px] placeholder-muted outline-none"
            defaultValue={projectTitleEn}
            onChange={(event) => setProjectTitleEn(event.target.value)}
          />
        </div>
      </div>

      <label
        htmlFor="ID_PROJECT_FILE"
        className="flex w-full cursor-pointer flex-col space-y-[8px]"
      >
        <span className="font-semibold">
          ملف <span className="text-danger">*</span>
        </span>
        <div className="flex items-center justify-between rounded-[6px] border-[2px] border-muted bg-white px-[16px] py-[8px] text-secondary">
          <div dir="ltr">
            {projectFile && projectFile.name}
            {!projectFile && project.file_name}
          </div>
          <BiUpload size={22} />
        </div>
        <input
          type="file"
          name="project_file"
          id="ID_PROJECT_FILE"
          className="hidden"
          accept=".doc,.docx"
          onChange={(event) => setProjectFile(event.target.files[0])}
        />
      </label>

      {formType === 'REVIEW' && (
        <div className="flex w-full flex-col space-y-[8px]">
          <label className="font-semibold">رقم المواصفة القياسية العربية</label>
          <input
            type="text"
            placeholder="رقم المواصفة القياسية العربية"
            name="project_arabic_number"
            defaultValue={projectArabNumber}
            className="rounded-[6px] border border-muted bg-white px-[16px] py-[8px] placeholder-muted outline-none"
            onChange={(event) => setProjectArabNumber(event.target.value)}
          />
        </div>
      )}

      <div className="flex w-full flex-col space-y-[8px]">
        <label className="font-semibold">
          مراجع <span className="text-danger">*</span>
        </label>
        {references.map((reference, index) => (
          <ReferenceInput
            key={reference.id}
            reference={reference}
            index={index}
            onChange={handleReferenceChange}
            onRemove={removeReference}
          />
        ))}
        <button
          onClick={addReference}
          type="button"
          className="w-full rounded-[6px] border bg-muted px-[16px] py-[8px] hover:bg-muted-hover"
        >
          إضافة مرجع
        </button>
      </div>

      <div className="flex justify-start">
        <button
          type="submit"
          className="rounded-[6px] bg-primary px-[32px] py-[8px] text-white hover:bg-primary-hover"
        >
          تعديل
        </button>
      </div>
    </form>
  );
}
