
import Select, { components } from 'react-select';

const formatOptionLabel = (option) => (
    <div className="flex justify-start items-center gap-[8px]">
        <img
            className="w-[40px] rounded-[4px] border-[2px] border-muted"
            src={`${process.env.REACT_APP_BASE_URL}/storage/flags/${option.country.image}`}
        />
        <span>{option.label}</span>
    </div>
);

const customComponents = {
    Control: (props) => (
        <components.Control
            {...props}
            className="!rounded-[6px] border border-muted bg-white px-[8px] py-[2px] !outline-none focus:border-muted"
        />
    ),
    Menu: (props) => (
        <components.Menu
            {...props}
            className="z-10 mt-[8px] !overflow-hidden !rounded-[6px] border border-muted px-[8px] !pt-[6px]"
        />
    ),
    Option: (props) => (
        <components.Option
            {...props}
            className={`mb-[8px] !rounded-[6px] !py-[8px] px-[8px] hover:bg-primary hover:text-white ${props.isFocused ? 'bg-gray-200 text-primary hover:text-dark' : ''
                }`}
        />
    ),
};

export default function SelectUsers({ option, onUserChange, isLoading }) {
    const handleOnChangeUserSelect = (option) => {
        onUserChange(option)
    }
    return (
        <Select
            options={option}
            isLoading={isLoading}
            isSearchable={true}
            components={customComponents}
            formatOptionLabel={formatOptionLabel}
            onChange={handleOnChangeUserSelect}

        />
    )
}
