import React from 'react';
import { CgSpinner } from 'react-icons/cg';

export default function UserEditFormLoading() {
  return (
    <div className={`grid grid-cols-12 gap-[16px]`}>
      <div className={`col-span-12 flex flex-col space-y-[4px]`}>
        <label htmlFor={`IdPassword`} className={`font-bold`}>
          دولة
          <span className={`text-danger`}>*</span>
        </label>
        <div className="h-[38px] w-full animate-pulse rounded-[6px] bg-muted" />
      </div>
      <div className={`col-span-6 flex flex-col space-y-[4px]`}>
        <label htmlFor={`IdFirstNameAr`} className={`font-bold`}>
          الاسم الشخصي بالعربية
          <span className={`text-danger`}>*</span>
        </label>
        <div className="h-[38px] w-full animate-pulse rounded-[6px] bg-muted" />
      </div>
      <div className={`col-span-6 flex flex-col space-y-[4px]`}>
        <label htmlFor={`IdLastNameAr`} className={`font-bold`}>
          الاسم العائلة بالعربية
          <span className={`text-danger`}>*</span>
        </label>
        <div className="h-[38px] w-full animate-pulse rounded-[6px] bg-muted" />
      </div>
      <div className={`col-span-6 flex flex-col space-y-[4px]`}>
        <label htmlFor={`IdFirstNameEn`} className={`font-bold`}>
          الاسم الشخصي بالانجليزية
          <span className={`text-danger`}>*</span>
        </label>
        <div className="h-[38px] w-full animate-pulse rounded-[6px] bg-muted" />
      </div>
      <div className={`col-span-6 flex flex-col space-y-[4px]`}>
        <label htmlFor={`IdLastNameEn`} className={`font-bold`}>
          الاسم العائلة بالانجليزية
          <span className={`text-danger`}>*</span>
        </label>
        <div className="h-[38px] w-full animate-pulse rounded-[6px] bg-muted" />
      </div>
      <div className={`col-span-12 flex flex-col space-y-[4px]`}>
        <label htmlFor={`IdPassword`} className={`font-bold`}>
          البريد الإلكتروني
          <span className={`text-danger`}>*</span>
        </label>
        <div className="h-[38px] w-full animate-pulse rounded-[6px] bg-muted" />
      </div>
      <div className={`col-span-12 flex flex-col space-y-[4px]`}>
        <label htmlFor={`IdPassword`} className={`font-bold`}>
          نوع
          <span className={`text-danger`}>*</span>
        </label>
        <div className="h-[38px] w-full animate-pulse rounded-[6px] bg-muted" />
      </div>
      <div className={`col-span-12 flex flex-col space-y-[4px]`}>
        <label htmlFor={`IdPassword`} className={`font-bold`}>
          الجنس
          <span className={`text-danger`}>*</span>
        </label>
        <div className="h-[38px] w-full animate-pulse rounded-[6px] bg-muted" />
      </div>
      <div className={`col-span-2 flex flex-col space-y-[4px]`}>
        <div
          className={` flex cursor-wait justify-center rounded-[6px] border bg-primary px-[16px] py-[8px] text-white`}
        >
          <CgSpinner className="animate-spin" size={21} />
        </div>
      </div>
    </div>
  );
}
