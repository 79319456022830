import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosClient from '../../../../axios-client';
import SelectUserRoles from '../../../../components/dashboard/selects/SelectUserRoles';
import { CgSpinner } from 'react-icons/cg';
import {
  getRoleName,
  handleFetchError,
  showToastSuccess,
} from '../../../../helper';
import { useAuth } from '../../../../contexts/authContext/AuthContext';

export default function CommitteeMemberEditPage() {
  const { committee_id, member_id } = useParams();

  const { authDispatch } = useAuth();
  const navigate = useNavigate();

  const [isInProgress, setIsInProgress] = useState(false);

  const [selectedUserRoleOption, setSelectedUserRoleOption] = useState(null);
  const [member, setMember] = useState(null);

  const handleOnSubmitEditMemberForm = (event) => {
    event.preventDefault();
    if (isInProgress) {
      return;
    }
    const payload = {
      member_id: member.id,
      role: selectedUserRoleOption?.value,
    };
    axiosClient
      .put(`/dashboard/committees/${committee_id}/members`, payload)
      .then(({ data }) => {
        showToastSuccess(data.message);
        navigate(`/dashboard/committees/${committee_id}/members`);
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        setIsInProgress(false);
      });
  };

  const getMember = () => {
    axiosClient
      .get(`/dashboard/committees/${committee_id}/members/${member_id}`)
      .then(({ data }) => {
        setMember(data.member);
        setSelectedUserRoleOption({
          value: data.member.role,
          label: getRoleName(data.member.role),
        });
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {});
  };
  useEffect(() => {
    getMember();
  }, []);

  const handleOnChangeUserRole = (selectedUserRoleOption) => {
    setSelectedUserRoleOption(selectedUserRoleOption);
  };

  return (
    <div className={`w-full p-[16px] text-[14px]`}>
      <h1 className="mb-[8px] text-[22px]">تعديل صلاحيات العضو</h1>
      <div className="rounded-[8px] bg-white p-[16px]">
        <form
          onSubmit={handleOnSubmitEditMemberForm}
          className={`grid grid-cols-12 gap-[16px]`}
          method="POST"
        >
          <div className={`col-span-12 flex flex-col space-y-[4px]`}>
            <label className={`font-bold`}>العضو</label>
            <div className="flex cursor-not-allowed gap-[4px] rounded-[6px] border-[2px] border-muted bg-light p-[8px]">
              <img
                alt={member?.country_name}
                title={member?.country_name}
                width={32}
                className="rounded-[4px] border-[2px] border-muted"
                src={`${process.env.REACT_APP_BASE_URL}/storage/flags/${member?.country_image}`}
              />
              <div>{member?.full_name}</div>
            </div>
          </div>
          <div className={`col-span-12 flex flex-col space-y-[4px]`}>
            <label className={`font-bold`}>
              الرتبة
              <span className={`text-danger`}>*</span>
            </label>
            <SelectUserRoles
              defaultValue={selectedUserRoleOption}
              onUserRoleChange={handleOnChangeUserRole}
            />
          </div>
          <div className={`col-span-2 flex flex-col space-y-[4px]`}>
            <button
              type={`submit`}
              disabled={isInProgress}
              className={`${isInProgress ? 'cursor-wait' : 'hover:bg-primary-hover'} flex justify-center rounded-[6px] border bg-primary px-[16px] py-[8px] text-white`}
            >
              {isInProgress && <CgSpinner className="animate-spin" size={21} />}
              {!isInProgress && <>تعديل</>}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
