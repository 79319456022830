import React, { useState } from 'react';
import { CgClose } from 'react-icons/cg';
import RadioButton from '../../../components/formus/ui/RadioButton';
import { useCommittee } from '../../../contexts/forums/committeeContext/CommitteeContext';
import CommitteeTitle from '../../../components/formus/ui/CommitteeTitle';
import axiosClient from '../../../axios-client';
import {
  handleFetchError,
  showToastError,
  showToastSuccess,
} from '../../../helper';
import { AUTH_ACTIONS } from '../../../contexts/authContext/authActions';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/authContext/AuthContext';
import ReferenceInput from '../../../components/formus/ui/ReferenceInput';
import { PROJECTS_ACTIONS } from '../../../contexts/forums/projectsContext/projectsActions';
import { useProjects } from '../../../contexts/forums/projectsContext/ProjectsContext';
import ProjectCreateForm from '../../../components/formus/projects/project-create-page/ProjectCreateForm';

// Component for the project creation form
export default function ProjectCreatePage() {
  const { committeeState } = useCommittee();
  const { projectsDispatch } = useProjects();
  const { authDispatch } = useAuth();
  const [isInProgress, setIsInProgress] = useState(false);

  const navigate = useNavigate();

  const handleOnSubmitCreateForm = (payload) => {
    if (isInProgress) {
      return;
    }
    setIsInProgress(true);
    axiosClient
      .post(
        `/committees/${committeeState.currentCommittee.id}/projects`,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .then(({ data }) => {
        showToastSuccess(data.message);
        projectsDispatch({
          type: PROJECTS_ACTIONS.PUSH_NEW_PROJECT,
          payload: {
            project: data.project,
          },
        });
        navigate(`/forums/committees/${committeeState.currentCommittee.id}`);
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        setIsInProgress(false);
      });
  };
  return (
    <div className="w-full border-r border-muted p-[16px]">
      <CommitteeTitle />
      <ProjectCreateForm
        onSubmit={handleOnSubmitCreateForm}
        isInProgress={isInProgress}
      />
    </div>
  );
}
