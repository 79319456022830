import React from 'react';
import { Link } from 'react-router-dom';

export default function ErrorPage() {
  return (
    <div className="flex h-screen items-center justify-center bg-gray-100">
      <div className="rounded-lg bg-white p-6 text-center shadow-md">
        <h1 className="mb-4 text-2xl font-bold">حدثت مشكلة!</h1>
        <p className="mb-6">
          نأسف، لم نتمكن من العثور على الصفحة التي تبحث عنها.
        </p>
        <Link to="/login">
          <button className="rounded-lg bg-blue-500 px-4 py-2 font-semibold text-white hover:bg-blue-600">
            تسجيل الدخول
          </button>
        </Link>
      </div>
    </div>
  );
}
