import React from 'react';

export default function CommitteeMembersTableHeader() {
  return (
    <thead>
      <tr className={`my-2 bg-dark text-white`}>
        <th className={` rounded-r-[4px] p-[16px]`}>الاسم الكامل</th>
        <th className={`p-[16px] text-right`}>الرتبة</th>
        <th className={`w-[228px] p-[16px] text-right`}>الانضمام في</th>
        <th className={`w-[126px] rounded-l-[4px] p-[16px] text-center`}>
          actions
        </th>
      </tr>
    </thead>
  );
}
