import { Editor } from '@tinymce/tinymce-react';
import React, { useState } from 'react';
import { CgSpinner } from 'react-icons/cg';

export default function ProjectCommentEdit({
  onSend,
  onCancel,
  isInProgress,
  comment,
}) {
  const [body, setBody] = useState(comment.body);

  const [attachment, setAttachment] = useState(null);

  const handleEditorChange = (content, editor) => {
    setBody(content);
  };

  const handleOnClickEditCommnet = () => {
    onSend({ body, attachment });
  };

  const handleOnClickCancelEditComment = () => {
    onCancel();
  };

  return (
    <>
      <Editor
        apiKey={'6w0lf9jhoitegtoixng3j9pwefdlcm8j4vqy2zepjo98h624'}
        initialValue={comment.body}
        init={{
          selector: 'textarea', // change this selector as per your needs
          plugins: 'directionality lists link',
          directionality: 'rtl',
          menubar: false,
          height: 200,
          toolbar:
            'undo redo | rtl ltr | formatselect | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist',
          content_style: `
          body {
            border: none !important;
            border-radius: 0 !important;
          }
        `,
          setup: (editor) => {
            editor.on('init', () => {
              editor.getContainer().style.border = 'none';
              editor.getContainer().style.borderRadius = '0';
            });
          },
        }}
        onEditorChange={handleEditorChange}
      />
      <div className="flex justify-between p-[8px]">
        <div className="flex gap-[8px]">
          <button
            className=" flex items-center gap-[12px] rounded-[8px] bg-danger/50 px-[16px] py-[8px] text-white hover:bg-danger-hover"
            onClick={handleOnClickCancelEditComment}
            disabled={isInProgress}
          >
            {isInProgress && <CgSpinner className="animate-spin" size={21} />}
            {!isInProgress && <> إلغاء التعديل</>}
          </button>
          <button
            onClick={handleOnClickEditCommnet}
            className=" flex items-center gap-[12px] rounded-[8px] bg-primary px-[16px] py-[8px] text-white hover:bg-primary-hover"
            disabled={isInProgress}
          >
            {isInProgress && <CgSpinner className="animate-spin" size={21} />}
            {!isInProgress && <>تعديل</>}
          </button>
        </div>
        <label
          htmlFor="ID_PROJECT_FILE_ATTACHMENT"
          className="flex cursor-pointer flex-col space-y-[8px]"
        >
          <div
            className={`flex items-center justify-between rounded-[6px] border-[2px]  ${attachment ? 'border-success-hover bg-success text-white' : 'border-muted bg-white text-secondary hover:bg-muted'}   px-[16px] py-[8px] `}
          >
            <div>
              {!attachment && 'تعديل الملحق'}
              {attachment && 'تم رفع ملحق المعدل'}
            </div>
          </div>
          <input
            type="file"
            name="attachment_file"
            id="ID_PROJECT_FILE_ATTACHMENT"
            className="hidden"
            onChange={(event) => setAttachment(event.target.files[0])}
          />
        </label>
      </div>
    </>
  );
}
