import { useRef, useState } from 'react';
import axiosClient from '../axios-client';
import { VscEye, VscEyeClosed } from 'react-icons/vsc';

import toast, { Toaster } from 'react-hot-toast';
import { useAuth } from '../contexts/authContext/AuthContext';
import { Navigate, useNavigate } from 'react-router-dom';
import { AUTH_ACTIONS } from '../contexts/authContext/authActions';
import { handleFetchError } from '../helper';

export default function Login() {
  const { authState, authDispatch } = useAuth();

  const navigate = useNavigate();

  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isShow, setIsShow] = useState(false);

  function handleShowPassword() {
    setIsShow(!isShow);
  }

  function handleSubmitLoginForm(event) {
    event.preventDefault();

    const payload = {
      email: emailRef.current.value,
      password: passwordRef.current.value,
    };

    setIsLoading(true);

    axiosClient
      .post(`/session/login`, payload)
      .then(({ data }) => {
        authDispatch({
          type: AUTH_ACTIONS.LOGIN,
          payload: {
            user: data.user,
            token: data.token,
          },
        });
        switch (data.user.type) {
          case 'ADMIN':
            navigate('/dashboard/users');
            break;
          case 'FORUMS_USER':
            navigate('/forums');
            break;
          case 'DATABASES_USER':
            navigate('/databases/projects?page=1');
            break;
          default:
            authDispatch({
              type: AUTH_ACTIONS.LOGOUT,
            });
            navigate('/login');
            break;
        }
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        setIsLoading(false);
        passwordRef.current.value = ``;
      });
  }

  if (authState.token) {
    return <Navigate to={`/`} replace />;
  } else {
    return (
      <div className={`flex h-screen w-full items-center justify-center`}>
        <Toaster />
        <div className={`min-w-[512px] rounded-[8px] bg-white p-[32px] shadow`}>
          <div
            className={`mt-[8px] flex flex-col items-center space-y-[8px] text-center lg:mt-0 lg:w-full`}
          >
            <img
              src={`/assets/images/aidsmo_logo.svg`}
              alt={`logo`}
              width={128}
            />
            <div>
              <h1 className={`text-[24px] font-bold`}>
                مركز المواصفات والمقاييس
              </h1>
              <p className={`text-[20px] font-light`}>
                المنظمة العربية للتنمية الصناعية والتقييس والتعدين
              </p>
            </div>
          </div>
          <form
            onSubmit={handleSubmitLoginForm}
            className={`mt-[32px] ${isLoading ? 'hidden' : 'flex'} flex-col`}
          >
            <div className={`flex flex-col space-y-[8px]`}>
              <div className={`flex flex-col space-y-[4px]`}>
                <label htmlFor={`ID_EMAIL`}>البريد الإلكتروني</label>
                <input
                  className={`rounded-[8px] border border-muted bg-light px-[16px] py-[12px] outline-none`}
                  ref={emailRef}
                  type={`email`}
                  name={`email`}
                  id={`ID_EMAIL`}
                  dir={`ltr`}
                />
              </div>
              <div className={`flex flex-col space-y-[4px]`}>
                <label htmlFor={`ID_PASSWORD`}>كلمة مرور</label>
                <div className={`flex space-x-[8px] space-x-reverse`}>
                  <input
                    className={`w-full rounded-[8px] border border-muted bg-light px-[16px] py-[12px] outline-none`}
                    ref={passwordRef}
                    type={isShow ? `text` : `password`}
                    name={`password`}
                    id={`ID_PASSWORD`}
                    dir={`ltr`}
                  />
                  <button
                    className={`rounded-[8px] bg-success p-[2px] px-[12px] text-white`}
                    type={`button`}
                    onClick={handleShowPassword}
                  >
                    {isShow ? (
                      <VscEyeClosed strokeWidth={0.5} size={28} />
                    ) : (
                      <VscEye strokeWidth={0.5} size={28} />
                    )}
                  </button>
                </div>
              </div>
            </div>

            <button
              className={`mt-[32px] rounded-[8px] bg-primary px-[16px] py-[16px] text-white`}
              type={`submit`}
            >
              تسجيل الدخول
            </button>
          </form>
          <div
            className={`mt-[32px] text-center ${isLoading ? 'block' : 'hidden'}`}
          >
            <p className={`animate-pulse font-extrabold text-success`}>
              يرجى الانتظار قليلاً لإكمال عملية تسجيل الدخول
            </p>
          </div>
        </div>
      </div>
    );
  }
}
