import React, { useState } from 'react';
import axiosClient from '../../../../axios-client';
import { FaFileDownload } from 'react-icons/fa';
import { handleFetchError } from '../../../../helper';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../contexts/authContext/AuthContext';

export default function ProjectPageDownloadButton({
  isLoading,
  link,
  fileName,
}) {
  const { authDispatch } = useAuth();
  const navigate = useNavigate();
  const [isDownloading, setIsDownloading] = useState(false);

  const handleOnClickDownload = () => {
    setIsDownloading(true);
    axiosClient
      .get(link, {
        responseType: 'blob',
      })
      .then((response) => {
        const filename = fileName;
        let blob = new Blob([response.data], {
          type: 'application/octet-stream',
        });

        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          // IE workaround for "HTML7007: One or more blob URLs were
          // revoked by closing the blob for which they were created.
          // These URLs will no longer resolve as the data backing
          // the URL has been freed."
          window.navigator.msSaveBlob(blob, filename);
        } else {
          let blobURL = window.URL.createObjectURL(blob);
          let tempLink = document.createElement('a');
          tempLink.style.display = 'none';
          tempLink.href = blobURL;
          tempLink.download = filename;
          tempLink.click();
          window.URL.revokeObjectURL(blobURL);
        }
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };
  if (isLoading) {
    return <div className="h-[48px] w-[150px] rounded-[6px] bg-white" />;
  }
  return (
    <button
      onClick={handleOnClickDownload}
      className="flex items-center justify-between gap-[16px] rounded-[8px] border-[2px] border-success-hover bg-success py-[8px] pl-[16px] pr-[12px] text-white hover:bg-success-hover disabled:cursor-wait disabled:bg-muted disabled:text-secondary"
      disabled={isDownloading}
    >
      <FaFileDownload size={20} />
      <div>تحميل مشروع</div>
    </button>
  );
}
