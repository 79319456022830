import React, { useState } from 'react';
import { SlOptions } from 'react-icons/sl';
import { Link } from 'react-router-dom';
import DialogConfirmation from '../../dialogs/DialogConfirmation';
import { useAuth } from '../../../../contexts/authContext/AuthContext';
import { FaUserTie, FaUser, FaUserShield, FaCopy } from "react-icons/fa";
import { MdEdit, MdRefresh } from "react-icons/md";
import { FaRegTrashCan } from "react-icons/fa6";
import { copyToClipboard } from '../../../../helper';


export default function UsersTableRow({ user, isEven, onDeleteUser }) {
  const { authState } = useAuth();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const handleOnCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleOnOpenDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };
  const handleOnConfirmDeleteDialog = () => {
    onDeleteUser(user.id);
    setIsDeleteDialogOpen(false);
  };

  return (
    <tr className={`${isEven ? 'bg-white' : 'bg-odd'} my-2 overflow-hidden`}>
      <DialogConfirmation
        onClose={handleOnCloseDeleteDialog}
        onConfirm={handleOnConfirmDeleteDialog}
        isOpen={isDeleteDialogOpen}
      >
        <p>
          هل أنت متأكد أنك تريد حذف هذا المستخدم؟{' '}
          <b>
            {user.first_name_ar} {user.last_name_ar}
          </b>
        </p>
      </DialogConfirmation>
      <td className={`rounded-r-[6px] p-[16px]`}>
        <div className={`flex items-end space-x-[8px] space-x-reverse`}>
          <div className={`broder-light rounded-[4px] w-[40px]  border-[2px]`}>
            <img
              className='rounded-[2px]'
              alt={user.country.name_ar}
              title={user.country.name_ar}
              src={`${process.env.REACT_APP_BASE_URL}/storage/flags/${user.country.image}`}
            />
          </div>
          <Link
            to={`/dashboard/users/${user.id}`}
            className={`font-bold text-primary underline hover:text-primary-hover`}
          >
            {user.first_name_ar} {user.last_name_ar}
          </Link>
        </div>
      </td>
      <td className={`p-[16px]`}>
        <button onClick={() => copyToClipboard(user.email)} className='flex justify-between items-center w-full hover:bg-muted p-[8px] rounded-[6px]'>
          <FaCopy size={18} />
          <div>{user.email}</div>
        </button>
      </td>
      <td className={`p-[16px] pt-5`}>
        <div>
          {
            user.type === 'ADMIN' && <FaUserShield title='مسؤل' size={22} />
          }
          {
            user.type === 'FORUMS_USER' && <FaUser title='مستخدم المنتديات' size={22} />
          }
          {
            user.type === 'DATABASES_USER' && <FaUserTie title='مستخدم القاعدة التفاعلية' size={22} />
          }

        </div>
      </td>

      <td className={`w-[64px] rounded-l-[6px] p-[16px]`}>
        <div className={`group relative flex gap-[4px]`}>
          <button className="rounded-[6px] bg-muted p-[8px]">
            <SlOptions size={16} />
          </button>
          <div className="absolute left-[12px] top-0 z-50 hidden group-hover:block">
            <div className="z-50 mt-[40px] min-w-[200px] rounded-[8px] border-[2px] border-muted bg-white p-[8px]">
              <ul className="flex flex-col space-y-[4px]">
                <li>
                  <Link
                    to={`/dashboard/users/${user.id}/edit`}
                    className={`flex rounded-[6px] px-[16px] py-[8px] text-[13px] gap-[8px] hover:bg-muted`}
                  >
                    <MdEdit size={20} />
                    <span>
                      تعديل
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    to={`/dashboard/users/${user.id}/change/password`}
                    className={`flex rounded-[6px] px-[16px] py-[8px] text-[13px] gap-[8px] hover:bg-muted`}
                  >
                    <MdRefresh size={20} />
                    <span>
                      تغيير كلمة مرور
                    </span>
                  </Link>
                </li>
                {user.id != authState.currentUser.id && (
                  <li>
                    <button
                      onClick={handleOnOpenDeleteDialog}
                      className={`flex w-full rounded-[6px] px-[16px] py-[8px] text-[13px] gap-[8px] bg-danger/10 text-danger hover:bg-muted`}
                    >
                      <FaRegTrashCan size={16} />
                      <span>حذف</span>
                    </button>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
}
