import moment from 'moment';
import React from 'react';
import { MdEdit } from 'react-icons/md';
import { Link } from 'react-router-dom';
import {
  getProjectType,
  timeAgoInArabic,
  toDateTime,
} from '../../../../helper';
import { useAuth } from '../../../../contexts/authContext/AuthContext';

export default function ProjectCard({ project, isLoading }) {
  const { authState } = useAuth();

  if (isLoading) {
    return (
      <div className="col-span-1 mt-[16px] h-[149px] w-full animate-pulse rounded-[6px] bg-white" />
    );
  }

  if (project) {
    return (
      <div
        className={`relative col-span-1 mt-[8px] flex flex-col gap-[8px] overflow-hidden rounded-[8px] border-2 border-white bg-white p-[8px]`}
      >
        <div className="absolute left-[8px] top-[8px]">
          <div className="flex gap-[8px]">
            {project.user.country_id === authState.currentUser.country_id && (
              <Link
                to={`/forums/committees/${project.committee_id}/projects/${project.id}/edit`}
                className="rounded-[4px] bg-primary p-[4px] text-white"
              >
                <MdEdit size={20} />
              </Link>
            )}
          </div>
        </div>
        <div>
          <div className="flex items-center gap-[4px]">
            <img
              className="w-[40px] overflow-hidden rounded-[5px] border-2 border-muted object-contain"
              src={`${process.env.REACT_APP_BASE_URL}/storage/flags/${project.user.country.image}`}
              alt={project.user.country.name_ar}
            />
            {project.created_at !== project.updated_at ? (
              <div
                title={toDateTime(project.updated_at)}
                className=" text-secondary"
              >
                تم التعديل {timeAgoInArabic(project.updated_at)}
              </div>
            ) : (
              <div
                title={toDateTime(project.created_at)}
                className=" text-secondary"
              >
                {timeAgoInArabic(project.created_at)}
              </div>
            )}
          </div>
          <Link className="text-[15px] font-bold hover:text-primary">
            {project.user.first_name_ar} {project.user.last_name_ar}
          </Link>
        </div>
        <div>
          <div className="flex gap-[8px] text-[24px] font-bold">
            <h2 className="select-all lg:line-clamp-1">{project.title_ar}</h2>
          </div>
        </div>
        <div className="flex flex-col justify-between gap-[8px] md:flex-row">
          <div className="flex flex-col items-center justify-between gap-[4px] text-[14px] sm:flex-row sm:text-[12px]">
            <div className="w-full cursor-default select-none whitespace-nowrap rounded-[5px] border-2 border-dark bg-dark/60 px-[16px] py-[2px] text-white sm:w-auto">
              {getProjectType(project.type)}
            </div>
            <div className="flex w-full cursor-default select-none rounded-[5px] border-2 border-primary bg-primary/60 text-white sm:w-auto">
              <div className=" bg-primary px-[8px] py-[2px]">
                {project.stage} : {project.version}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
