const genderOptions = [
  { value: 'ذكر', label: 'ذكر' },
  { value: 'أنثى', label: ' أنثى' },
];

const typeOptions = [
  { value: 'ADMIN', label: 'مسؤل' },
  { value: 'FORUMS_USER', label: 'مستخدم المنتديات' },
];

export { genderOptions, typeOptions };
