import React from 'react';
import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from 'react-icons/fi';
import { Link } from 'react-router-dom';

export default function Pagination({ pagination }) {
  if (pagination) {
    return (
      <div className="mt-[16px] flex items-center gap-2" dir="ltr">
        {/* First Page Icon */}
        {pagination.links.length > 1 &&
          pagination.current_page != 1 &&
          pagination.first_page_url && (
            <Link
              className="rounded-[6px] border-[2px] border-white bg-white px-[16px] py-[8px]"
              to={`?${pagination.first_page_url.split('?')[1]}`}
            >
              <FiChevronsLeft />
            </Link>
          )}

        {/* Pagination Links */}
        {pagination.links.map((link, index) => {
          if (!link.url) return null;

          const page = link.url.split('?')[1].split('=')[1];
          const isActive = pagination.current_page.toString() === page;

          const linkClassName = `py-[8px] px-[16px] rounded-[6px] bg-white ${!isActive ? 'border-[2px] border-white ' : 'border-[2px] border-muted-hover text-primary'}`;

          if (link.label === 'PREVIOUS') {
            return (
              <Link
                key={index}
                to={`?${link.url.split('?')[1]}`}
                className="rounded-[6px] border-[2px] border-white bg-white px-[16px] py-[8px]"
              >
                <FiChevronLeft />
              </Link>
            );
          }

          if (link.label === 'NEXT') {
            return (
              <Link
                key={index}
                to={`?${link.url.split('?')[1]}`}
                className="rounded-[6px] border-[2px] border-white bg-white px-[16px] py-[8px]"
              >
                <FiChevronRight />
              </Link>
            );
          }

          return (
            <Link
              key={index}
              to={`?${link.url.split('?')[1]}`}
              className={linkClassName}
            >
              {link.label}
            </Link>
          );
        })}
        {/* Last Page Icon */}
        {pagination.links.length > 1 &&
          pagination.links.length !== pagination.current_page + 1 &&
          pagination.last_page_url && (
            <Link
              className="rounded-[6px] border-[2px] border-white bg-white px-[16px] py-[8px]"
              to={`?${pagination.last_page_url.split('?')[1]}`}
            >
              <FiChevronsRight />
            </Link>
          )}
      </div>
    );
  }
}
