import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axiosClient from '../../../axios-client';
import { useAuth } from '../../../contexts/authContext/AuthContext';
import { handleFetchError, toDateTime } from '../../../helper';
import moment from 'moment';
import { MdRefresh } from 'react-icons/md';

export default function UserProfile() {
  const { user_id } = useParams();
  const { authDispatch } = useAuth();
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getUserProfile = () => {
    setIsLoading(true);
    axiosClient
      .get('/users/profile')
      .then(({ data }) => {
        setUser(data.user);
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getUserProfile();
    return () => {};
  }, []);
  if (!isLoading)
    return (
      <div className="w-full border-r border-muted p-[16px]">
        <h1 className="mb-[8px] text-[22px]">الملف الشخصي</h1>
        <div className="rounded-[8px] bg-white p-[16px]">
          <div className="space-y-[16px]">
            <div className="space-y-[4px]">
              <div className="font-bold">الاسم الكامل</div>
              <div className="rounded-[6px] border-[2px] border-muted px-[16px] py-[8px]">
                {user.first_name_ar} {user.last_name_ar}
              </div>
            </div>
            <div className="space-y-[4px]">
              <div className="font-bold">دولة</div>
              <div className="flex gap-[4px] rounded-[6px] border-[2px] border-muted px-[16px] py-[8px]">
                <img
                  alt={user.country.name_ar}
                  title={user.country.name_ar}
                  width={32}
                  className="rounded-[4px] border-[2px] border-muted"
                  src={`${process.env.REACT_APP_BASE_URL}/storage/flags/${user.country.image}`}
                />
                <div>{user.country.name_ar}</div>
              </div>
            </div>

            <div className="space-y-[4px]">
              <div className="font-bold">نوع</div>
              <div className="flex gap-[4px] rounded-[6px] border-[2px] border-muted px-[16px] py-[8px]">
                {user.gender}
              </div>
            </div>
            <div className="space-y-[4px]">
              <div className="font-bold">بريد إلكتروني</div>
              <div className="flex gap-[4px] rounded-[6px] border-[2px] border-muted px-[16px] py-[8px]">
                {user.email}
              </div>
            </div>
            <div className="flex-start flex">
              <Link
                to={`/forums/members/me/change/password`}
                className={`flex items-center gap-[8px] rounded-[6px] border-[2px] border-primary px-[16px] py-[8px] text-primary hover:bg-primary hover:text-white`}
              >
                <MdRefresh size={20} />
                <span>تغيير كلمة مرور</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
}
