import React, { useEffect, useRef, useState } from 'react';
import { FaFileUpload } from 'react-icons/fa';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import SelectProjectType from '../../../components/formus/ui/SelectProjectType';
import SelectCountries from '../../../components/formus/ui/SelectCountries';
import axiosClient from '../../../axios-client';
import ProjectsList from '../../../components/formus/projects/projects-page/ProjectsList';
import Pagination from '../../../components/formus/ui/Pagination';
import { MdClose } from 'react-icons/md';
import { TbFilter } from 'react-icons/tb';
import { handleFetchError } from '../../../helper';
import { useAuth } from '../../../contexts/authContext/AuthContext';

export default function ProjectsCountry() {
  const { authDispatch } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();

  const projectTitleRef = useRef(null);
  const memberNameRef = useRef(null);
  const projectReferenceRef = useRef(null);
  const filterRef = useRef(null);

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [projects, setProjects] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const queryStringParams = {};

    if (
      projectTitleRef.current &&
      projectTitleRef.current.value.trim() !== ''
    ) {
      queryStringParams['project_title'] = projectTitleRef.current.value;
    }
    if (selectedCountry) {
      queryStringParams['country_id'] = selectedCountry.value;
    }
    if (selectedType) {
      queryStringParams['type'] = selectedType.value;
    }

    if (memberNameRef.current && memberNameRef.current.value.trim() !== '') {
      queryStringParams['member_name'] = memberNameRef.current.value;
    }

    if (
      projectReferenceRef.current &&
      projectReferenceRef.current.value.trim() !== ''
    ) {
      queryStringParams['project_reference'] =
        projectReferenceRef.current.value;
    }

    const queryString = new URLSearchParams(queryStringParams).toString();
    navigate(`?${queryString}`);
  };
  const handleOnReset = (e) => {
    e.preventDefault();
    setSelectedCountry(null);
    setSelectedType(null);
    navigate(`?`);
    handleOnCloseFilter();
  };

  const handleOnOpenFilter = () => {
    setIsFilterOpen(true);
    filterRef.current.classList.remove(`-translate-x-full`);
    filterRef.current.classList.add(`translate-x-0`);
  };
  const handleOnCloseFilter = () => {
    setIsFilterOpen(false);
    filterRef.current.classList.remove(`translate-x-0`);
    filterRef.current.classList.add(`-translate-x-full`);
  };

  useEffect(() => {
    const queryString = location.search;
    setIsLoading(true);
    handleOnCloseFilter();
    axiosClient
      .get(`/committees/projects/our${queryString}`)
      .then(({ data }) => {
        setProjects(data.projects);
        setPagination({
          last_page_url: data.last_page_url,
          links: data.links,
          first_page_url: data.first_page_url,
          current_page: data.current_page,
        });
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        setIsLoading(false);
      });
    return () => {};
  }, [location.search]);

  return (
    <div className="flex w-full border-r border-muted p-[16px]">
      {isFilterOpen && (
        <div
          onClick={handleOnCloseFilter}
          className="fixed bottom-0 left-0 right-0 top-0 z-10 h-full w-full bg-dark/20 backdrop-blur"
        ></div>
      )}
      <div
        ref={filterRef}
        className="fixed bottom-0 left-0 top-[70px] z-10 w-full -translate-x-full bg-light p-[16px] transition md:w-[500px]"
      >
        <div className={`flex flex-col gap-[16px]`}>
          <div className="flex  items-center justify-between">
            <h3 className="text-[20px] font-semibold">فلتر</h3>
            <button
              onClick={handleOnCloseFilter}
              className={`rounded-[6px] border-[2px] border-muted-hover bg-white p-[8px] transition duration-150 ease-in-out hover:bg-muted-hover`}
            >
              <MdClose size={22} />
            </button>
          </div>
          {/* <div className="h-px w-full bg-muted" /> */}
          <form
            className="flex flex-col gap-[8px]"
            onSubmit={handleOnSubmit}
            onReset={handleOnReset}
          >
            <SelectProjectType
              onChange={(type) => {
                setSelectedType(type);
              }}
              defaultValue={selectedType}
            />
            <input
              placeholder="عنوان المشروع"
              type="text"
              name="project_title"
              ref={projectTitleRef}
              className="rounded-[6px] border border-muted bg-white px-[16px] py-[8px] outline-none"
            />
            <input
              placeholder="اسم عضو"
              type="text"
              name="member_name"
              ref={memberNameRef}
              className="rounded-[6px] border border-muted bg-white px-[16px] py-[8px] outline-none"
            />
            <input
              placeholder="مرجع"
              type="text"
              name="project_reference"
              ref={projectReferenceRef}
              className="rounded-[6px] border border-muted bg-white px-[16px] py-[8px] outline-none"
            />
            <div className="flex gap-[8px]">
              <button
                type="reset"
                className="mt-[8px] w-full rounded-[6px] bg-muted px-[16px] py-[8px] hover:bg-muted-hover"
              >
                مسح
              </button>
              <button className="mt-[8px] w-full rounded-[6px] bg-primary px-[16px] py-[8px] text-white hover:bg-primary-hover">
                بحث
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="w-full">
        <div className="flex justify-end">
          <button
            onClick={handleOnOpenFilter}
            className={`flex w-full items-center gap-[8px] rounded-[8px] border-[2px] border-muted-hover bg-white px-[16px] py-[8px] transition duration-150 ease-in-out hover:bg-muted-hover md:w-auto`}
          >
            <TbFilter size={20} />
            <div className="font-semibold">فلتر</div>
          </button>
        </div>
        <ProjectsList projects={projects} isLoading={isLoading} />
        {!isLoading && <Pagination pagination={pagination} />}
      </div>
    </div>
  );
}
