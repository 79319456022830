import GlobalDocumentsGridLoading from './GlobalDocumentsGridLoading';
import GlobalDocumentsGridItem from './GlobalDocumentsGridItem';

export default function GlobalDocumentsGrid({
  documents,
  isLoading,
  onDeleteGlobalDocument,
}) {
  if (isLoading) {
    return <GlobalDocumentsGridLoading />;
  }

  if (!documents || documents.length == 0) {
    return (
      <div className="col-span-12 h-[200px] mt-[8px]">
        <h2 className="flex h-[256px] items-center justify-center rounded-[6px] border-[2px] border-muted bg-white text-[22px] font-bold">
          لا توجد اي وثائق حاليا.
        </h2>
      </div>
    );
  }

  return documents?.map((document) => (
    <GlobalDocumentsGridItem
      globalDocument={document}
      onDeleteGlobalDocument={onDeleteGlobalDocument}
    />
  ));
}
