import ProjectVersionsHistoryList from './ProjectVersionsHistoryList';
import { useProjects } from '../../../../contexts/forums/projectsContext/ProjectsContext';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../../contexts/authContext/AuthContext';
import axiosClient from '../../../../axios-client';
import { handleFetchError } from '../../../../helper';
import { GoVersions } from 'react-icons/go';

export default function ProjectVersionsButton() {
  const { project_id, committee_id } = useParams();
  const navigate = useNavigate();

  const { authDispatch } = useAuth();

  const versionsSectionRef = useRef(null);

  const [versions, setVersions] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isVersionsOpen, setIsVersionsOpen] = useState(false);

  const getProjectVersions = () => {
    if (!versions) {
      setIsLoading(true);
      axiosClient
        .get(`/committees/${committee_id}/projects/${project_id}/versions`)
        .then(({ data }) => {
          setVersions(data.versions);
        })
        .catch((error) => {
          handleFetchError(error, authDispatch, navigate);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleOnOpenVersions = () => {
    setIsVersionsOpen(true);
    versionsSectionRef.current.classList.remove(`translate-y-full`);
    versionsSectionRef.current.classList.add(`translate-y-0`);
    getProjectVersions();
  };
  const handleOnCloseVersions = () => {
    setIsVersionsOpen(false);
    versionsSectionRef.current.classList.remove(`translate-y-0`);
    versionsSectionRef.current.classList.add(`translate-y-full`);
  };

  return (
    <>
      <button
        onClick={handleOnOpenVersions}
        className="flex w-full items-center gap-[8px] rounded-[8px] border-[2px] bg-white py-[8px] pl-[16px] pr-[12px] hover:bg-muted md:w-auto"
      >
        <GoVersions size={20} />
        <div>الإصدارات</div>
      </button>
      {isVersionsOpen && (
        <div
          onClick={handleOnCloseVersions}
          className="fixed bottom-0 left-0 right-0 top-0 z-10 h-full w-full bg-dark/20 backdrop-blur"
        />
      )}
      <div
        ref={versionsSectionRef}
        className="fixed bottom-0 left-0 right-0 z-10 mt-[16px] translate-y-full border-t border-muted bg-light transition duration-200 ease-in-out"
      >
        <div className="h-[512px] overflow-y-scroll">
          <h2 className="sticky right-0 top-0 z-20 mb-[8px] border-b border-muted bg-light p-[16px] font-bold shadow">
            الإصدارات السابقة للمشروع:
          </h2>
          <div className="flex flex-col gap-[16px] px-[16px] pb-[16px]">
            {isLoading ? (
              Array.from({ length: 3 }).map((_, index) => (
                <div
                  key={index}
                  className="h-[64px] w-full animate-pulse rounded bg-white text-right"
                />
              ))
            ) : (
              <ProjectVersionsHistoryList
                versions={versions}
                isLoading={isLoading}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
