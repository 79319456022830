import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/authContext/AuthContext';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import axiosClient from '../axios-client';
import { AUTH_ACTIONS } from '../contexts/authContext/authActions';
import { handleFetchError } from '../helper';

export default function Splash() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const { authState, authDispatch } = useAuth();

  useEffect(() => {
    setIsLoading(true);
    axiosClient
      .get('/session/me')
      .then(({ data }) => {
        if (data.user == null) {
          authDispatch({ type: AUTH_ACTIONS.LOGOUT });
          navigate('/login', { replace: true });
          return
        }
        authDispatch({
          type: AUTH_ACTIONS.SET_USER,
          payload: {
            user: data.user,
          },
        });
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [authState.token]);

  if (isLoading) {
    return (
      <div className="z-0 flex h-screen w-full items-center justify-center">
        <div className="flex flex-col justify-center gap-8 text-center">
          <img
            className="h-[256px] w-[256px] animate-pulse"
            src="/assets/images/aidsmo_logo.svg"
            alt="Logo"
          />
        </div>
      </div>
    );
  } else {
    return <Outlet />;
  }
}
