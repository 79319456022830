import { FaEye, FaVoteYea } from 'react-icons/fa';
import { RiDownloadLine, RiQuestionAnswerFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import {
  getProjectType,
  timeAgoInArabic,
  toDateTime,
} from '../../../../helper';
import { MdEdit } from 'react-icons/md';
import { useAuth } from '../../../../contexts/authContext/AuthContext';

export default function ProjectsListItem({ isEven, project }) {
  const { authState } = useAuth();
  return (
    <Link
      to={`/forums/committees/${project.committee_id}/projects/${project.id}`}
      className={`relative col-span-2 flex flex-col overflow-hidden border-2 p-[8px] transition-all xl:col-span-2 ${isEven ? 'border-white bg-white' : 'border-odd bg-odd'} gap-[8px] rounded-[8px] hover:border-muted`}
    >
      <div className="absolute left-[8px] top-[8px]">
        <div className="flex items-center gap-[8px]">
          {project.user.committees &&
            project.user.committees[0]?.pivot.role &&
            project.user.committees[0]?.pivot.role !== 'MEMBER' && (
              <Link
                to={`/forums/committees/${project.committee_id}/projects/${project.id}/votes`}
                title="الأصوات"
                className={`flex w-full items-center gap-[8px] rounded-[8px] border-[2px] border-muted-hover bg-muted p-[8px] pl-[16px] text-secondary transition duration-150 ease-in-out hover:bg-muted-hover md:w-auto`}
              >
                <FaVoteYea size={20} />
                <div className="line-clamp-1">الأصوات</div>
              </Link>
            )}
          {project.user.country.id === authState.currentUser.country_id && (
            <Link
              to={`/forums/committees/${project.committee_id}/projects/${project.id}/edit`}
              className="rounded-[8px] border-[2px] border-primary-hover bg-primary  p-[8px] text-white"
            >
              <MdEdit size={20} />
            </Link>
          )}
        </div>
      </div>
      <div>
        <div className="flex items-center gap-[4px]">
          <img
            className="w-[40px] overflow-hidden rounded-[5px] border-2 border-muted object-contain"
            src={`${process.env.REACT_APP_BASE_URL}/storage/flags/${project.user.country.image}`}
            alt=""
          />
          {project.created_at !== project.updated_at ? (
            <div
              title={toDateTime(project.updated_at)}
              className=" text-secondary"
            >
              تم التعديل {timeAgoInArabic(project.updated_at)}
            </div>
          ) : (
            <div
              title={toDateTime(project.created_at)}
              className=" text-secondary"
            >
              {timeAgoInArabic(project.created_at)}
            </div>
          )}
        </div>
        <Link className="text-[15px] font-bold">
          {project.user.first_name_ar} {project.user.last_name_ar}
        </Link>
      </div>
      <div>
        <div className="flex gap-[8px] text-[24px] font-bold">
          <h2 className="select-all lg:line-clamp-1">{project.title_ar}</h2>
        </div>
      </div>
      <div className="flex flex-col justify-between gap-[8px] md:flex-row">
        <div className="flex flex-col items-center justify-between gap-[4px] text-[14px] sm:flex-row sm:text-[12px]">
          <div className="w-full cursor-default select-none whitespace-nowrap rounded-[5px] border-2 border-dark bg-dark/60 px-[16px] py-[2px] text-white sm:w-auto">
            {getProjectType(project.type)}
          </div>
          <div className=" flex w-full cursor-default select-none rounded-[5px] border-[2px]  border-primary-hover bg-primary px-[16px] py-[2px] text-white sm:w-auto">
            {project.stage}:{project.version}
          </div>
        </div>
        <div className="grid grid-cols-12 gap-[8px] text-[14px] md:w-1/2 lg:w-1/3">
          <div className="col-span-4 flex justify-end gap-[8px]">
            <span>{project.views_count}</span>
            <FaEye size={20} />
          </div>
          <div className="col-span-4 flex justify-end gap-[8px]">
            <span>{project.comments_count}</span>
            <RiQuestionAnswerFill size={20} />
          </div>
          <div className="col-span-4 flex justify-end gap-[8px]">
            <span>{project.download_count}</span>
            <RiDownloadLine size={20} />
          </div>
        </div>
      </div>
    </Link>
  );
}
