import React, { useRef, useState } from 'react';
import { FiRefreshCw, FiUploadCloud } from 'react-icons/fi';
import axiosClient from '../../../axios-client';
import { useNavigate } from 'react-router-dom';
import { CgSpinner } from 'react-icons/cg';
import { handleFetchError, showToastSuccess } from '../../../helper';
import { useAuth } from '../../../contexts/authContext/AuthContext';

export default function CommitteesCreatePage() {
  const { authDispatch } = useAuth();
  const navigate = useNavigate();

  const imageRef = useRef(null);
  const nameRef = useRef(null);
  const symbolRef = useRef(null);

  const [selectedImage, setSelectedImage] = useState(null);
  const [isInProgress, setIsInProgress] = useState(false);

  const handleOnChangeImageInput = () => {
    if (imageRef.current.files[0]) {
      setSelectedImage(URL.createObjectURL(imageRef.current.files[0]));
    }
  };

  const handleOnSubmitCreateCommittee = (event) => {
    event.preventDefault();
    setIsInProgress(true);
    if (isInProgress) {
      return;
    }
    const payload = {
      name: nameRef.current?.value,
      symbol: symbolRef.current?.value,
      image: imageRef.current?.files[0],
    };

    axiosClient
      .post(`/dashboard/committees/create`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({ data }) => {
        showToastSuccess(data.message);
        navigate(`/dashboard/committees?page=1`);
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        setIsInProgress(false);
      });
  };

  return (
    <div className={`w-full p-[16px] text-[14px]`}>
      <h1 className="mb-[8px] text-[22px]">إنشاء لجنة</h1>
      <div className="rounded-[8px] bg-white p-[16px]">
        <form
          onSubmit={handleOnSubmitCreateCommittee}
          className={`grid grid-cols-12 gap-[16px]`}
          method="POST"
          enctype="multipart/form-data"
        >
          <div className={`col-span-12 flex flex-col space-y-[4px]`}>
            <label htmlFor={`ID_COMMITTEE_IMAGE`} className={`font-bold`}>
              الصورة
              <span className={`text-danger`}>*</span>
            </label>
            <label
              className={`${selectedImage ? 'hidden' : 'flex'} group h-[256px] cursor-pointer flex-col items-center justify-center space-y-[16px] rounded-[8px] border-[2px] border-muted hover:border-primary hover:text-primary`}
              for="ID_COMMITTEE_IMAGE"
            >
              <FiUploadCloud className="h-[32px] w-[32px]" />
              <span className="font-semibold">قم بسحب وإسقاط الصور هنا</span>
              <span className="rounded-md bg-muted px-4 py-1 group-hover:bg-primary group-hover:text-white">
                تصفح ملفات
              </span>
            </label>
            <div
              className={`${selectedImage ? 'relative' : 'hidden'} relative col-span-12`}
            >
              <label
                htmlFor={`ID_COMMITTEE_IMAGE`}
                className="absolute m-[8px] cursor-pointer rounded-[8px] bg-primary text-white hover:bg-primary-hover"
              >
                <div className="flex gap-[8px] px-[16px] py-[8px]">
                  <FiRefreshCw size={20} />
                  <span>تغيير</span>
                </div>
              </label>
              <img
                className="h-[256px] w-full rounded-[8px] border-[2px] border-muted object-cover"
                src={selectedImage}
                alt="committee_image"
              />
            </div>
            <input
              ref={imageRef}
              className={`hidden rounded-[6px] border-[2px] border-light px-[16px] py-[8px] outline-none`}
              type={`file`}
              name={`committee_image`}
              id={`ID_COMMITTEE_IMAGE`}
              accept="image/png, image/webp, image/jpeg"
              onChange={handleOnChangeImageInput}
            />
          </div>

          <div className={`col-span-12 flex flex-col space-y-[4px]`}>
            <label htmlFor={`ID_TITLE`} className={`font-bold`}>
              الأسم
              <span className={`text-danger`}> *</span>
            </label>
            <input
              ref={nameRef}
              className={`rounded-[6px] border-[2px] border-muted px-[16px] py-[8px] outline-none`}
              type={`text`}
              name={`title`}
              id={`ID_TITLE`}
            />
          </div>
          <div className={`col-span-12 flex flex-col space-y-[4px]`}>
            <label htmlFor={`ID_SYMBOL`} className={`font-bold`}>
              الرمز
              <span className={`text-danger`}> *</span>
            </label>
            <input
              ref={symbolRef}
              className={`rounded-[6px] border-[2px] border-muted px-[16px] py-[8px] outline-none`}
              type={`text`}
              name={`symbol`}
              id={`ID_SYMBOL`}
            />
          </div>

          <div className={`col-span-2 flex flex-col space-y-[4px]`}>
            <button
              disabled={isInProgress}
              className={`${isInProgress ? 'cursor-wait' : 'hover:bg-primary-hover'} flex justify-center rounded-[6px] border bg-primary px-[16px] py-[8px] text-white`}
            >
              {isInProgress && <CgSpinner className="animate-spin" size={21} />}
              {!isInProgress && <>اضاف</>}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
