import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/authContext/AuthContext';
import axiosClient from '../../../axios-client';
import { handleFetchError } from '../../../helper';
import GlobalDocumentsGrid from '../../../components/dashboard/global-documents-page/GlobalDocumentsGrid';

export default function GlobalDocumentsPage() {
  const { authDispatch, authState } = useAuth();
  const navigate = useNavigate();

  // Get the search params from the current location
  const [searchParams] = useSearchParams();

  const [pagination, setPagination] = useState(null);
  const [documents, setDocuments] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  const fetchDocuments = () => {
    setIsLoading(true);
    axiosClient
      .get(`/dashboard/documents/global?${searchParams.toString()}`)
      .then(({ data }) => {
        setDocuments(data.documents);
        setPagination({
          last_page_url: data.last_page_url,
          links: data.links,
          first_page_url: data.first_page_url,
          current_page: data.current_page,
        });
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleOnDeleteGlobalDocument = () => {
    fetchDocuments();
  };

  useEffect(() => {
    fetchDocuments();
  }, [authState.token, searchParams]);

  return (
    <div className={`w-full p-[16px]`}>
      <div className={`mb-[8px] flex items-end justify-between`}>
        <h1 className="text-[22px]">وثائق اللجان العامة</h1>
        <Link
          to={`/dashboard/documents/global/create`}
          className={`rounded-[6px] bg-primary px-[16px] py-[8px] text-white hover:bg-primary-hover`}
        >
          إضافة وثيقة
        </Link>
      </div>
      <div className="grid grid-cols-12 gap-[8px]">
        <GlobalDocumentsGrid
          documents={documents}
          isLoading={isLoading}
          onDeleteGlobalDocument={handleOnDeleteGlobalDocument}
        />
      </div>
    </div>
  );
}
