import { useRef, useState, useEffect } from 'react';
import { MdClose, MdMenu } from 'react-icons/md';
import { Link, useLocation, useParams } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import { RiFileCopy2Fill } from 'react-icons/ri';
import { useCommittee } from '../../../contexts/forums/committeeContext/CommitteeContext';

const NavbarOpenMenu = () => {
  const location = useLocation();
  const checkIsActive = (path) => location.pathname === path;
  return (
    <div className="flex flex-col gap-[8px]">
      <div
        title={`الرئيسية`}
        className={`group rounded-[6px] ${checkIsActive('/forums') ? 'bg-white text-primary' : 'cursor-pointer hover:bg-white '} transition duration-200 ease-in-out`}
      >
        <Link to="/forums" className={`flex items-center gap-[12px] p-[8px]`}>
          <FaHome size={20} />
        </Link>
      </div>
      <div
        title={`مشاريع الخاصة بي`}
        className={`group rounded-[6px]  ${checkIsActive('/forums/projects/our') ? 'bg-white text-primary' : 'cursor-pointer hover:bg-white '} transition duration-200 ease-in-out`}
      >
        <Link
          to="/forums/projects/our"
          className={`flex items-center gap-[12px] p-[8px]`}
        >
          <RiFileCopy2Fill size={20} />
        </Link>
      </div>
    </div>
  );
};

const NavbarOpenCommittees = () => {
  const { committee_id } = useParams();
  const { committeeState } = useCommittee();
  return (
    <div className="flex flex-col gap-[8px]">
      {committeeState.committees?.map((committee, i) => (
        <Link
          to={`/forums/committees/${committee.id}`}
          className={`flex select-none p-[8px] font-semibold ${committee.id === committee_id ? 'bg-white text-primary' : 'cursor-pointer hover:bg-white '} rounded-[6px] transition duration-300 ease-in-out`}
        >
          {committee.symbol.split(`/`)[1]}
        </Link>
      ))}
    </div>
  );
};
const NavbarCommittees = () => {
  const { committee_id } = useParams();
  const { committeeState } = useCommittee();
  return (
    <div className="flex flex-col gap-[8px]">
      {committeeState.committees?.map((committee, i) => (
        <Link
          to={`/forums/committees/${committee.id}`}
          className={`flex select-none flex-col px-[16px] py-[10px] font-semibold ${committee.id === committee_id ? 'bg-white text-primary' : 'cursor-pointer hover:bg-white hover:text-primary'} rounded-[6px] transition duration-300 ease-in-out`}
        >
          <div className="line-clamp-1 font-semibold"> {committee.name}</div>
          <div className="line-clamp-1 font-light">{committee.symbol}</div>
        </Link>
      ))}
    </div>
  );
};

export default function NavbarOpen() {
  const menuRef = useRef(null);
  const location = useLocation();
  const checkIsActive = (path) => location.pathname === path;

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleOnOpenMenu = () => {
    setIsMenuOpen(true);
    menuRef.current.classList.remove(`translate-x-full`);
    menuRef.current.classList.add(`translate-x-0`);
  };
  const handleOnCloseMenu = () => {
    setIsMenuOpen(false);
    menuRef.current.classList.remove(`translate-x-0`);
    menuRef.current.classList.add(`translate-x-full`);
  };

  useEffect(() => {
    if (isMenuOpen) {
      handleOnCloseMenu();
    }
    return () => {};
  }, [location.pathname]);

  return (
    <>
      {isMenuOpen && (
        <div
          onClick={handleOnCloseMenu}
          className="fixed bottom-0 left-0 right-0 top-0 z-20 h-full w-full bg-dark/20 backdrop-blur"
        ></div>
      )}
      <div
        ref={menuRef}
        className="fixed right-0 top-[70px] z-20 h-full w-full translate-x-full overflow-y-scroll border-l border-muted bg-light px-[8px] py-[16px] transition md:w-[460px]"
      >
        <div className={`flex flex-col gap-[16px]`}>
          <div className="flex items-center justify-between">
            <h3 className="text-[18px] font-bold">القائمة الرئيسية</h3>
            <button
              onClick={handleOnCloseMenu}
              className={`rounded-[8px] border-[2px] border-muted bg-white p-[8px] transition duration-150 ease-in-out hover:bg-muted`}
            >
              <MdClose size={24} />
            </button>
          </div>
          <div className="h-px w-full bg-muted" />
          <div className="flex flex-col gap-[8px]">
            <div
              title={`الرئيسية`}
              className={`group rounded-[8px] ${checkIsActive('/forums') ? 'bg-white text-primary' : 'cursor-pointer hover:bg-white hover:text-primary'} transition duration-200 ease-in-out`}
            >
              <Link
                to="/forums"
                className={`flex items-center gap-[12px] p-[8px]`}
              >
                <div
                  className={`rounded-[8px] ${checkIsActive('/forums') ? 'bg-primary text-white' : 'bg-muted text-secondary group-hover:bg-primary group-hover:text-white'} p-[8px] transition duration-200 ease-in-out`}
                >
                  <FaHome size={22} />
                </div>
                <div
                  className={`${checkIsActive('/forums') ? '' : 'group-hover:text-primary'} whitespace-nowrap`}
                >
                  الرئيسية
                </div>
              </Link>
            </div>
            <div
              title={`مشاريع الخاصة بي`}
              className={`group rounded-[8px]  ${checkIsActive('/forums/projects/our') ? 'bg-white text-primary' : 'cursor-pointer hover:bg-white hover:text-primary'} transition duration-200 ease-in-out`}
            >
              <Link
                to="/forums/projects/our"
                className={`flex items-center gap-[12px] p-[8px]`}
              >
                <div
                  className={`rounded-[8px] ${checkIsActive('/forums/projects/our') ? 'bg-primary text-white' : 'bg-muted text-secondary group-hover:bg-primary group-hover:text-white'} p-[8px] transition duration-200 ease-in-out`}
                >
                  <RiFileCopy2Fill size={22} />
                </div>
                <div
                  className={`${checkIsActive('/forums/projects/our') ? '' : 'group-hover:text-primary'} whitespace-nowrap`}
                >
                  مشاريع الخاصة بي
                </div>
              </Link>
            </div>
          </div>
          <div className="h-px w-full bg-muted" />
          <NavbarCommittees />
        </div>
      </div>
      <div className="sticky right-0 top-[78px] h-full w-[56px] px-[8px] py-[16px]">
        <div className={`flex flex-col items-center gap-[16px]`}>
          <div className="items-center">
            <button
              onClick={handleOnOpenMenu}
              className={`rounded-[6px] bg-white p-[8px]`}
            >
              <MdMenu size={20} />
            </button>
          </div>
          <div className="h-px w-full bg-muted" />
          <NavbarOpenMenu />
          <div className="h-px w-full bg-muted" />
          <NavbarOpenCommittees />
        </div>
      </div>
    </>
  );
}
