import { useEffect, useState } from 'react';
import ProjectCommentsList from './ProjectCommentsList';
import ProjectCommentsListLaoding from './ProjectCommentsListLaoding';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../../../contexts/authContext/AuthContext';
import axiosClient from '../../../../../axios-client';
import { handleFetchError } from '../../../../../helper';

export default function ProjectComments({ refresh }) {
  const { project_id, committee_id } = useParams();
  const navigate = useNavigate();

  const { authDispatch } = useAuth();

  const [comments, setComments] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getProjectComments = () => {
    setIsLoading(true);
    axiosClient
      .get(`/committees/${committee_id}/projects/${project_id}/comments`)
      .then(({ data }) => {
        setComments(data.comments);
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        setIsLoading(false);
      });
    return () => {};
  };

  useEffect(() => {
    getProjectComments();
  }, [committee_id, project_id, refresh]);

  const handleOnEditComment = () => {
    getProjectComments();
  };

  return (
    <div className="mt-[16px]">
      <h2 className="font-bold">التعليقات :</h2>
      <div className="mt-[4px] flex flex-col gap-2">
        {isLoading ? (
          <ProjectCommentsListLaoding />
        ) : (
          <ProjectCommentsList
            comments={comments}
            onEditComment={handleOnEditComment}
          />
        )}
      </div>
    </div>
  );
}
