import React, { useRef } from 'react';
import { GrPowerReset } from 'react-icons/gr';
import axiosClient from '../../../axios-client';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/authContext/AuthContext';
import {
  generateRandomPassword,
  handleFetchError,
  showToastError,
  showToastSuccess,
} from '../../../helper';
import { AUTH_ACTIONS } from '../../../contexts/authContext/authActions';

export default function UserChangeMyPasswordPage() {
  const { authDispatch } = useAuth();
  const navigate = useNavigate();

  const currentPasswordRef = useRef(null);
  const newPasswordRef = useRef(null);
  const newPasswordConfirmationRef = useRef(null);

  const handleOnSubmitChangePasswordForm = (event) => {
    event.preventDefault();

    const payload = {
      current_password: currentPasswordRef.current?.value,
      new_password: newPasswordRef.current?.value,
      new_password_confirmation: newPasswordConfirmationRef.current?.value,
    };

    if (!payload.current_password) {
      showToastError('كلمة المرور الحالية مطلوبة');
      return;
    }
    if (payload.new_password !== payload.new_password_confirmation) {
      showToastError('كلمة المرور الجديدة وتأكيدها لا يتطابقان');
      return;
    }

    axiosClient
      .put(`/session/me/change/password`, payload)
      .then(({ data }) => {
        showToastSuccess(data.message);
        authDispatch({ type: AUTH_ACTIONS.LOGOUT });
        navigate('/login', { replace: true });
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {});
  };

  return (
    <div className={`w-full p-[16px] text-[14px]`}>
      <h1 className="mb-[8px] text-[22px]">تغيير كلمة مرور</h1>
      <div className="rounded-[8px] bg-white p-[16px]">
        <form
          onSubmit={handleOnSubmitChangePasswordForm}
          className={`grid grid-cols-12 gap-[16px]`}
          method="POST"
        >
          <div className={`col-span-12 flex-col space-y-[4px] `}>
            <label htmlFor={`ID_CURRENT_PASSWORD`} className={`font-bold`}>
              كلمة المرور الحالية
              <span className={`text-danger`}>*</span>
            </label>
            <div className="flex w-full gap-[4px]">
              <input
                ref={currentPasswordRef}
                className={`w-full rounded-[6px] border-[2px] border-light px-[16px] py-[8px] outline-none`}
                type={`password`}
                name={`CURRENT_PASSWORD`}
                id={`ID_CURRENT_PASSWORD`}
              />
            </div>
          </div>

          <div className={`col-span-12 flex-col space-y-[4px] `}>
            <label htmlFor={`ID_NEW_PASSWORD`} className={`font-bold`}>
              كلمة مرور الجديدة
              <span className={`text-danger`}>*</span>
            </label>
            <div className="flex w-full gap-[4px]">
              <input
                ref={newPasswordRef}
                className={`w-full rounded-[6px] border-[2px] border-light px-[16px] py-[8px] outline-none`}
                type={`password`}
                name={`new_passwprd`}
                id={`ID_NEW_PASSWORD`}
              />
            </div>
          </div>
          <div className={`col-span-12 flex-col space-y-[4px] `}>
            <label
              htmlFor={`ID_NEW_PASSWORD_CONFIRMATION`}
              className={`font-bold`}
            >
              تأكيد كلمة مرور الجديدة
              <span className={`text-danger`}>*</span>
            </label>
            <div className="flex w-full gap-[4px]">
              <input
                ref={newPasswordConfirmationRef}
                className={`w-full rounded-[6px] border-[2px] border-light px-[16px] py-[8px] outline-none`}
                type={`password`}
                name={`new_passwprd_confirmation`}
                id={`ID_NEW_PASSWORD_CONFIRMATION`}
              />
            </div>
          </div>
          <div className={`col-span-2 flex flex-col space-y-[4px]`}>
            <button
              type={`submit`}
              className={` rounded-[6px] border bg-primary px-[16px] py-[8px] text-white hover:bg-primary-hover`}
            >
              تغيير
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
