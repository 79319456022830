import React from 'react'
import CommitteesTableHeader from './CommitteesTableHeader'
import CommitteesTableBodyLoading from './CommitteesTableBodyLoading'
import CommitteesTableBody from './CommitteesTableBody'

export default function CommitteesTable({ committees, isLoading, onDeleteCommittee }) {
  return (
    <table
      className={`w-full border-separate border-spacing-y-[8px] text-right text-[14px]`}
    >
      <CommitteesTableHeader />
      {isLoading && <CommitteesTableBodyLoading />}
      {!isLoading && <CommitteesTableBody committees={committees} onDeleteCommittee={onDeleteCommittee} />}
    </table>
  )
}
