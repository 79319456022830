import React from 'react'

export default function CommitteesTableHeader() {
  return (
    <thead>
      <tr className={`my-2 bg-dark text-white`}>
        <th className={`rounded-r-[4px] p-[16px] w-[228px]`}>الصورة</th>
        <th className={`p-[16px] text-right`}>اسم</th>
        <th className={`p-[16px] text-center`}>عدد الأعضاء</th>
        <th className={`rounded-l-[4px] p-[16px] text-right`}></th>
      </tr>
    </thead>
  )
}
