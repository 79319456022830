import React, { useState } from 'react';
import { FaUsers } from 'react-icons/fa';
import { HiTrash } from 'react-icons/hi2';
import { MdEdit } from 'react-icons/md';
import { SlOptions } from 'react-icons/sl';
import { Link } from 'react-router-dom';
import DialogConfirmation from '../../dialogs/DialogConfirmation';

export default function CommitteesTableRow({
  committee,
  isEven,
  onDeleteCommittee,
}) {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const handleOnCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleOnOpenDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };
  const handleOnConfirmDeleteDialog = () => {
    onDeleteCommittee(committee.id);
    setIsDeleteDialogOpen(false);
  };
  return (
    <tr
      key={committee.id}
      className={`${isEven ? 'bg-white' : 'bg-odd'} my-2 overflow-hidden`}
    >
      <DialogConfirmation
        onClose={handleOnCloseDeleteDialog}
        onConfirm={handleOnConfirmDeleteDialog}
        isOpen={isDeleteDialogOpen}
      >
        <p>
          هل أنت متأكد أنك تريد حذف هذه اللجنة؟
          <br />
        </p>
        <p className="mt-[8px] font-bold text-danger">"{committee.name}"</p>
      </DialogConfirmation>
      <td className="rounded-r-[6px] p-[8px]">
        <img
          className="h-[64px] w-full rounded-[6px] border-[2px] border-muted object-cover"
          src={`${process.env.REACT_APP_BASE_URL}/storage/images/committees/${committee.image}`}
          alt={committee.name}
        />
      </td>
      <td className={`p-[16px]`}>
        <h3 className="select-all font-bold">{committee.name}</h3>
        <p className="select-all font-light">{committee.symbol}</p>
      </td>
      <td className={`p-[16px]`}>
        <h3 className="select-all text-center font-bold">
          {committee.members_count}
        </h3>
      </td>
      <td className={`w-[174px] rounded-l-[6px] p-[16px]`}>
        <div className="flex gap-[8px]">
          <Link
            to={`/dashboard/committees/${committee.id}/members`}
            className={`flex gap-[8px] rounded-[6px] border-[2px] border-muted px-[16px] py-[8px] text-[13px] hover:bg-muted`}
          >
            <FaUsers size={20} />
            <span>الأعضاء</span>
          </Link>
          <div className={`group relative flex gap-[4px]`}>
            <button className="rounded-[6px] bg-muted px-[12px] py-[8px]">
              <SlOptions size={16} />
            </button>
            <div className="absolute left-0 top-[8px] z-50 hidden group-hover:block">
              <div className="z-50 mt-[40px] min-w-[200px] rounded-[8px] border-[2px] border-muted bg-white p-[8px]">
                <ul className="flex flex-col space-y-[4px]">
                  <li>
                    <Link
                      to={`/dashboard/committees/${committee.id}/edit`}
                      className={`flex gap-[8px] rounded-[6px] px-[16px] py-[8px] text-[13px] hover:bg-muted`}
                    >
                      <MdEdit size={20} />
                      <span>تعديل</span>
                    </Link>
                  </li>
                  <li>
                    <button
                      onClick={handleOnOpenDeleteDialog}
                      className={`flex w-full gap-[8px] rounded-[6px] bg-danger/10 px-[16px] py-[8px] text-[13px] text-danger hover:bg-muted`}
                    >
                      <HiTrash size={20} />
                      <span>حذف</span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
}
