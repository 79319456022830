import { createContext, useContext, useReducer } from 'react';

import { authInitialState } from './authInitialState';
import { authReducer } from './authReducer';

const AuthContext = createContext({
  state: authInitialState,
  dispatch: () => {},
});

export const AuthProvider = ({ children }) => {
  const [authState, authDispatch] = useReducer(authReducer, authInitialState);

  return (
    <AuthContext.Provider value={{ authState, authDispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

export const useAuth = () => useContext(AuthContext);
