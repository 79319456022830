import React from 'react';
import { Toaster } from 'react-hot-toast';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/authContext/AuthContext';
import { AUTH_ACTIONS } from '../../contexts/authContext/authActions';

export default function DatabasesLayout() {
  const { authDispatch, authState } = useAuth();
  const navigate = useNavigate();

  const handleOnClickLogout = () => {
    authDispatch({
      type: AUTH_ACTIONS.LOGOUT,
    });
    navigate('/login', {
      replace: true,
    });
  };
  
  return (
    <div className="text-[14px]">
      <Toaster />
      <div className="m-auto flex w-[800px] flex-col">
        <div className="flex items-center justify-between py-[16px]">
          <div>
            <ul className="flex gap-[16px]">
              <li>
                <Link
                  to={`/databases/projects/add`}
                  className="flex rounded-[6px] bg-primary px-[16px] py-[8px] text-white hover:bg-primary-hover"
                >
                  اضف مشروعا
                </Link>
              </li>
              <li>
                <Link
                  to={`/databases/projects`}
                  className="flex rounded-[6px] bg-white px-[16px] py-[8px]"
                >
                  مشاريع عربية
                </Link>
              </li>
              <li>
                <Link
                  to={`/databases/projects`}
                  className="flex rounded-[6px] bg-white px-[16px] py-[8px]"
                >
                  مشاريع متبناه
                </Link>
              </li>
            </ul>
          </div>
          <div className="flex gap-[8px]">
            <button className="rounded-[6px] bg-muted px-[16px] py-[8px] font-bold">
              {authState.currentUser.last_name_ar}{' '}
              {authState.currentUser.first_name_ar}
            </button>
            <button
              onClick={handleOnClickLogout}
              className="rounded-[6px] bg-danger px-[16px] py-[8px] text-white hover:bg-danger-hover"
            >
              تسجيل الخروج
            </button>
          </div>
        </div>
        <Outlet />
      </div>
    </div>
  );
}
