import React from 'react';

export default function UsersTableHeader() {
  return (
    <thead>
      <tr className={`my-2 bg-dark text-white`}>
        <th className={`rounded-r-[4px] p-[16px]`}>الدولة / الاسم الكامل</th>
        <th className={`p-[16px] text-center`}>بريد إلكتروني</th>
        <th className={`w-[64px] p-[16px] text-right`}>نوع</th>
        <th className={`rounded-l-[4px] p-[16px] text-right`}>actions</th>
      </tr>
    </thead>
  );
}
