import IndexCommitteesGridCards from '../../../components/formus/index/committees/IndexCommitteesGridCards';
import { useCommittee } from '../../../contexts/forums/committeeContext/CommitteeContext';

export default function IndexCommittees() {
  const { committeeState } = useCommittee();

  return (
    <>
      <h2 className="mb-[8px] text-[24px] font-bold">منتديات اللجان الفنية</h2>
      <IndexCommitteesGridCards
        isLoading={committeeState.committeesIsLoading}
        committees={committeeState.committees}
      />
    </>
  );
}
