import React from 'react';

export default function IndexCommitteesGridCardsLoading() {
  return (
    <div className="grid w-full grid-cols-12 gap-[16px]">
      {[...Array(8)].map((_, index) => (
        <div
          key={index}
          className={`col-span-6 h-[250px] animate-pulse rounded-[4px] bg-white md:col-span-6 xl:col-span-4`}
        />
      ))}
    </div>
  );
}
