import React, { useEffect, useState } from 'react';
import ProjectCard from '../../../components/formus/projects/project-page/ProjectCard';
import CommitteeTitle from '../../../components/formus/ui/CommitteeTitle';
import ProjectReferencesListButton from '../../../components/formus/projects/project-page/ProjectReferencesListButton';

import ProjectVersionsButton from '../../../components/formus/projects/project-page/ProjectVersionsButton';
import ProjectComments from '../../../components/formus/projects/project-page/project-page-comments/ProjectComments';
import ProjectCommentCreate from '../../../components/formus/projects/project-page/project-page-comments/ProjectCommentCreate';
import axiosClient from '../../../axios-client';
import { handleFetchError } from '../../../helper';
import { useAuth } from '../../../contexts/authContext/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import ProjectPageDownloadButton from '../../../components/formus/projects/project-page/ProjectPageDownloadButton';

export default function ProjectPage() {
  const { project_id, committee_id } = useParams();
  const navigate = useNavigate();

  const { authDispatch } = useAuth();

  const [project, setProject] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    axiosClient
      .get(`/committees/${committee_id}/projects/${project_id}`)
      .then(({ data }) => {
        setProject(data.project);
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        setIsLoading(false);
      });

    return () => {};
  }, [committee_id, project_id]);

  const handleOnUserComment = () => {
    // Update the user_has_comment property of the project
    setProject((prevProject) => ({
      ...prevProject,
      user_has_comment: true,
    }));
    setRefresh(!refresh);
  };

  return (
    <div className="flex w-full">
      <div className="w-full border-x border-muted p-[16px]">
        <CommitteeTitle />
        <div className="mt-[16px] flex flex-col justify-between gap-[8px] md:flex-row md:items-end">
          <div className="flex items-end gap-[8px] md:justify-between">
            <ProjectVersionsButton />
            <ProjectReferencesListButton
              references={project?.references}
              isLoading={isLoading}
            />
          </div>
          <ProjectPageDownloadButton
            link={`/committees/${committee_id}/projects/${project_id}/download`}
            isLoading={isLoading}
            fileName={`${project?.title_ar}.docx`}
          />
        </div>
        <ProjectCard project={project} isLoading={isLoading} />

        <ProjectCommentCreate
          onUserComment={handleOnUserComment}
          project={project}
          isLoading={isLoading}
          committeeId={committee_id}
        />

        <ProjectComments refresh={refresh} />
      </div>
    </div>
  );
}
