import React, { useState } from 'react';
import DialogConfirmation from '../dialogs/DialogConfirmation';
import { SlOptions } from 'react-icons/sl';
import { BiDownload } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { HiTrash } from 'react-icons/hi2';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/authContext/AuthContext';
import axiosClient from '../../../axios-client';
import { FaFile } from 'react-icons/fa';
import { handleFetchError, showToastSuccess } from '../../../helper';
import { MdEdit } from 'react-icons/md';

export default function GlobalDocumentsGridItem({
  globalDocument,
  onDeleteGlobalDocument,
}) {
  const { authDispatch, authState } = useAuth();

  const navigate = useNavigate();

  const [isDownloading, setIsDownloading] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const handleOnCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleOnOpenDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleOnConfirmDeleteDialog = (documentId) => {
    handleRemoveGlobalDocument(documentId);

    setIsDeleteDialogOpen(false);
  };

  const handleRemoveGlobalDocument = (documentId) => {
    axiosClient
      .delete(`/dashboard/documents/global/${documentId}`)
      .then(({ data }) => {
        showToastSuccess(data.message);
        onDeleteGlobalDocument();
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      });
  };

  const handleOnClickDownloadDocument = (documentId, fileName) => {
    setIsDownloading(true);
    axiosClient
      .get(`/dashboard/documents/global/${documentId}/download`, {
        responseType: 'blob',
      })
      .then((response) => {
        // Create a URL object from the Blob data
        const url = window.URL.createObjectURL(new Blob([response.data]));
        // Create a link element to trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); // Set the file name for the download
        document.body.appendChild(link); // Append to body
        // Programmatically trigger the download
        link.click();
        // Cleanup: remove the link and release the URL object
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        showToastSuccess(fileName);
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };
  return (
    <div className="relative col-span-12 mt-[8px] flex items-center rounded-[8px] bg-white p-[16px] lg:col-span-6">
      <DialogConfirmation
        onClose={() => handleOnCloseDeleteDialog()}
        onConfirm={() => handleOnConfirmDeleteDialog(globalDocument.id)}
        isOpen={isDeleteDialogOpen}
      >
        <p>
          هل أنت متأكد أنك تريد حذف هذه الوثيقة؟
          <br />
          <br />
          <b>
            {globalDocument.title}.{globalDocument.file.split('.')[1]}
          </b>
        </p>
      </DialogConfirmation>
      <div className="flex w-full items-center gap-[16px] ">
        <FaFile size={32} />
        <p className="line-clamp-1 pl-[48px]">{globalDocument.title}</p>
      </div>
      <div className="absolute left-[16px]">
        <div className={`group relative flex justify-end gap-[4px]`}>
          <button className="rounded-[6px] bg-muted p-[8px]">
            <SlOptions size={16} />
          </button>
          <div className="absolute left-0 top-0 z-50 hidden group-hover:block">
            <div className="z-50 mt-[40px] min-w-[200px] rounded-[8px] border-[2px] border-muted bg-white p-[8px]">
              <ul className="flex flex-col space-y-[4px]">
                <li>
                  <button
                    disabled={isDownloading}
                    onClick={() =>
                      handleOnClickDownloadDocument(
                        globalDocument.id,
                        `${globalDocument.title}.${globalDocument.file.split('.')[1]}`,
                      )
                    }
                    className={`flex w-full gap-[8px] rounded-[6px] px-[16px] py-[8px] text-[13px] hover:bg-muted disabled:cursor-not-allowed disabled:bg-secondary disabled:text-white`}
                  >
                    <BiDownload size={20} />
                    <span>تنزيل وثيقة </span>
                  </button>
                </li>
                {/* <li>
                  <Link
                    to={`/dashboard/documents/global/${globalDocument.id}`}
                    className={`flex gap-[8px] rounded-[6px] px-[16px] py-[8px] text-[13px] hover:bg-muted`}
                  >
                    <MdEdit size={20} />
                    <span>تعديل وثيقة </span>
                  </Link>
                </li> */}
                <li>
                  <button
                    onClick={handleOnOpenDeleteDialog}
                    className={`flex w-full gap-[8px] rounded-[6px] bg-danger/10 px-[16px] py-[8px] text-[13px] text-danger hover:bg-muted`}
                  >
                    <HiTrash size={20} />
                    <span>حذف</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
