import React, { useEffect, useRef, useState } from 'react';
import axiosClient from '../../../../axios-client';
import { genderOptions, typeOptions } from '../../../../config/const';
import { handleFetchError, showToastSuccess } from '../../../../helper';
import { CgSpinner } from 'react-icons/cg';

import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../contexts/authContext/AuthContext';

export default function UserEditForm({ user }) {
  const { authDispatch } = useAuth();
  const navigate = useNavigate();

  const [isInProgress, setIsInProgress] = useState(false);

  const [countriesOptions, setCountriesOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedGender, setSelectedGender] = useState(genderOptions[0]);

  const firstNameAr = useRef(null);
  const firstNameEn = useRef(null);
  const lastNameAr = useRef(null);
  const lastNameEn = useRef(null);
  const emailRef = useRef(null);

  useEffect(() => {
    if (!user) {
      return;
    }
    setSelectedCountry({
      label: user.country.name_ar,
      value: user.country.id,
    });
    setSelectedType(
      user.type === 'FORUMS_USER' ? typeOptions[1] : typeOptions[0],
    );
    setSelectedGender(
      user.gender == 'ذكر' ? genderOptions[0] : genderOptions[1],
    );
  }, [user]);

  async function fetchCountries() {
    axiosClient
      .get(`/countries`)
      .then(({ data }) => {
        const option = data.countries.map((country) => ({
          label: country.name_ar,
          value: country.id,
        }));
        setCountriesOptions(option);
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {});
  }

  useEffect(() => {
    fetchCountries();
    return () => {};
  }, []);

  function handleOnSubmit(event) {
    event.preventDefault();
    if (isInProgress) {
      return;
    }
    setIsInProgress(true);
    const payload = {
      country_id: selectedCountry.value,
      type: selectedType.value,
      gender: selectedGender.value,
      first_name_ar: firstNameAr.current.value,
      first_name_en: firstNameEn.current.value,
      last_name_ar: lastNameAr.current.value,
      last_name_en: lastNameEn.current.value,
      email: emailRef.current.value,
    };

    axiosClient
      .put(`/dashboard/users/${user.id}`, payload)
      .then(({ data }) => {
        showToastSuccess(data.message);
        navigate('/dashboard/users');
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        setIsInProgress(false);
      });
  }

  return (
    <form
      onSubmit={handleOnSubmit}
      className={`grid grid-cols-12 gap-[16px]`}
      method="POST"
    >
      <div className={`col-span-12 flex flex-col space-y-[4px]`}>
        <label htmlFor={`IdPassword`} className={`font-bold`}>
          دولة
          <span className={`text-danger`}>*</span>
        </label>
        <Select
          isSearchable={true}
          options={countriesOptions}
          onChange={(selectedCountry) => setSelectedCountry(selectedCountry)}
          value={selectedCountry}
        />
      </div>
      <div className={`col-span-6 flex flex-col space-y-[4px]`}>
        <label htmlFor={`IdFirstNameAr`} className={`font-bold`}>
          الاسم الشخصي بالعربية
          <span className={`text-danger`}>*</span>
        </label>
        <input
          ref={firstNameAr}
          className={`rounded-[6px] border-[2px] border-light px-[16px] py-[8px] outline-none`}
          type={`text`}
          name={`first_name_ar`}
          id={`IdFirstNameAr`}
          defaultValue={user.first_name_ar}
        />
      </div>
      <div className={`col-span-6 flex flex-col space-y-[4px]`}>
        <label htmlFor={`IdLastNameAr`} className={`font-bold`}>
          الاسم العائلة بالعربية
          <span className={`text-danger`}>*</span>
        </label>
        <input
          ref={lastNameAr}
          className={`rounded-[6px] border-[2px] border-light px-[16px] py-[8px] outline-none`}
          type={`text`}
          name={`last_name_ar`}
          id={`IdLastNameAr`}
          defaultValue={user.last_name_ar}
        />
      </div>
      <div className={`col-span-6 flex flex-col space-y-[4px]`}>
        <label htmlFor={`IdFirstNameEn`} className={`font-bold`}>
          الاسم الشخصي بالانجليزية
          <span className={`text-danger`}>*</span>
        </label>
        <input
          ref={firstNameEn}
          dir={`ltr`}
          className={`rounded-[6px] border-[2px] border-light px-[16px] py-[8px] outline-none`}
          type={`text`}
          name={`first_name_en`}
          id={`IdFirstNameEn`}
          defaultValue={user.first_name_en}
        />
      </div>
      <div className={`col-span-6 flex flex-col space-y-[4px]`}>
        <label htmlFor={`IdLastNameEn`} className={`font-bold`}>
          الاسم العائلة بالانجليزية
          <span className={`text-danger`}>*</span>
        </label>
        <input
          ref={lastNameEn}
          dir={`ltr`}
          className={`rounded-[6px] border-[2px] border-light px-[16px] py-[8px] outline-none`}
          type={`text`}
          name={`last_name_en`}
          id={`IdLastNameEn`}
          defaultValue={user.last_name_en}
        />
      </div>

      <div className={`col-span-12 flex flex-col space-y-[4px]`}>
        <label htmlFor={`IdPassword`} className={`font-bold`}>
          البريد الإلكتروني
          <span className={`text-danger`}>*</span>
        </label>
        <input
          ref={emailRef}
          dir={`ltr`}
          className={`rounded-[6px] border-[2px] border-light px-[16px] py-[8px] outline-none`}
          type={`email`}
          name={`email`}
          id={`IdEmail`}
          defaultValue={user.email}
        />
      </div>

      <div className={`col-span-12 flex flex-col space-y-[4px]`}>
        <label htmlFor={`IdPassword`} className={`font-bold`}>
          الفئة
          <span className={`text-danger`}>*</span>
        </label>
        <Select
          options={typeOptions}
          isSearchable={false}
          onChange={(selectedType) => setSelectedType(selectedType)}
          value={selectedType}
        />
      </div>
      <div className={`col-span-12 flex flex-col space-y-[4px]`}>
        <label htmlFor={`IdPassword`} className={`font-bold`}>
          نوع
          <span className={`text-danger`}>*</span>
        </label>
        <Select
          options={genderOptions}
          isSearchable={false}
          value={selectedGender}
          onChange={(selectedGender) => setSelectedGender(selectedGender)}
        />
      </div>
      <div className={`col-span-2 flex flex-col space-y-[4px]`}>
        <button
          type={`submit`}
          disabled={isInProgress}
          className={`${isInProgress ? 'cursor-wait' : 'hover:bg-primary-hover'} flex justify-center rounded-[6px] border bg-primary px-[16px] py-[8px] text-white`}
        >
          {isInProgress && <CgSpinner className="animate-spin" size={21} />}
          {!isInProgress && <>تعديل</>}
        </button>
      </div>
    </form>
  );
}
