export default function UserInformationsLoading() {
  return (
    <div className="space-y-[16px]">
      <div className="space-y-[4px]">
        <div className="font-bold">الاسم الكامل</div>
        <div className=" h-[40px] w-full rounded-[8px] bg-muted animate-pulse" />
      </div>
      <div className="space-y-[4px]">
        <div className="font-bold">دولة</div>
        <div className=" h-[40px] w-full rounded-[8px] bg-muted animate-pulse" />
      </div>
      <div className="space-y-[4px]">
        <div className="font-bold">نوع</div>
        <div className=" h-[40px] w-full rounded-[8px] bg-muted animate-pulse" />
      </div>
      <div className="space-y-[4px]">
        <div className="font-bold">بريد إلكتروني</div>
        <div className=" h-[40px] w-full rounded-[8px] bg-muted animate-pulse" />
      </div>
      <div className="space-y-[4px]">
        <div className="font-bold">تم إنشاؤه</div>
        <div className=" h-[40px] w-full rounded-[8px] bg-muted animate-pulse" />
      </div>
      <div className="space-y-[4px]">
        <div className="font-bold">تم تحديثه</div>
        <div className=" h-[40px] w-full rounded-[8px] bg-muted animate-pulse" />
      </div>
      <div className='flex gap-[16px]'>
        <div className=" h-[40px] w-[150px] rounded-[8px] bg-muted animate-pulse" />
        <div className=" h-[40px] w-[150px] rounded-[8px] bg-muted animate-pulse" />
      </div>
    </div>

  );
}
