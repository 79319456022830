import { useRef, useState } from 'react';
import { BiUpload } from 'react-icons/bi';
import { CgSpinner } from 'react-icons/cg';
import axiosClient from '../../../axios-client';
import { handleFetchError, showToastSuccess } from '../../../helper';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/authContext/AuthContext';

export default function GlobalDocumentsCreatePage() {
  const { authDispatch } = useAuth();
  const navigate = useNavigate();

  const titleRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isInProgress, setIsInProgress] = useState(false);

  const handleOnSubmitCreateGlobalDocument = (event) => {
    event.preventDefault();
    const payload = {
      title: titleRef.current.value,
      file: selectedFile,
    };

    setIsInProgress(true);

    if (isInProgress) {
      return;
    }

    axiosClient
      .post(`/dashboard/documents/global/create`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({ data }) => {
        showToastSuccess(data.message);
        navigate(`/dashboard/documents/global?page=1`);
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        setIsInProgress(false);
      });
  };
  return (
    <div className={`w-full p-[16px] text-[14px]`}>
      <h1 className="mb-[8px] text-[22px]">إضافة وثيقة</h1>
      <div className="rounded-[8px] bg-white p-[16px]">
        <form
          onSubmit={handleOnSubmitCreateGlobalDocument}
          className={`grid grid-cols-12 gap-[16px]`}
        >
          <div className={`col-span-12 flex flex-col space-y-[4px]`}>
            <label htmlFor={`ID_TITLE`} className={`font-bold`}>
              عنوان
              <span className={`text-danger`}> *</span>
            </label>
            <input
              ref={titleRef}
              className={`rounded-[6px] border-[2px] border-muted px-[16px] py-[8px] outline-none`}
              type={`text`}
              name={`title`}
              id={`ID_TITLE`}
            />
          </div>

          <div className={`col-span-12 flex flex-col space-y-[4px]`}>
            <label
              htmlFor="ID_PROJECT_FILE"
              className="flex w-full cursor-pointer flex-col space-y-[8px]"
            >
              <span className="font-bold">
                ملف <span className="text-danger">*</span>
              </span>
              <div className="flex items-center justify-between rounded-[6px] border-[2px] border-muted bg-white px-[16px] py-[8px] text-secondary">
                <div>
                  {selectedFile && selectedFile.name}
                  {!selectedFile && `اختر ملف`}
                </div>
                <BiUpload size={22} />
              </div>
              <input
                type="file"
                name="project_file"
                id="ID_PROJECT_FILE"
                className="hidden"
                accept=".doc,.docx,.xls,.xlsx,.xlsm"
                onChange={(event) => setSelectedFile(event.target.files[0])}
              />
            </label>
          </div>

          <div className={`col-span-2 flex flex-col space-y-[4px]`}>
            <button
              disabled={isInProgress}
              className={`${isInProgress ? 'cursor-wait' : 'hover:bg-primary-hover'} flex justify-center rounded-[6px] border bg-primary px-[16px] py-[8px] text-white`}
            >
              {isInProgress && <CgSpinner className="animate-spin" size={21} />}
              {!isInProgress && <>إضافة</>}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
