import React, { useEffect, useState } from 'react';
import IndexOverview from './IndexOverview';
import IndexCommittees from './IndexCommittees';
import axiosClient from '../../../axios-client';
import { handleFetchError } from '../../../helper';
import { useAuth } from '../../../contexts/authContext/AuthContext';
import { useNavigate } from 'react-router-dom';
import { HiDownload } from 'react-icons/hi';

export default function Index() {
  const { authDispatch } = useAuth();
  const navigate = useNavigate();

  const [globalDocuments, setGlobalDocuments] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getGlobalDocuments = () => {
    setIsLoading(true);
    axiosClient
      .get('/documents/global')
      .then(({ data }) => {
        setGlobalDocuments(data.documents);
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleOnClickDownload = (document_name, document_id) => {
    setIsDownloading(true);
    axiosClient
      .get(`/documents/${document_id}/global/download`, {
        responseType: 'blob',
      })
      .then((response) => {
        const filename = document_name;
        let blob = new Blob([response.data], {
          type: 'application/octet-stream',
        });

        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          // IE workaround for "HTML7007: One or more blob URLs were
          // revoked by closing the blob for which they were created.
          // These URLs will no longer resolve as the data backing
          // the URL has been freed."
          window.navigator.msSaveBlob(blob, filename);
        } else {
          let blobURL = window.URL.createObjectURL(blob);
          let tempLink = document.createElement('a');
          tempLink.style.display = 'none';
          tempLink.href = blobURL;
          tempLink.download = filename;
          tempLink.click();
          window.URL.revokeObjectURL(blobURL);
        }
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  useEffect(() => {
    getGlobalDocuments();
  }, []);

  return (
    <div className="w-full border-r border-muted p-[16px]">
      <IndexOverview />
      <>
        <h2 className="mb-[8px] text-[24px] font-bold">وثائق اللجنة العامة</h2>

        <div className="mb-[16px] grid w-full grid-cols-12 gap-[8px]">
          {isLoading &&
            [...Array(8)].map(() => (
              <div className="col-span-12 h-[52px] animate-pulse rounded-[8px] border-[2px] bg-white md:col-span-6"></div>
            ))}
          {!isLoading &&
            globalDocuments &&
            globalDocuments.map((document) => (
              <button
                onClick={() =>
                  handleOnClickDownload(
                    `${document.title}.${document.file.split('.')[1]}`,
                    document.id,
                  )
                }
                className="col-span-12 flex rounded-[8px] border-[2px] border-muted bg-white px-[16px] py-[12px] hover:bg-muted  md:col-span-6"
              >
                <div className="w-full text-right">{document.title}</div>
                <div>
                  <HiDownload size={20} />
                </div>
              </button>
            ))}
        </div>
      </>
      <IndexCommittees />
    </div>
  );
}
