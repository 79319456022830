import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/authContext/AuthContext';
import axiosClient from '../../../axios-client';
import CommitteesTable from '../../../components/dashboard/committees/committees-page/CommitteesTable';
import {
  handleFetchError,
  showToastError,
  showToastSuccess,
} from '../../../helper';
import { AUTH_ACTIONS } from '../../../contexts/authContext/authActions';

export default function CommitteesPage() {
  const { authDispatch, authState } = useAuth();

  // Get the search params from the current location
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [committees, setCommittees] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchCommittees = () => {
    axiosClient
      .get(`/dashboard/committees?${searchParams.toString()}`)
      .then(({ data }) => {
        setCommittees(data.committees);
        setPagination({
          last_page_url: data.last_page_url,
          links: data.links,
          first_page_url: data.first_page_url,
          current_page: data.current_page,
        });
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteCommittee = (committeeId) => {
    axiosClient
      .delete(`/dashboard/committees/${committeeId}`)
      .then(({ data }) => {
        fetchCommittees();
        showToastSuccess(data.message);
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {});
  };

  useEffect(() => {
    fetchCommittees();
  }, [authState.token, searchParams]);

  return (
    <div className={`w-full p-[16px]`}>
      <div className={`mb-[8px] flex items-end justify-between`}>
        <h1 className="text-[22px]">اللجان</h1>
        <Link
          to={`/dashboard/committees/create`}
          className={`rounded-[6px] bg-primary px-[16px] py-[8px] text-white hover:bg-primary-hover`}
        >
          إضافة لجنة
        </Link>
      </div>
      <CommitteesTable
        committees={committees}
        isLoading={isLoading}
        onDeleteCommittee={deleteCommittee}
      />
    </div>
  );
}
