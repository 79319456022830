import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/authContext/AuthContext';
import axiosClient from '../../../axios-client';
import { handleFetchError, timeAgoInArabic } from '../../../helper';

export default function VotesPage() {
  const { committee_id, project_id } = useParams();

  const { authDispatch } = useAuth();

  const navigate = useNavigate();

  const [votes, setVotes] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState(null);

  useEffect(() => {
    const getProjects = async () => {
      setIsLoading(true);
      axiosClient
        .get(`/committees/${committee_id}/projects/${project_id}/votes`)
        .then(({ data }) => {
          setVotes(data.votes);
        })
        .catch((error) => {
          handleFetchError(error, authDispatch, navigate);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    getProjects();
    return () => {};
  }, [committee_id, project_id]);

  return (
    <div className="w-full border-r border-muted p-[16px]">
      <h1 className="mb-[8px] text-[22px]">صفحة التصويتات</h1>
      <table className="w-full ">
        <thead className=" rounded-[8px] border-[2px] border-muted-hover bg-muted">
          <th className=" w-1/2 border-l-[2px] border-muted-hover px-[16px] py-[8px]">
            الأعضاء ليس لديهم تعليقات
          </th>
          <th className="w-1/2 px-[16px] py-[8px]">الأعضاء لديهم تعليقات</th>
        </thead>
        <tbody className="col-span-1">
          <tr>
            <td className="border-[2px] border-muted-hover p-[8px]">
              <div className="flex flex-wrap justify-between gap-[8px]">
                {votes?.map((vote) => {
                  if (vote.has_comment == 0) {
                    return (
                      <div className="flex items-center gap-[4px] rounded-[8px] border-[2px] border-success bg-gradient-to-l from-success/50 to-white px-[16px] py-[8px]">
                        <img
                          className="w-[40px] overflow-hidden rounded-[5px] border-2 border-muted object-contain"
                          src={`${process.env.REACT_APP_BASE_URL}/storage/flags/${vote.user.country.image}`}
                          alt={vote.user.country.name_ar}
                        />
                        <Link className="text-[15px] font-bold hover:text-primary">
                          {vote.user.first_name_ar} {vote.user.last_name_ar}
                        </Link>
                      </div>
                    );
                  }
                  return;
                })}
              </div>
            </td>
            <td className="border-[2px] border-muted-hover p-[8px]">
              <div className="flex flex-wrap justify-between gap-[8px]">
                {votes?.map((vote) => {
                  if (vote.has_comment == 1) {
                    return (
                      <>
                        <div className="flex items-center gap-[4px] rounded-[8px] border-[2px] border-danger bg-gradient-to-l from-danger/50 to-white px-[16px] py-[8px]">
                          <img
                            className="w-[40px] overflow-hidden rounded-[5px] border-2 border-muted object-contain"
                            src={`${process.env.REACT_APP_BASE_URL}/storage/flags/${vote.user.country.image}`}
                            alt={vote.user.country.name_ar}
                          />
                          <Link className="text-[15px] font-bold hover:text-primary">
                            {vote.user.first_name_ar} {vote.user.last_name_ar}
                          </Link>
                        </div>
                      </>
                    );
                  }
                  return;
                })}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
