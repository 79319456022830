import React from 'react';
import { CheckmarkIcon } from 'react-hot-toast';
import { FaCheck } from 'react-icons/fa';

export default function RadioButton({ isActive, label, onClick }) {
  return (
    <button
      type="button"
      onClick={() => onClick()}
      className={`group  w-full border-[2px] px-[12px] py-[6px] ${isActive ? 'border-primary text-primary' : 'border-muted hover:border-primary hover:text-primary'} rounded-[6px] bg-white`}
    >
      <div className="flex items-center gap-[8px]">
        <div
          className={`flex h-[17px] w-[17px] items-center justify-center text-secondary ${isActive ? 'bg-primary' : ' bg-muted group-hover:bg-primary'} rounded-[4px]`}
        >
          {isActive && <FaCheck size={12} className="text-white" />}
        </div>
        <div className="select-none">{label}</div>
      </div>
    </button>
  );
}
