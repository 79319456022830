import React from 'react'
import { CgSpinner } from 'react-icons/cg'

export default function ProjectEditFormLoading() {
    return (
        <div
            className="mt-[16px] flex flex-col gap-[16px] rounded-[8px] bg-white p-[16px]"
        >
            <h1 className="text-[24px] font-bold">تعديل مشروع مواصفة</h1>
            <div className="space-y-[8px]">
                <label className="font-semibold">
                    نوع إضافة المشروع <span className="text-danger">*</span>
                </label>
                <div className="flex gap-[16px]">
                    <div className={`bg-muted h-[40px] w-full rounded-[8px] animate-pulse`} />
                    <div className={`bg-muted h-[40px] w-full rounded-[8px] animate-pulse`} />

                </div>
            </div>

            <div className="space-y-[8px]">
                <label className="font-semibold">
                    نوع المشروع <span className="text-danger">*</span>
                </label>
                <div className="flex gap-[16px]">
                    <div className={`bg-muted h-[40px] w-full rounded-[8px] animate-pulse`} />
                    <div className={`bg-muted h-[40px] w-full rounded-[8px] animate-pulse`} />
                </div>
            </div>

            <div className="flex gap-[16px]">
                <div className="flex w-full flex-col space-y-[8px]">
                    <label className="font-semibold">
                        عنوان بالعربية <span className="text-danger">*</span>
                    </label>
                    <div className={`bg-muted h-[40px] w-full rounded-[8px] animate-pulse`} />
                </div>
                <div className="flex w-full flex-col space-y-[8px]">
                    <label className="font-semibold">عنوان بالإنجليزية</label>

                    <div className={`bg-muted h-[40px] w-full rounded-[8px] animate-pulse`} />
                </div>
            </div>

            <label
                className="flex w-full cursor-pointer flex-col space-y-[8px]"
            >
                <span className="font-semibold">
                    ملف <span className="text-danger">*</span>
                </span>

                <div className={`bg-muted h-[40px] w-full rounded-[8px] animate-pulse`} />
            </label>


            <div className="flex w-full flex-col space-y-[8px]">
                <label className="font-semibold">
                    مراجع <span className="text-danger">*</span>
                </label>

                <div className={`bg-muted h-[40px] w-full rounded-[8px] animate-pulse`} />

                <div className={`bg-muted h-[40px] w-full rounded-[8px] animate-pulse`} />

                <div className={`bg-muted h-[40px] w-full rounded-[8px] animate-pulse`} />

            </div>

            <div className="flex justify-start">
                <button
                    className="rounded-[6px] bg-primary px-[32px] py-[8px] text-white hover:bg-primary-hover"
                >
                    <CgSpinner className="animate-spin" size={21} />
                </button>
            </div>
        </div>
    )
}
