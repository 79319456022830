import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosClient from '../../../axios-client';
import UserInformationsLoading from '../../../components/dashboard/users/single-user-page/UserInformationsLoading';
import { handleFetchError } from '../../../helper';
import { useAuth } from '../../../contexts/authContext/AuthContext';
import UserInformations from '../../../components/dashboard/users/single-user-page/UserInformations';

export default function User() {
  const { user_id } = useParams();

  const { authDispatch } = useAuth();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    axiosClient
      .get(`/dashboard/users/${user_id}`)
      .then(({ data }) => {
        setUser(data.user);
      })
      .catch((error) => {
        handleFetchError(error, authDispatch, navigate);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [user_id]);

  return (
    <div className="p-[16px]">
      <h1 className='text-[22px] mb-[8px]'>
        معلومات المستخدم
      </h1>
      <div className="rounded-[8px] bg-white p-[16px]">
        {isLoading && <UserInformationsLoading />}
        {!isLoading && <UserInformations user={user} />}
      </div>
    </div>
  );
}
