import React, { useEffect, useRef, useState } from 'react';
import SelectCountries from '../../components/formus/ui/SelectCountries';
import axiosClient from '../../axios-client';
import { useAuth } from '../../contexts/authContext/AuthContext';
import { AUTH_ACTIONS } from '../../contexts/authContext/authActions';
import { showToastError } from '../../helper';
import { useNavigate } from 'react-router-dom';

export default function DatabasesProjectsAdd() {
  const { authState, authDispatch } = useAuth();
  const navigate = useNavigate();

  const projectSymbolRef = useRef(null);
  const isProposedForApprovalRef = useRef(null);
  const projectStatuslRef = useRef(null);
  const documentTypeRef = useRef(null);
  const projectArabicNumberRef = useRef(null);
  const projectNameArabicRef = useRef(null);
  const projectNameEnglishRef = useRef(null);
  const fileRef = useRef(null);
  const referencesRef = useRef(null);
  const cmmitteeRef = useRef(null);
  const additionalNotesRef = useRef(null);
  const departmentRef = useRef(null);

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [committees, setCommittees] = useState(null);

  const handleOnSelectCountry = (option) => {
    setSelectedCountry(option);
  };

  useEffect(() => {
    axiosClient
      .get(`/committes`)
      .then(({ data }) => {
        setCommittees(data.committees);
      })
      .catch((error) => {})
      .finally(() => {});
    return () => {};
  }, []);

  const handleOnClickAddProject = () => {
    const payload = {
      committee_id: cmmitteeRef.current?.value,
      symbol: projectSymbolRef.current?.value,
      country_id: selectedCountry?.value,
      proposed_for_approval: isProposedForApprovalRef.current?.value,
      project_status: projectStatuslRef.current?.value,
      document_type: documentTypeRef.current?.value,
      project_arabic_number: projectArabicNumberRef.current?.value,
      project_name_arabic: projectNameArabicRef.current?.value,
      project_name_english: projectNameEnglishRef.current?.value,
      file: fileRef.current?.files[0],
      references: referencesRef.current?.value,
      additional_notes: additionalNotesRef.current?.value,
      department: departmentRef.current?.value,
    };

    axiosClient
      .post(`/databases/projects/create`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        navigate('/databases/projects', {
          replace: true,
        });
      })
      .catch((error) => {
        const { response } = error;
        if (response.status === 401) {
          showToastError('انتهت صلاحية الجلسة الخاصة بك');
          authDispatch({
            type: AUTH_ACTIONS.LOGOUT,
          });
          navigate('/login', {
            replace: true,
          });
        } else if (
          response.status === 500 &&
          response.data.error === 'Unauthenticated.'
        ) {
          showToastError('انتهت صلاحية الجلسة الخاصة بك');
          authDispatch({
            type: AUTH_ACTIONS.LOGOUT,
          });
          navigate('/login', {
            replace: true,
          });
        } else {
          showToastError(response.data.error);
        }
      })
      .finally(() => {});
  };

  return (
    <div className="">
      <div className="rounded-[8px] bg-white p-[16px]">
        <h1 className="mb-[16px] border-r-[4px] border-dark pr-[8px] text-[16px] font-bold">
          إضافة مشروع
        </h1>
        <div className="grid grid-cols-12 gap-[8px]">
          <div className={`col-span-12 flex flex-col space-y-[4px]`}>
            <label htmlFor={`projectSymbolRef`} className={`font-bold`}>
              رمز المشروع
              <span className={`text-danger`}> *</span>
            </label>
            <input
              ref={projectSymbolRef}
              className={`rounded-[6px] border-[2px] border-light px-[16px] py-[8px] outline-none`}
              type={`text`}
              id={`projectSymbolRef`}
            />
          </div>
          <div className={`col-span-12 flex flex-col space-y-[4px]`}>
            <label htmlFor={`isProposedForApprovalRef`} className={`font-bold`}>
              معروضة للإعتماد
              <span className={`text-danger`}> *</span>
            </label>
            <select
              ref={isProposedForApprovalRef}
              className={`rounded-[6px] border-[2px] border-light px-[16px] py-[6px] outline-none`}
              id={`isProposedForApprovalRef`}
            >
              <option value="0">لا</option>
              <option value="1">نعم</option>
            </select>
          </div>
          <div className={`col-span-12 flex flex-col space-y-[4px]`}>
            <label className={`font-bold`}>
              الجهة المقترحة
              <span className={`text-danger`}> *</span>
            </label>
            <SelectCountries onSelect={handleOnSelectCountry} />
          </div>
          <div className={`col-span-12 flex flex-col space-y-[4px]`}>
            <label htmlFor="projectStatuslRef" className={`font-bold`}>
              وضعية المشروع
              <span className={`text-danger`}> *</span>
            </label>
            <select
              ref={projectStatuslRef}
              className={`rounded-[6px] border-[2px] border-light px-[16px] py-[6px] outline-none`}
              id={`projectStatuslRef`}
            >
              <option value="مشروع جديد">مشروع جديد</option>
              <option value="مشروع معدل">مشروع معدل</option>
              <option value="تحديث مواصفة معتمدة">تحديث مواصفة معتمدة</option>
              <option value="دليل استرشادي">دليل استرشادي</option>
              <option value="لائحة فنية">لائحة فنية</option>
            </select>
            <label htmlFor={`projectNameArabicRef`} className={`font-bold`}>
              في حالة إختيار "تحديث مواصفة معتمدة"، يرجى إدخال رقمها العربي
            </label>
            <input
              ref={projectArabicNumberRef}
              className={`rounded-[6px] border-[2px] border-light px-[16px] py-[8px] outline-none`}
              type={`text`}
              id={`projectNameArabicRef`}
            />
          </div>
          <div className={`col-span-12 flex flex-col space-y-[4px]`}>
            <label htmlFor={`documentTypeRef`} className={`font-bold`}>
              نوع الوثيقة
              <span className={`text-danger`}> *</span>
            </label>
            <select
              ref={documentTypeRef}
              className={`rounded-[6px] border-[2px] border-light px-[16px] py-[6px] outline-none`}
              id={`documentTypeRef`}
            >
              <option value="مواصفة">مواصفة</option>
              <option value="دليل">دليل</option>
              <option value="دراسة">دراسة</option>
              <option value="توصية">توصية</option>
              <option value="لائحة فنية">لائحة فنية</option>
            </select>
          </div>

          <div className={`col-span-12 flex flex-col space-y-[4px]`}>
            <label htmlFor={`projectNameArabicRef`} className={`font-bold`}>
              اسم المشروع بالعربية
              <span className={`text-danger`}> *</span>
            </label>
            <input
              ref={projectNameArabicRef}
              className={`rounded-[6px] border-[2px] border-light px-[16px] py-[8px] outline-none`}
              type={`text`}
              id={`projectNameArabicRef`}
            />
          </div>
          <div className={`col-span-12 flex flex-col space-y-[4px]`}>
            <label htmlFor={`projectNameEnglishRef`} className={`font-bold`}>
              اسم المشروع بالإنجليزية
              <span className={`text-danger`}> *</span>
            </label>
            <input
              ref={projectNameEnglishRef}
              className={`rounded-[6px] border-[2px] border-light px-[16px] py-[8px] outline-none`}
              type={`text`}
              id={`projectNameEnglishRef`}
            />
          </div>
          <div className={`col-span-12 flex flex-col space-y-[4px]`}>
            <label htmlFor={`fileRef`} className={`font-bold`}>
              نص المشروع
              <span className={`text-danger`}> *</span>
            </label>
            <input
              ref={fileRef}
              className={`rounded-[6px] border-[2px] border-light px-[16px] py-[8px] outline-none`}
              type={`file`}
              id={`fileRef`}
            />
          </div>
          <div className={`col-span-12 flex flex-col space-y-[4px]`}>
            <label htmlFor={`referencesRef`} className={`font-bold`}>
              المراجع
              <span className={`text-danger`}> *</span>
            </label>
            <textarea
              id="referencesRef"
              ref={referencesRef}
              rows={5}
              className={`rounded-[6px] border-[2px] border-light px-[16px] py-[6px] outline-none`}
            />
          </div>
          <div className={`col-span-12 flex flex-col space-y-[4px]`}>
            <label htmlFor={`cmmitteeRef`} className={`font-bold`}>
              اللجنة الفنية
              <span className={`text-danger`}> *</span>
            </label>
            <select
              ref={cmmitteeRef}
              className={`rounded-[6px] border-[2px] border-light px-[16px] py-[6px] outline-none`}
              id={`cmmitteeRef`}
            >
              {committees?.map((committee) => (
                <option value={committee.id}>
                  {committee.name} / {committee.symbol}
                </option>
              ))}
            </select>
          </div>
          <div className={`col-span-12 flex flex-col space-y-[4px]`}>
            <label htmlFor={`additionalNotesRef`} className={`font-bold`}>
              ملاحظات إضافية حول المشروع
              <span className={`text-danger`}> *</span>
            </label>
            <textarea
              ref={additionalNotesRef}
              id="additionalNotesRef"
              rows={5}
              className={`rounded-[6px] border-[2px] border-light px-[16px] py-[6px] outline-none`}
            />
          </div>
          <div className={`col-span-12 flex flex-col space-y-[4px]`}>
            <label htmlFor={`departmentRef`} className={`font-bold`}>
              قسم
              <span className={`text-danger`}> *</span>
            </label>
            <select
              ref={departmentRef}
              className={`rounded-[6px] border-[2px] border-light px-[16px] py-[6px] outline-none`}
              id={`departmentRef`}
            >
              <option value="عربية">عربية</option>
              <option value="متبناة">متبناة</option>
            </select>
          </div>
          <div className={`col-span-2 flex flex-col space-y-[4px]`}>
            <button
              onClick={handleOnClickAddProject}
              className={`flex justify-center rounded-[6px] border bg-primary px-[16px] py-[8px] text-white hover:bg-primary-hover`}
            >
              اضاف
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
