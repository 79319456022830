import { Dialog } from '@headlessui/react';

export default function DialogConfirmation({
  isOpen,
  onConfirm,
  onClose,
  children,
}) {
  return (
    <Dialog open={isOpen} onClose={() => onClose()} className="relative z-50">
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div
        className="fixed inset-0 bg-dark/20 backdrop-blur"
        aria-hidden="true"
      />

      {/* Full-screen container to center the panel */}
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        {/* The actual dialog panel  */}
        <Dialog.Panel className="w-[512px] rounded-[10px] bg-white p-[16px]">
          <div className="flex flex-col gap-[8px]">
            <div className="mb-[32px] mt-[16px]">{children}</div>
            <div className="flex gap-[16px]">
              <button
                onClick={() => onClose()}
                className="w-full rounded-[6px] bg-danger px-[4px] py-[8px] text-white transition duration-150 ease-in-out hover:bg-danger-hover"
              >
                إلغاء
              </button>
              <button
                onClick={() => onConfirm()}
                className="w-full rounded-[6px] bg-muted px-[4px] py-[8px] transition duration-150 ease-in-out hover:bg-muted-hover"
              >
                تأكد
              </button>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
