import { Navigate, createBrowserRouter } from 'react-router-dom';
import UsersPage from './views/dashboard/users/UsersPage';
import Login from './views/Login';
import Splash from './views/Splash';
import ForumsLayout from './views/forums/ForumsLayout';
import ProjectCreatePage from './views/forums/projects/ProjectCreatePage';
import UserPage from './views/dashboard/users/UserPage';
import UserCreate from './views/dashboard/users/UserCreate';
import UserEdit from './views/dashboard/users/UserEdit';
import UserChangePasswordPage from './views/dashboard/users/UserChangePasswordPage';
import CommitteesPage from './views/dashboard/committees/CommitteesPage';
import AdminLayout from './views/dashboard/AdminLayout';
import CommitteesCreatePage from './views/dashboard/committees/CommitteesCreatePage';
import CommitteesEditPage from './views/dashboard/committees/CommitteesEditPage';
import CommitteeMemberAddPage from './views/dashboard/committees/committee-members/CommitteeMemberAddPage';
import CommitteeMembersPage from './views/dashboard/committees/committee-members/CommitteeMembersPage';
import CommitteeMemberEditPage from './views/dashboard/committees/committee-members/CommitteeMemberEditPage';
import Index from './views/forums/index/Index';
import ProjectPage from './views/forums/projects/ProjectPage';
import DatabasesProjectsAdd from './views/databases/DatabasesProjectsAdd';
import DatabasesLayout from './views/databases/DatabasesLayout';
import DatabasesProjects from './views/databases/DatabasesProjects';
import DatabasesProjectsEdit from './views/databases/DatabasesProjectsEdit';
import ProjectsPage from './views/forums/projects/ProjectsPage';
import ProjectEditPage from './views/forums/projects/ProjectEditPage';
import ProjectsCountry from './views/forums/projects/ProjectsCountry';
import UserProfile from './views/forums/users/UserProfile';
import DocumentAdd from './views/forums/documents/DocumentAdd';
import DocumentEdit from './views/forums/documents/DocumentEdit';
import GlobalDocumentsPage from './views/dashboard/global-documents/GlobalDocumentsPage';
import GlobalDocumentsCreatePage from './views/dashboard/global-documents/GlobalDocumentsCreatePage';
import UserChangeMyPasswordPage from './views/forums/users/UserChangeMyPasswordPage';
import VotesPage from './views/forums/votes/VotesPage';
import AnnualPlanCreatePage from './views/forums/annual-plans/AnnualPlanCreatePage';
import AnnualPlanPage from './views/forums/annual-plans/AnnualPlanPage';
import ErrorPage from './views/ErrorPage';

const router = createBrowserRouter([
  {
    path: ``,
    element: <Splash />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: `/`,
        element: <Navigate to={'/forums'} />,
      },
      {
        element: <AdminLayout />,
        path: `dashboard`,
        children: [
          {
            path: `users`,
            children: [
              {
                path: ``,
                element: <UsersPage />,
              },
              {
                path: `create`,
                element: <UserCreate />,
              },
              {
                path: `:user_id`,
                children: [
                  {
                    path: '',
                    element: <UserPage />,
                  },
                  {
                    path: 'edit',
                    element: <UserEdit />,
                  },
                  {
                    path: `change`,
                    children: [
                      {
                        path: 'password',
                        element: <UserChangePasswordPage />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: 'committees',
            children: [
              {
                path: '',
                element: <CommitteesPage />,
              },
              {
                path: 'create',
                element: <CommitteesCreatePage />,
              },
              {
                path: ':committee_id',
                children: [
                  {
                    path: 'edit',
                    element: <CommitteesEditPage />,
                  },
                  {
                    path: 'members',
                    children: [
                      {
                        path: '',
                        element: <CommitteeMembersPage />,
                      },
                      {
                        path: 'add',
                        element: <CommitteeMemberAddPage />,
                      },
                      {
                        path: ':member_id',
                        children: [
                          {
                            path: 'edit',
                            element: <CommitteeMemberEditPage />,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: 'documents',
            children: [
              {
                path: 'global',
                children: [
                  {
                    path: '',
                    element: <GlobalDocumentsPage />,
                  },
                  {
                    path: 'create',
                    element: <GlobalDocumentsCreatePage />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        element: <ForumsLayout />,
        path: `forums`,
        children: [
          {
            path: ``,
            element: <Index />,
          },
          {
            path: `members`,
            children: [
              {
                path: ':user_id',
                element: <UserProfile />,
              },
              {
                path: 'me/change/password',
                element: <UserChangeMyPasswordPage />,
              },
            ],
          },
          {
            path: ``,
            children: [
              {
                path: `projects`,
                children: [
                  {
                    path: `our`,
                    element: <ProjectsCountry />,
                  },
                ],
              },

              {
                path: `committees`,
                children: [
                  {
                    path: `:committee_id`,
                    children: [
                      // {
                      //   path: `plans`,
                      //   children: [
                      //     {
                      //       path: ``,
                      //       element: <AnnualPlanPage />,
                      //     },
                      //     {
                      //       path: `create`,
                      //       element: <AnnualPlanCreatePage />,
                      //     },
                      //   ],
                      // },
                      {
                        path: `documents`,
                        children: [
                          {
                            path: `create`,
                            element: <DocumentAdd />,
                          },
                          {
                            path: `:document_id/edit`,
                            element: <DocumentEdit />,
                          },
                        ],
                      },
                      {
                        path: '',
                        element: <ProjectsPage />,
                      },
                      {
                        path: `projects`,
                        children: [
                          {
                            path: `create`,
                            element: <ProjectCreatePage />,
                          },
                          {
                            path: `:project_id`,
                            children: [
                              {
                                path: '',
                                element: <ProjectPage />,
                              },
                              {
                                path: 'edit',
                                element: <ProjectEditPage />,
                              },
                              {
                                path: 'votes',
                                element: <VotesPage />,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        path: 'databases',
        element: <DatabasesLayout />,
        children: [
          {
            path: 'projects',
            children: [
              {
                path: '',
                element: <DatabasesProjects />,
              },
              {
                path: 'add',
                element: <DatabasesProjectsAdd />,
              },
              {
                path: ':project_id',
                children: [
                  {
                    path: 'edit',
                    element: <DatabasesProjectsEdit />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        element: <Login />,
        path: `login`,
      },
    ],
  },
]);

export default router;
