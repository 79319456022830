import React from 'react'
import { CgClose } from 'react-icons/cg';

export default function ReferenceInput({ reference, index, onChange, onRemove }) {
    return (
        <div className="mb-[8px] w-full">
            <div className="flex gap-[8px]">
                <input
                    placeholder={`مرجع ${index + 1}`}
                    type="text"
                    name={`project_reference_${reference.id}`}
                    className="w-full rounded-[6px] border-[2px] border-muted bg-white px-[16px] py-[8px] placeholder-muted outline-none"
                    value={reference.value}
                    onChange={(e) => onChange(reference.id, e)}
                />
                {index > 0 && (
                    <button
                        type="button"
                        onClick={() => onRemove(reference.id)}
                        className="rounded-[6px] border bg-danger px-[10px] py-[6px] text-white hover:bg-danger-hover"
                    >
                        <CgClose size={22} />
                    </button>
                )}
            </div>
        </div>
    );
}
