import { Dialog } from '@headlessui/react';
import { useState } from 'react';
import { VscReferences } from 'react-icons/vsc';

export default function ProjectReferencesListButton({ references, isLoading }) {
  const [isOpen, setIsOpen] = useState(false);
  if (isLoading) {
    return (
      <div className="h-[44px] w-[100px] animate-pulse rounded-[8px] bg-white" />
    );
  }
  if (references)
    return (
      <>
        <button
          onClick={() => setIsOpen(true)}
          className="flex w-full items-center gap-[8px] rounded-[8px] border-[2px] bg-white py-[8px] pl-[16px] pr-[12px] hover:bg-muted"
        >
          <VscReferences size={20} />
          <div>المراجع</div>
        </button>
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          className="relative z-50"
        >
          {/* The backdrop, rendered as a fixed sibling to the panel container */}
          <div
            className="fixed inset-0 bg-dark/20 backdrop-blur"
            aria-hidden="true"
          />

          {/* Full-screen container to center the panel */}
          <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
            {/* The actual dialog panel  */}
            <Dialog.Panel className="w-[512px] rounded-[10px] bg-white p-[16px] pl-[0px]">
              <div className="flex flex-col gap-[8px]">
                <div className="overflow-y-scroll ">
                  {
                    <div>
                      <div className="sticky left-0 top-0 bg-white pb-[16px] font-bold">
                        المراجع :
                      </div>

                      <div className="flex h-[400px] flex-col gap-[8px] pl-[16px]  text-[14px]">
                        {JSON.parse(references).map((reference) =>
                          /[\u0600-\u06FF]/.test(reference) ? (
                            <div
                              dir="rtl"
                              className="rounded-[8px] border-[2px] border-muted p-[8px]"
                              key={reference}
                            >
                              {reference}
                            </div>
                          ) : (
                            <div
                              dir="ltr"
                              className="rounded-[8px] border-[2px] border-muted p-[8px]"
                              key={reference}
                            >
                              {reference}
                            </div>
                          ),
                        )}
                      </div>
                    </div>
                  }
                </div>
              </div>
            </Dialog.Panel>
          </div>
        </Dialog>
      </>
    );
  // if (isLoading) {
  //   return (
  //     <div className="mt-[8px]">
  //       <label className="font-bold">مراجع المشروع:</label>
  //       <ul className="text-auto mt-[8px] flex flex-col gap-[8px] text-[14px]">
  //         {[...Array(5)].map((_, index) => (
  //           <li
  //             key={index}
  //             className="h-[80px] w-full rounded-[8px] bg-white text-right"
  //           />
  //         ))}
  //       </ul>
  //     </div>
  //   );
  // }
  // if (references) {
  //   return (

  //   );
  // }
}
