import ProjectsList from '../../../components/formus/projects/projects-page/ProjectsList';
import CommitteeTitle from '../../../components/formus/ui/CommitteeTitle';
import ProjectsListFilter from '../../../components/formus/projects/projects-page/ProjectsListFilter';
import Pagination from '../../../components/formus/ui/Pagination';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axiosClient from '../../../axios-client';
import { handleFetchError } from '../../../helper';
import { useAuth } from '../../../contexts/authContext/AuthContext';

export default function ProjectsPage() {
  const { committee_id } = useParams();

  const { authDispatch } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();

  const [projects, setProjects] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState(null);

  useEffect(() => {
    const getProjects = async () => {
      setIsLoading(true);
      const queryString = location.search;
      axiosClient
        .get(`/committees/${committee_id}/projects${queryString}`)
        .then(({ data }) => {
          setProjects(data.projects);
          setPagination({
            last_page_url: data.last_page_url,
            links: data.links,
            first_page_url: data.first_page_url,
            current_page: data.current_page,
          });
        })
        .catch((error) => {
          handleFetchError(error, authDispatch, navigate);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    getProjects();
    return () => {};
  }, [committee_id, location.search]);

  return (
    <div className="w-full border-r border-muted p-[16px]">
      <ProjectsListFilter />
      <CommitteeTitle />
      <ProjectsList projects={projects} isLoading={isLoading} />
      {!isLoading && <Pagination pagination={pagination} />}
    </div>
  );
}
