import React, { useEffect, useState } from 'react';
import axiosClient from '../../axios-client';
import DatabasesProjectDetails from './DatabasesProjectDetails';
import { Link, useNavigate } from 'react-router-dom';
import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from 'react-icons/fi';
import { AUTH_ACTIONS } from '../../contexts/authContext/authActions';
import { showToastError } from '../../helper';
import { useAuth } from '../../contexts/authContext/AuthContext';
import DatabasesSearchForm from './DatabasesSearchForm';

export default function DatabasesProjects() {
  const { authDispatch, authState } = useAuth();
  const navigate = useNavigate();

  const [projects, setProjects] = useState(null);
  const [pagination, setPagination] = useState(null);
  useEffect(() => {
    axiosClient
      .get(`/databases/projects`)
      .then(({ data }) => {
        setProjects(data.projects);
        setPagination({
          last_page_url: data.last_page_url,
          links: data.links,
          first_page_url: data.first_page_url,
          current_page: data.current_page,
        });
      })
      .catch((error) => {
        const { response } = error;
        if (response.status === 401) {
          showToastError('انتهت صلاحية الجلسة الخاصة بك');
          authDispatch({
            type: AUTH_ACTIONS.LOGOUT,
          });
          navigate('/login', {
            replace: true,
          });
        } else if (
          response.status === 500 &&
          response.data.error === 'Unauthenticated.'
        ) {
          showToastError('انتهت صلاحية الجلسة الخاصة بك');
          authDispatch({
            type: AUTH_ACTIONS.LOGOUT,
          });
          navigate('/login', {
            replace: true,
          });
        } else {
          showToastError(response.data.error);
        }
      })
      .finally(() => {});
    return () => {};
  }, []);
  return (
    <div className="flex w-full flex-col gap-[8px]">
      <DatabasesSearchForm />
      <div>
        <h1 className="mb-[8px] mt-[16px] border-r-4 border-dark pr-[4px] text-xl font-bold">
          مشاريع المواصفات القياسية العربية
        </h1>
      </div>
      {projects?.map((project) => (
        <DatabasesProjectDetails project={project} />
      ))}
      {pagination && (
        <div className="flex items-center gap-2" dir="ltr">
          {/* First Page Icon */}
          {pagination.links.length > 1 &&
            pagination.current_page != 1 &&
            pagination.first_page_url && (
              <Link
                className="rounded-[6px] border-[2px] border-white bg-white px-[16px] py-[8px]"
                to={`?${pagination.first_page_url.split('?')[1]}`}
              >
                <FiChevronsLeft />
              </Link>
            )}

          {/* Pagination Links */}
          {pagination.links.map((link, index) => {
            if (!link.url) return null;

            const page = link.url.split('?')[1].split('=')[1];
            const isActive = pagination.current_page.toString() === page;

            const linkClassName = `py-[8px] px-[16px] rounded-[6px] bg-white ${!isActive ? 'border-[2px] border-white ' : 'border-[2px] border-muted-hover text-primary'}`;

            if (link.label === 'PREVIOUS') {
              return (
                <Link
                  key={index}
                  to={`?${link.url.split('?')[1]}`}
                  className="rounded-[6px] border-[2px] border-white bg-white px-[16px] py-[8px]"
                >
                  <FiChevronLeft />
                </Link>
              );
            }

            if (link.label === 'NEXT') {
              return (
                <Link
                  key={index}
                  to={`?${link.url.split('?')[1]}`}
                  className="rounded-[6px] border-[2px] border-white bg-white px-[16px] py-[8px]"
                >
                  <FiChevronRight />
                </Link>
              );
            }

            return (
              <Link
                key={index}
                to={`?${link.url.split('?')[1]}`}
                className={linkClassName}
              >
                {link.label}
              </Link>
            );
          })}
          {/* Last Page Icon */}
          {pagination.links.length > 1 &&
            pagination.links.length !== pagination.current_page + 1 &&
            pagination.last_page_url && (
              <Link
                className="rounded-[6px] border-[2px] border-white bg-white px-[16px] py-[8px]"
                to={`?${pagination.last_page_url.split('?')[1]}`}
              >
                <FiChevronsRight />
              </Link>
            )}
        </div>
      )}
    </div>
  );
}
